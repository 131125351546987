import { type Variable } from '~/js/models/lesson/LessonModel/types';
import {
  type VariableDefinition,
  VariableType,
} from '~/js/models/common/CodeModel/types';

export const lessonVariablesToCore = (
  variables: Variable[],
): VariableDefinition[] =>
  variables.map(({ key, name, type, initialValue }) => {
    switch (type) {
      case VariableType.Int: {
        return {
          key,
          name,
          type,
          initialValue:
            (typeof initialValue === 'string'
              ? parseInt(initialValue)
              : initialValue) || 0,
        };
      }
      case VariableType.Float: {
        return {
          key,
          name,
          type,
          initialValue:
            (typeof initialValue === 'string'
              ? parseFloat(initialValue)
              : initialValue) || 0,
        };
      }
      case VariableType.String: {
        return {
          key,
          name,
          type,
          initialValue: initialValue ? `${initialValue}` : '',
        };
      }
      default: {
        throw new Error(
          `Variable '${name}' has type '${type}, which is not allowed'`,
        );
      }
    }
  });

import { type ConnItem } from '~/js/utils/breadboard/components/layers/WireLayer/types';
import { Cell } from '~/js/utils/breadboard/core/Grid';

export const getConnItemRepresentation = (connItem: ConnItem) => {
  if (connItem instanceof Cell) {
    return `[${connItem.idx.x},${connItem.idx.y}]`;
  } else {
    return `{junc ${connItem.id}: [${connItem.cells
      .map((cell) => `[${cell.idx.x},${cell.idx.y}]`)
      .join(', ')}]}`;
  }
};

import { type Cell } from '../../core/Grid';
import { Plate, type PlateProps } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

type InductorPlateProps = PlateProps & {
  inductance: number;
};

/**
 * Inductor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class InductorPlate extends LinearPlate<InductorPlateProps> {
  static get Alias() {
    return PLATE_ALIASES.INDUCTOR;
  }

  protected get __inheritProps__() {
    return {
      inductance: 100,
    };
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected __setProps__(props: InductorPlateProps) {
    super.__setProps__({ ...props, inductance: Number(props.inductance) });
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    this._drawLabel();
  }

  /**
   * Draws an inductor over the plate surface
   *
   * @param qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect2.x() - rect1.x();

    const line_gap = line_len / 4;

    this._group
      .path([
        ['M', 0, 0],
        ['l', line_len / 2 - line_gap, 0],
        ['m', line_gap * 2, 0],
        ['l', line_len / 2 - line_gap, 0],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 3 })
      .fill('none')
      .move(rect1.cx(), rect1.cy());

    const circ_base_x = rect1.cx() + line_len / 2 - line_gap;

    for (let i = 0; i < 3; i++) {
      this._group
        .circle(line_gap / 1.5)
        .x(circ_base_x + (line_gap / 1.5) * i)
        .cy(rect1.cy())
        .fill('none')
        .addClass('bb-plate-stroke')
        // @ts-expect-error
        .stroke({ width: 3, dasharray: [0, line_gap, line_gap * 1.5] });
    }
  }

  /**
   * Draws a label with the capacitor designation
   *
   * @param size label font size
   */
  private _drawLabel(size = Plate.LabelFontSizePreferred) {
    this._group
      .text(this._shortLabel())
      .font({ size })
      .addClass('bb-plate-caption')
      .cx(this._container.width() / 2)
      .y(this._container.height() - size * 2)
      .stroke({ width: 0.5 });
  }

  /**
   * @returns short designation of the inductor
   */
  private _shortLabel(): string {
    return `${this.props.inductance} H`;
  }
}

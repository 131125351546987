import './styles.less';
import { ContextMenu } from '../../../core/ContextMenu';
import { type Grid } from '../../../core/Grid';
import { Layer, type LayerOptions } from '../../../core/Layer';
import { getAbsolutePosition } from '../../../core/Layer/helpers';
import { type XYPoint } from '../../../types';

/**
 * Contains {@link ContextMenu}s called from other {@link Layer}s of the breadboard
 *
 * Unlike most of the layers in the {@link Breadboard}, this layer is HTML-based.
 * This is required because {@link ContextMenu}s renders HTML elements which can be mounted in HTML container only.
 *
 * @category Breadboard
 * @subcategory Layers
 */
export class MenuLayer extends Layer<HTMLDivElement> {
  /** CSS class of the layer */
  static get Class() {
    return 'bb-layer-menu';
  }

  /** instance of context menu currently opened */
  private _menu?: ContextMenu;

  /** local event handlers */
  private readonly _callbacks: {
    ctxmenuitemclick?: (item_id: number, alias: string, value: any) => void;
  };

  /**
   * Make sure to pass an HTML container when constructing the layer.
   * Since the other layers all in the SVG, make sure that the container
   * is placed over entire SVG document in the DOM tree and visible to user.
   * The container should allow any interactions outside the content it creates, but
   * prevent any background interactions under that content.
   */
  constructor(container: HTMLDivElement, grid: Grid, options: LayerOptions) {
    super(container, grid, options);

    this._container.classList.add(MenuLayer.Class);

    this._callbacks = {
      ctxmenuitemclick: undefined,
    };

    this._drawMenu = this._drawMenu.bind(this);

    this._menu = undefined;

    this._handleFreeClick = this._handleFreeClick.bind(this);
  }

  /**
   * Attaches global click event handler
   */
  __compose__() {
    document.addEventListener('mousedown', this._handleFreeClick, false);
  }

  /**
   * Attaches context menu item click event handler
   *
   * Callback parameters:
   *  - item_id   optional id of the item, if applicable
   *  - alias     optional alias of the item type, if applicable
   *  - value     optional value if the item, if applicable
   *
   * For example, if item is a {@link RheostatPlate}, its alias is 'switch' and its value is `100`.
   *
   * @param cb callback function as a handler to attach
   */
  onContextMenuItemClick(
    cb: (item_id: number, alias: string, value: any) => void,
  ) {
    this._callbacks.ctxmenuitemclick = cb;
  }

  /**
   * Opens given {@link ContextMenu} instance
   *
   * @param menu      the menu instance to open
   * @param position  position of the mouse click
   * @param inputs    optional inputs of the item clicked
   */
  openMenu(
    menu: ContextMenu,
    position: XYPoint,
    inputs: Array<string | number>,
  ) {
    this.hideMenu();

    if (!menu) {
      throw new Error('Menu is not provided');
    }
    if (!position) {
      throw new Error("parameter 'position' is undefined");
    }

    this._drawMenu(menu, position, inputs);

    this._menu?.onItemClick((item_id: number, alias: string, value: any) => {
      this.hideMenu();
      this._callbacks.ctxmenuitemclick &&
        this._callbacks.ctxmenuitemclick(item_id, alias, value);
    });
  }

  /**
   * Hides {@link ContextMenu} instance currently displaying
   */
  hideMenu() {
    if (this._menu) {
      const container = this._menu.container;

      this._menu.hide(() => {
        this._container.removeChild(container);
      });

      this._menu = undefined;
    }
  }

  /**
   * Draws given {@link ContextMenu} instance and attaches it to the container
   *
   * @param menu     the menu instance to draw
   * @param position position of the mouse click
   * @param inputs   optional inputs of the item clicked
   */
  _drawMenu(
    menu: ContextMenu,
    position: XYPoint,
    inputs: Array<string | number>,
  ) {
    this._menu = menu;

    const container_menu = this._menu.draw(position, inputs);
    this._container.appendChild(container_menu);

    container_menu.style.left = position.x + 'px';
    container_menu.style.top = position.y + 'px';

    const { x: root_x0, y: root_y0 } = getAbsolutePosition(this._container);
    const menu_x0 = position.x;
    const menu_y0 = position.y;

    const dx = root_x0;
    const dy = root_y0;

    const root_x1 = this._container.offsetWidth + root_x0;
    const root_y1 = this._container.offsetHeight + root_y0;

    const menu_x1 = container_menu.offsetWidth + menu_x0;
    const menu_y1 = container_menu.offsetHeight + menu_y0;

    let { x: px, y: py } = position;

    if (menu_x0 < root_x0) {
      px = position.x + root_x0 - menu_x0;
    }
    if (menu_x1 > root_x1) {
      px = position.x - menu_x1 + root_x1;
    }

    if (menu_y0 < root_y0) {
      py = position.y + root_y0 - menu_y0;
    }
    if (menu_y1 > root_y1) {
      py = position.y - menu_y1 + root_y1;
    }

    container_menu.style.left = px - dx + 'px';
    container_menu.style.top = py - dy + 'px';
  }

  /**
   * Handles global document click event
   *
   * @param evt global click event
   */
  _handleFreeClick(evt: MouseEvent) {
    // do not handle right-click events because it may block others modules to open menus
    if (evt.which === 3) {
      return;
    }

    let el = evt.target as Element | null | undefined;

    // detect if the element is the part of a menu
    while (
      (el = el?.parentElement) &&
      !el.classList.contains(ContextMenu.Class)
    ) {}

    if (!el) {
      // evt.preventDefault();
      this.hideMenu();
    }
  }

  protected _applyMode() {}
}

import { type Cell } from '../../core/Grid';
import { Plate, type PlateProps } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

type BridgePlateProps = PlateProps & {
  length: number;
};

/**
 * Bridge plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class BridgePlate extends LinearPlate<BridgePlateProps> {
  static get Alias() {
    return PLATE_ALIASES.BRIDGE;
  }

  static get Layouts() {
    return ['v5x', 'v8x'];
  }

  /**
   * @inheritdoc
   */
  public get variant() {
    return String(this.__length__);
  }

  /**
   * @inheritdoc
   */
  protected get __length__(): number {
    return Number(this.props.length);
  }

  protected get __inheritProps__() {
    return {
      length: 2,
    };
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected __setProps__(props: BridgePlateProps) {
    super.__setProps__(props);

    const length = Number(props.length);
    this.props.length = Number.isInteger(length) ? length : 2;
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: any) {
    this._drawPicture();

    // this._group.text(`Bridge ${this._params.len} cells`).font({size: 20});
  }

  /**
   * Draws a bridge over the plate surface
   *
   * @param qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const ls = this.options.schematic ? 10 : 6;

    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs).addClass('bb-plate-fill');
    const rect2 = this._group.rect(qs, qs).addClass('bb-plate-fill');

    rect1.center(cell1.center_rel.x, cell1.center_rel.y);
    rect2.center(cell2.center_rel.x, cell2.center_rel.y);

    this._group
      .rect(rect2.x() - rect1.x(), ls)
      .addClass('bb-plate-fill')
      .x(cell1.center_rel.x)
      .cy(cell1.center_rel.y);
  }
}

import type SVG from 'svg.js';

import { CurrentLayer } from '~/js/utils/breadboard/components/layers';
import { type Net } from '~/js/utils/breadboard/components/layers/WireLayer/types';
import { type SerializedPlateWithId } from '~/js/utils/breadboard/core/Plate';
import { type Wire } from '~/js/utils/breadboard/core/Wire/Wire';
import { Current, type CurrentPath, type CurrentSerialized, type ElecUnit, } from '../../../core/Current';
import { type Grid } from '../../../core/Grid';
import { Layer, type LayerOptions } from '../../../core/Layer';
import { type XYPoint } from '../../../types';
import { CurrentPopup } from '../../popups/CurrentPopup';
import { CurrentWeightUpdateParams } from "~/js/utils/breadboard/components/layers/ComplexCurrentLayer/constants";

/**
 * Displays and manages {@link Current} objects.
 * Handles current data formats, generates paths for the {@link Current}s.
 *
 * @see Current
 *
 * @category Breadboard
 * @subcategory Layers
 */
export class ComplexCurrentLayer extends Layer {
    /** layer's main SVG container */
    protected _container: SVG.Container;
    protected _popups: Record<number, CurrentPopup>;
    /** list of {@link Current} instances being displayed */
    private readonly _currents: Record<number, Current>;
    private units?: Record<number, ElecUnit>;
    /** simple graphic mode flag */
    private readonly _spare: any;
    /** SVG group for currents */
    private _currentgroup: any;
    /** SVG group for debug */
    private _debuggroup: SVG.G;
    /** whether short circuit is detected  */
    private _shorted: boolean;
    /** local event handlers */
    private readonly _callbacks: {
        shortcircuit: () => void; // short circuit detected
        shortcircuitstart: () => void; // short circuit started
        shortcircuitend: () => void; // short circuit ended
    };

    /**
     * @inheritdoc
     */
    constructor(container: SVG.Container, grid: Grid, options: LayerOptions) {
        super(container, grid, options);

        this._container.addClass(ComplexCurrentLayer.Class);

        this._currents = {};

        this._spare = undefined;

        this._currentgroup = undefined;

        this._shorted = false;

        this._callbacks = {
            shortcircuit: () => {},
            shortcircuitstart: () => {},
            shortcircuitend: () => {},
        };
    }

    /** CSS class of the layer */
    static get Class() {
        return 'bb-layer-current-complex';
    }

    /** The minimum weight of a {@link Current} that is required to render it */
    static get MeaningfulnessThreshold() {
        return 1e-8;
    }

    /**
     * @inheritdoc
     */
    public __compose__() {
        this._initGroups();
    }

    /**
     * @inheritdoc
     */
    public recompose(options: LayerOptions) {
        super.recompose(options);

        this.removeAllCurrents();

        this._clearGroups();
        this._initGroups();
    }

    public getUnits() {
        return this.units;
    }

    public setCurrents(
        units: Record<number, ElecUnit>,
        network: Record<number, Net>,
        jumpers: SerializedPlateWithId[],
    ) {
        this.units = units;

        // all untouched currents will be removed after processing
        for (const current of Object.values(this._currents)) {
            current.___touched = false;
        }

        for (const [net_id, wires] of Object.entries(network)) {
            const unit = units[Number(net_id)];

            // check if there are a corresponding unit related to the net
            if (!unit) {
                continue;
            }

            const jumper = jumpers.find((j) => String(j.id) === net_id);

            if (!jumper) {
                throw new Error(
                    `Cannot map currents to units: Jumper for net #${net_id} is not found`,
                );
            }

            const wslist = Object.values(wires);

            // map Unit Cells Indices to Current Indices to get the right Wire to animate with
            // (the order of currents is guaranteed by the core)
            for (const wire of Object.values(wslist)) {
                const wire_cell = wire.getConnectedCell();

                const pin_idx = jumper.state.position.cells.findIndex((cell) =>
                    wire_cell.isAt(cell.x, cell.y),
                );

                if (pin_idx === -1) {
                    continue;
                }

                const weight = unit.currents[pin_idx];

                const [src, dst] =
                    weight >= 0
                        ? [wire.pos.src, wire.pos.dst]
                        : [wire.pos.dst, wire.pos.src];

                const thread = {
                    src,
                    dst,
                    weight: Math.abs(weight),
                };

                const sameCurrent = Object.values(this._currents).find((current) =>
                    current.hasSameThread(thread),
                );

                // (create new / update existing) & touch
                if (sameCurrent) {
                    const weightUpdateParams = {
                        current: sameCurrent,
                        wire,
                        unit,
                        pin_idx,
                        net_id: Number(net_id),
                    }

                    if (thread.weight < CurrentLayer.MeaningfulnessThreshold) {
                        this._setCurrentWeight({
                            ...weightUpdateParams,
                            weight: sameCurrent.weight,
                        });
                    } else {
                        this._setCurrentWeight({
                            ...weightUpdateParams,
                            weight: thread.weight,
                        });
                        sameCurrent.___touched = true;
                    }
                } else {
                    if (thread.weight >= CurrentLayer.MeaningfulnessThreshold) {
                        const newCurrent = this._createCurrent(
                            thread,
                            wire,
                            unit,
                            pin_idx,
                            Number(net_id),
                        );
                        newCurrent.___touched = true;
                    }
                }
            }
        }

        // remove untouched currents
        for (const current of Object.values(this._currents)) {
            if (!current.___touched) {
                this.removeCurrent(current.id);
            }
        }

        this._findShortCircuits();
    }

    /**
     * Removes all the currents presented in the layer
     */
    public removeAllCurrents() {
        for (const current_id in this._currents) {
            this.removeCurrent(Number(current_id));
        }

        this.units = undefined;
    }

    /**
     * Removes the selected current
     *
     * @param id current ID
     */
    public removeCurrent(id: number) {
        if (typeof id === 'undefined') {
            throw new TypeError("Argument 'id' must be defined");
        }

        if (!(id in this._currents)) {
            throw new TypeError(`Current ${id} does not exist`);
        }

        const current = this._currents[id];
        const popup = this._popups[id];

        current.erase();
        this._requestPopupClear(popup);

        delete this._currents[current.id];
        delete this._popups[current.id];
    }

    /**
     * Attaches callback function as the 'short circuit started' event handler
     *
     * The event triggers after 'shortcircuit' only
     * if it wasn't triggered prevously without a subsequent 'shortcircuitend' event.
     *
     * @param cb callback to attach
     */
    public onShortCircuitStart(cb?: () => void) {
        if (!cb) {
            this._callbacks.shortcircuitstart = () => {};
        }

        this._callbacks.shortcircuitstart = cb || (() => undefined);
    }

    /**
     * Attaches callback function as the 'short circuit ended' event handler
     *
     * The event triggers when there is no short-circuit currents were detected
     * after the moment when 'shortcircuitstart' event has been triggered.
     *
     * @param cb callback to attach
     */
    public onShortCircuitEnd(cb?: () => void) {
        if (!cb) {
            this._callbacks.shortcircuitend = () => {};
        }

        this._callbacks.shortcircuitend = cb || (() => undefined);
    }

    /**
     * Returns all {@link Current} instances presented in the layer at the moment.
     *
     * @returns an object in which the keys are the IDs of the {@link Current} instance presented in the value
     */
    public getAllCurrents(): Record<number, Current> {
        return this._currents;
    }

    private _createCurrent(
        thread: CurrentSerialized,
        wire: Wire,
        unit: ElecUnit,
        pin_idx: number,
        net_id: number,
    ) {
        const current = new Current(this._currentgroup, thread, this.__schematic);
        const popup = new CurrentPopup(String(current.id));

        this._currents[current.id] = current;
        this._popups[current.id] = popup;

        // TODO: Add ability to change the animation direction without need to re-generate the path
        //       Also, the path generation makes impossible to use custom paths (e.g. when using PhysicalWire)
        // const line_path = wire.getPath() || this._getLinePathArbitrary(src, dst);

        // generated path direction is important for animation direction
        const line_path = this._getLinePathArbitrary(thread.src, thread.dst);

        current.draw(line_path);
        current.activate();

        this._requestPopupDraw(popup, {
            current,
            wire,
            unit,
            pin_idx,
            net_id,
            verbose: this.__verbose,
        });

        this._attachEventsHoverable(current, wire);

        return current;
    }

    private _setCurrentWeight({ current, weight, wire, unit, pin_idx, net_id }: CurrentWeightUpdateParams) {
        if (current.weight === weight) {
            return;
        }

        current.setWeight(weight);

        const thread = current.thread;

        if (!thread) {
            // this current is connected to a junction,
            // it cannot take voltages from the line
            return;
        }

        // const line_id = this.__grid.getLineIdByPoint(current.thread.src);
        // const voltage = line_id ? this.__grid.getLineVoltage(line_id) : NaN;
        //
        this._popups[current.id].updateContent({
            current,
            wire,
            unit,
            pin_idx,
            net_id,
            verbose: this.__verbose,
        });
    }

    /**
     * Detects any short-circuited {@link Current}s, i.e. {@link Current}s with the
     * {@link Current.is_burning} flag set
     */
    private _findShortCircuits() {
        for (const id in this._currents) {
            if (!this._currents.hasOwnProperty(id)) {
                continue;
            }

            if (this._currents[id].is_burning) {
                this._callbacks.shortcircuit();

                if (!this._shorted) {
                    this._callbacks.shortcircuitstart();
                    this._shorted = true;
                }

                return;
            }
        }

        if (this._shorted) {
            this._callbacks.shortcircuitend();
        }

        this._shorted = false;
    }

    /**
     * Initializes internal SVG groups
     */
    private _initGroups() {
        this._clearGroups();

        this._currentgroup = this._container.group();

        if (this.__verbose) {
            this._debuggroup = this._container.group();
        }
    }

    /**
     * Removes SVG groups created previously with {@link _initGroups}
     */
    private _clearGroups() {
        if (this._currentgroup) {
            this._currentgroup.remove();
        }
    }

    private _attachEventsHoverable(current: Current, wire: Wire) {
        current.makeHoverable(true);

        current.onMouseEnter(() => {
            this._popups[current.id] &&
            this._requestPopupShow(this._popups[current.id]);
        });

        current.onMouseLeave(() => {
            this._popups[current.id] &&
            this._requestPopupHide(this._popups[current.id]);
        });

        wire.onMouseEnter(() => {
            this._popups[current.id] &&
            this._requestPopupShow(this._popups[current.id]);
        });

        wire.onMouseLeave(() => {
            this._popups[current.id] &&
            this._requestPopupHide(this._popups[current.id]);
        });
    }

    /**
     * Generates the path for current placed in arbitrary cells
     *
     * @param p_src    source point of the current flow
     * @param p_dst    destination point of the current flow
     *
     * @returns a sequence of SVG path commands
     */
    private _getLinePathArbitrary(p_src: XYPoint, p_dst: XYPoint): CurrentPath {
        return [
            ['M', p_src.x, p_src.y],
            ['L', p_dst.x, p_dst.y],
            ['L', p_dst.x, p_dst.y],
        ];
    }
}

import isEqual from 'lodash/isEqual';
import type SVG from 'svg.js';

import { type Cell, type Grid } from '../../../core/Grid';
import { Layer, type LayerOptions } from '../../../core/Layer';
import { getRandomInt } from '../../../helpers/common';

import {
  type ChangeCallback,
  type ChangeCallbackArg,
  Plate,
  type PlateParams,
  type PlateState,
  type SerializedPlate,
  type SerializedPlateState,
  type SerializedPlateWithId,
} from '../../../core/Plate';

import { ContextMenu } from '../../../core/ContextMenu';

import { PlateContextMenu } from '../../menus';

import {
  BatteryPlate,
  BridgePlate,
  ButtonPlate,
  BuzzerPlate,
  CapacitorPlate,
  DummyPlate,
  InductorPlate,
  LEDPlate,
  PhotoresistorPlate,
  ReedSwitchPlate,
  RelayPlate,
  ResistorPlate,
  RGBPlate,
  RheostatPlate,
  SwitchPlate,
  TransistorNpnPlate,
  TRheostatPlate,
  TSwitchPlate,
  UnkPlate,
} from '../../plates';

import { reduce } from 'lodash';
import { DiodePlate } from '~/js/utils/breadboard/components/plates/DiodePlate';
import { IndicatorPlate } from '~/js/utils/breadboard/components/plates/IndicatorPlate';
import { TempSensorPlate } from '~/js/utils/breadboard/components/plates/TempSensorPlate';
import { ThermoresistorPlate } from '~/js/utils/breadboard/components/plates/ThermoresistorPlate';
import { TransistorPnpPlate } from '~/js/utils/breadboard/components/plates/TransistorPnpPlate';
import { getCursorPoint } from '../../../core/Layer/helpers';
import { convertPositionToCellsExternal } from '../../../core/Plate/helpers';
import { JumperPlate } from '../../plates/JumperPlate';
import { PhototransistorPlate } from '../../plates/PhototransistorPlate';
import {
  type AddPlateParams,
  type AddPlateSerializedParams,
  PlateChangeActionType,
  type PlatePrototype,
  type PlateStyle,
  type PseudoMouseEvent,
  type SerializedFields,
} from './types';
import { type BreadboardMode } from '~/js/utils/breadboard/Breadboard.types';
import { isEditingAllowed } from '~/js/utils/breadboard/Breadboard.helpers';
import { type ElecUnit } from '~/js/utils/breadboard/core/Current';

/**
 * Contains plates, provides an API to create, delete plates and update their states
 *
 * Provides an admin user interface to compose plates on the board and
 * to manually change their individual states by instantiating and managing
 * {@link Plate} instances.
 *
 * Also allows to listen to manual and programmatical changes in the composition.
 *
 * @category Breadboard
 * @subcategory Layers
 */
export class PlateLayer extends Layer<SVG.Container> {
  /** CSS class of the layer */
  static get Class() {
    return 'bb-layer-plate';
  }

  /**
   * list of {@link Plate} types which are invariant to 180-degree rotation
   *
   * TODO: Move this information to Plate classes
   */
  static get TypesReversible() {
    return [
      BridgePlate.Alias,
      BatteryPlate.Alias,
      ResistorPlate.Alias,
      PhotoresistorPlate.Alias,
      CapacitorPlate.Alias,
      ButtonPlate.Alias,
      InductorPlate.Alias,
      BuzzerPlate.Alias,
      DummyPlate.Alias,
      UnkPlate.Alias,
    ];
  }

  /** local event handlers */
  private readonly _callbacks: {
    change: ChangeCallback;
    dragstart: (plate: Plate) => void;
  };

  /** SVG containers in which the {@link Plate} instances will be rendered */
  private _plategroup: SVG.Container;
  private _plategroupstatic: SVG.Container;

  /** {@link Plate} instances which currently forms the current composition, keyed by its identifiers */
  private _plates: Record<number, Plate>;
  /** [debug only] {@link Plate} instances that were overwritten accidentally (to detect leakages or key assignment issues). Keys are salted to keep possible duplicates */
  private readonly _plates_old: Record<string, Plate> = {};

  /** the {@link Cell} where the {@link Plate} should go if user interrupts dragging */
  private _cell_supposed: Cell;
  /** the {@link Plate} the user is currently dragging */
  private _plate_dragging?: Plate;
  /** the {@link Plate} that is currently in focus */
  private _plate_selected?: Plate;

  /** the last point where 'mousedown' event has been triggered last time */
  private _cursor_point_mousedown: any;

  /**
   * Checks if two plates are visually identical
   *
   * Visual identity differs from exact equality for several reasons:
   *  - there are multiple combinations of position an orientation that leads to
   *    same cell occupancy, which is needed for visual similarity
   *  - some types of {@link Plate} are indepenent of opposite orientations
   *
   * @param svg           arbitrary SVG document to initialize test board
   * @param grid          {@link Grid} of the test board, must be the same
   *                      as the one used to place the plates being compared
   * @param plate1_data   raw data object defining properties of the first plate
   * @param plate2_data   raw data object defining properties of the second plate
   *
   * @returns are the plates visually identical
   */
  public static comparePlates(
    svg: SVG.Container,
    grid: Grid,
    plate1_data: SerializedPlate,
    plate2_data: SerializedPlate,
  ) {
    if (plate1_data.type !== plate2_data.type) {
      return false;
    }
    if (!isEqual(plate1_data.props, plate2_data.props)) {
      return false;
    }

    const is_rotpos_equal = isEqual(
      plate1_data.state.position.cells,
      plate2_data.state.position.cells,
    );

    if (!PlateLayer.TypesReversible.includes(plate1_data.type)) {
      if (!is_rotpos_equal) {
        return false;
      }
    } else {
      const plate1 = PlateLayer.jsonToPlate(svg, grid, plate1_data);
      const plate2 = PlateLayer.jsonToPlate(svg, grid, plate2_data);

      const { x: p1_x0, y: p1_y0 } = plate1.pos;
      const { x: p2_x0, y: p2_y0 } = plate2.pos;
      const p1_surf_points = plate1.surface;
      const p2_surf_points = plate2.surface;

      for (const p1_surf_point of p1_surf_points) {
        const { x: p1_x, y: p1_y } = Plate._orientXYObject(
          p1_surf_point,
          plate1.state.position.orientation,
        );

        let has_same_point = false;

        for (const p2_surf_point of p2_surf_points) {
          const { x: p2_x, y: p2_y } = Plate._orientXYObject(
            p2_surf_point,
            plate2.state.position.orientation,
          );

          if (p1_x0 + p1_x === p2_x0 + p2_x && p1_y0 + p1_y === p2_y0 + p2_y) {
            has_same_point = true;
            break;
          }
        }

        if (!has_same_point) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * Converts JSON plate data object to rendered plate instance
   *
   * @param svg           SVG container to render to
   * @param grid          grid with proportions required to visualize the plate
   * @param plate_data    plate data object describing its type and properties
   *
   * @returns rendered plate instance
   */
  public static jsonToPlate(
    svg: SVG.Container,
    grid: Grid,
    plate_data: SerializedPlate,
  ) {
    const { id, type, props, state } = plate_data as any;

    const plate_class = PlateLayer.typeToPlateClass(type);
    const plate = new plate_class({
      container: svg,
      grid,
      id,
      props,
      state,
    });
    plate.draw(
      grid.getCell(props.position.x, props.position.y),
      props.position.orientation,
      false,
    );

    return plate;
  }

  constructor(container: SVG.Container, grid: Grid, options: LayerOptions) {
    super(container, grid, options);

    this._container.addClass(PlateLayer.Class);

    this._callbacks = {
      change: () => {},
      dragstart: () => {},
    };

    this._plates = {};

    /// Последняя позиция перемещения курсора
    this._cursor_point_mousedown = undefined;

    this._handleKey = this._handleKey.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._handleMouseUp = this._handleMouseUp.bind(this);
    this._handleMouseMove = this._handleMouseMove.bind(this);
    this._handleContextMenu = this._handleContextMenu.bind(this);
  }

  public __compose__() {
    this._initGroups();
  }

  /**
   * Also, re-instantiates all {@link Plate} instances with all its properties and states.
   */
  public recompose(options: LayerOptions) {
    super.recompose(options);

    const plates_data = this.getSerializedPlates();

    this.removeAllPlates(false, true);

    this.compose();

    for (const plate_data of plates_data) {
      this.addPlateSerialized({
        ...plate_data,
        isStatic: false,
      });
    }
  }

  /**
   * Sets allowed visual properties of plates
   *
   * @param style visual properties of plates
   */
  public setPlateStyle(style?: PlateStyle) {
    if (style?.quad_size != null) {
      Plate.QuadSizePreferred = style.quad_size;
    } else {
      Plate.QuadSizePreferred = Plate.QuadSizeDefault;
    }

    if (style?.led_size != null) {
      Plate.LEDSizePreferred = style.led_size;
    } else {
      Plate.LEDSizePreferred = Plate.LEDSizeDefault;
    }

    if (style?.label_font_size != null) {
      Plate.LabelFontSizePreferred = style.label_font_size;
    } else {
      Plate.LabelFontSizePreferred = Plate.LabelFontSizeDefault;
    }
  }

  /**
   * Serializes plates of the current composition
   *
   * @returns serialized data of current plates
   */
  public getSerializedPlates(withStatic = false): SerializedPlateWithId[] {
    const data = [];

    for (const plate_id of Object.keys(this._plates)) {
      const plate = this._plates[Number(plate_id)];

      // if (plate.alias === JumperPlate.Alias) {
      //   continue;
      // }

      if (!withStatic && plate.___static) {
        continue;
      }

      data.push(plate.serialize());
    }

    return data;
  }

  public getFields(): Record<number, PlateState['field']> {
    return Object.values(this.getSerializedPlates(true)).reduce(
      (acc, plate) => ({
        ...acc,
        ...(plate.state.field != null && {
          [Number(plate.id)]: plate.state.field,
        }),
      }),
      {},
    );
  }

  public setFields(fields: SerializedFields) {
    for (const [plateId, field] of Object.entries(fields)) {
      if (!this._plates[Number(plateId)]) {
        console.warn(
          `Tried to set field of plate #${plateId}, but it does not exist`,
        );
        continue;
      }

      this._plates[Number(plateId)].setState({ field });
    }
  }

  /**
   * Gets plate instance by its identifier
   *
   * @param plate_id plate identifier
   *
   * @returns plate instance if exists
   */
  public getPlateById(plate_id: number): Plate {
    if (!(plate_id in this._plates)) {
      throw new RangeError('Plate does not exist');
    }

    return this._plates[plate_id];
  }

  /**
   * Compiles random composition of plates based on their prototypes
   *
   * The method will generate random number of plates (within the limits given) additively. \
   * It means it wouldn't apply some kind of tiling but instead it will mount each next plate iteratively
   * until the planned number of them is reached.
   *
   * Since the board has a finite size, high values of {@link size_mid} and {@link size_deviation}
   * may lead the generation to fail. In this case, some number of additional attempts will be applied.
   * This can be adjusted via {@link attempts_max}.
   *
   * @param _protos           limitations on types, max quantities and properties of the plates
   * @param size_mid          mean total quantity of plates to generate
   * @param size_deviation    deviation of quantity of plates to generate
   * @param attempts_max      maximum number of attempts to generate if failed
   */
  public setRandom(
    _protos: PlatePrototype[],
    size_mid: number = 10,
    size_deviation: number = 2,
    attempts_max: number = 40,
  ) {
    const protos = [];

    const orientations = [
      Plate.Orientations.West,
      Plate.Orientations.East,
      Plate.Orientations.North,
      Plate.Orientations.South,
    ];

    for (const proto of _protos) {
      if (proto.quantity > 0) {
        protos.push({
          type: proto.type,
          props: proto.props,
          qty: proto.quantity,
        });
      }
    }

    let remaining = size_mid + getRandomInt(-size_deviation, size_deviation);

    while (remaining > 0) {
      if (protos.length === 0) {
        return;
      }

      const p_index = getRandomInt(0, protos.length - 1);
      const proto = {
        type: protos[p_index].type,
        props: protos[p_index].props,
        qty: protos[p_index].qty,
      };

      if (proto.qty === 1) {
        protos.splice(p_index, 1);
      }

      proto.qty--;

      let placed: number | null = null;
      let attempts = 0;

      while (!placed && attempts < attempts_max) {
        const orientation =
          orientations[getRandomInt(0, orientations.length - 1)];

        const x = getRandomInt(0, this.__grid.dim.x - 1);
        const y = getRandomInt(0, this.__grid.dim.y - 1);

        const cells = convertPositionToCellsExternal(
          this._container,
          this.__grid,
          proto.type,
          proto.props,
          { x, y },
          orientation,
        );

        placed = this.addPlate({
          id: null,
          type: proto.type,
          props: proto.props,
          state: {
            position: {
              cells,
            },
          },
        });

        if (placed != null) {
          if (this.hasIntersections(placed)) {
            this.removePlate(placed);
            placed = null;
          }
        }

        attempts++;
      }

      remaining--;
    }
  }

  /**
   * Checks if the plate intersects with others
   *
   * @param plate_id ID of the plate needed to check
   *
   * @returns whether the plate intersects with at least one another plate
   */
  public hasIntersections(plate_id: number): boolean {
    const plate = this._plates[plate_id];
    const { x: px0, y: py0 } = plate.pos;
    const plate_rels = plate.surface;

    for (const p of Object.values(this._plates)) {
      const { x: x0, y: y0 } = p.pos;
      const p_rels = p.surface;

      if (p.id === plate.id) {
        continue;
      }

      if (x0 === px0 && y0 === py0) {
        return true;
      }

      for (const p_rel of p_rels) {
        const { x, y } = Plate._orientXYObject(
          p_rel,
          p.state.position.orientation,
        );

        if (plate_rels) {
          for (const plate_rel of plate_rels) {
            const { x: px, y: py } = Plate._orientXYObject(
              plate_rel,
              plate.state.position.orientation,
            );

            if (px0 + px === x0 + x && py0 + py === y0 + y) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  /**
   * Toggles editability of the plates
   *
   * Editability means ability to select, move, rotate, delete and
   * open the context menu of any plate in the layer.
   *
   * @param mode
   */
  public setMode(mode: BreadboardMode) {
    super.setMode(mode);

    if (mode === this.__mode) {
      return;
    }

    this._setCurrentPlatesEditable(isEditingAllowed(mode));
  }

  /**
   * Creates a plate from a drag-and-drop action
   *
   * This method is intended to call as a dragging handler when user takes a plate
   * from the flyout selector menu (see {@link SelectorLayer})
   *
   * The plate is placed in a drag-and-drop state immediately after instantation.
   * It's position adjusts to the cursor position and holds until the mouse button is released.
   * The plate created then keeps the focus, so even if there was no drag (just a single click),
   * the plate is placed at first suitable position and remains focused (selected).
   *
   * @param plate_data    serialized object from the plate configured in the flyout
   * @param plate_x       X position of the SVG document for the plate preview from the flyout
   * @param plate_y       Y position of the SVG document for the plate preview from the flyout
   * @param cursor_x      X position of the client cursor
   * @param cursor_y      Y position of the client cursor
   */
  public takePlate(
    plate_data: SerializedPlate,
    plate_x: number,
    plate_y: number,
    cursor_x: number,
    cursor_y: number,
  ) {
    const id = this.addPlateSerialized({
      type: plate_data.type,
      props: plate_data.props,
      state: plate_data.state,
    });

    if (!id) {
      return;
    }

    const plate = this._plates[id];

    if (this._container.node instanceof SVGSVGElement) {
      const plate_point = getCursorPoint(
        this._container.node,
        plate_x,
        plate_y,
      );

      plate.center_to_point(plate_point.x, plate_point.y);
      this._handlePlateMouseDown(
        { which: 1, clientX: cursor_x, clientY: cursor_y },
        plate,
      );
      this.selectPlate(plate);
    } else {
      throw new Error(
        `${this.constructor.name}'s container is not an ${SVGSVGElement.name}`,
      );
    }
  }

  /**
   * Creates a new plate in the layer
   *
   * @param id                preferred plate identifier (should be unique for different plates)
   * @param type              plate alias
   * @param props             custom plate properties
   * @param state             initial plate state
   * @param animate           animate plate appearance
   * @param suppress_error    log errors instead of throwing exceptions
   *
   * @returns created plate identifier
   */
  public addPlate({
    id = null,
    type,
    props,
    state,
    animate = false,
    suppressError = false,
    isStatic = false,
  }: AddPlateParams) {
    const layer = isStatic ? this._plategroupstatic : this._plategroup;

    let plate_class, plate;

    if (!state?.position) {
      throw new Error('Plate position is not defined');
    }

    // TODO: Move to separate method. Use then in setPlates().
    if (id != null && id in this._plates && this._plates[id].alias === type) {
      const plate = this._plates[id];

      const posrot = plate.convertCellsToPosition(state?.position?.cells);

      this._plates[id].rotate(posrot.orientation);
      return id;
    } else {
      plate_class = PlateLayer.typeToPlateClass(type);

      plate = new plate_class({
        container: layer,
        grid: this.__grid,
        id,
        props,
        state,
        options: {
          schematic: this.__schematic,
          verbose: this.__verbose,
        },
      });
      plate.___static = isStatic;
    }

    this._attachPlateEvents(plate);

    if (isEditingAllowed(this.__mode) || plate.___static) {
      plate.onChange((data: ChangeCallbackArg) => {
        this._callbacks.change(data);
      });
    }

    try {
      const posrot = plate.convertCellsToPosition(state.position.cells);

      plate.draw(
        this.__grid.getCell(posrot.cell.x, posrot.cell.y),
        posrot.orientation,
        animate,
      );
    } catch (err) {
      if (!suppressError) {
        console.error('Cannot draw the plate', err);
      }

      plate.dispose();

      return null;
    }

    // Move old plate with same id to prevent overwriting
    // If this plate will exist after full board refresh, it can help when debugging
    if (plate.id in this._plates) {
      const old_plate = this._plates[plate.id];
      // const randpostfix = Math.floor(Math.random() * (10 ** 6));
      // this._plates_old[`_old_${plate.id}_#${randpostfix}`] = old_plate;
      console.info(
        'Plate',
        old_plate.id,
        'of type',
        `'${old_plate.alias}'`,
        'will be overwritten with the plate of type',
        `'${plate.alias}'`,
      );
      old_plate.dispose();
    }

    this._plates[plate.id] = plate;

    return plate.id;
  }

  /**
   * Creates a new plate by serialized properties
   *
   * @param type              plate alias
   * @param position          serialized plate position
   * @param id                prefferred plate id (should be unique for different plates)
   * @param props             custom plate properties
   * @param animate           animate plate appearance
   * @param suppressError     log errors instead of throwing exceptions
   *
   * @returns created plate identifier
   */
  public addPlateSerialized({
    id,
    type,
    props,
    state,
    animate,
    suppressError,
    isStatic,
  }: AddPlateSerializedParams) {
    return this.addPlate({
      id,
      type,
      props,
      state,
      animate,
      suppressError,
      isStatic,
    });
  }

  /**
   * Displays entire composition of plates
   *
   * Creates new (provided but non-existent yet) plates,
   * updates current (provided and existing) plates,
   * removes old (existing but non-provided) plates.
   *
   * If there are no changes at all, no events will be triggered.
   *
   * @param plates list of plates that should be displayed at the layer
   * @param isStatic
   *
   * @returns are there any changes in the composition
   */
  public setPlates(
    plates: SerializedPlate[],
    isStatic: boolean = false,
    isSilent: boolean = false,
  ): boolean {
    /// are there any changes
    let is_dirty = false;

    /// remove possible flags from local plates
    for (const plate_id in this._plates) {
      this._plates[Number(plate_id)].___touched = undefined;
      this._plates[Number(plate_id)].highlightError(false);
    }

    /// perform main loop
    for (const plate of plates) {
      /// if there are no plate, skip the iteration
      if (!plate) {
        continue;
      }

      // check if there are any changes
      if (
        !(plate.id && plate.id in this._plates) ||
        plate.type === JumperPlate.Alias
      ) {
        is_dirty = true;
      }

      /// ID of the new/current plate
      let id;

      /// extra-parameter may be under another key
      // plate.extra = plate.extra || plate.number;

      /// add a plate if there is none
      id = this.addPlateSerialized({
        id: plate.id,
        type: plate.type,
        props: plate.props,
        state: plate.state,
        isStatic,
      });

      /// if the plate created with no errors / exists already
      if (id != null) {
        /// set the flag on it
        this._plates[id].___touched = true;

        /// update its state
        this.setPlateState(id, plate.state);
      }
    }

    /// remove unflagged plates
    for (const plate_id in this._plates) {
      const plate = this._plates[plate_id];
      if (!plate.___touched && !plate.___static) {
        this.removePlate(Number(plate_id));

        is_dirty = true;
      }
    }

    if (is_dirty) {
      const platesDynamic = reduce(
        Object.entries(this._plates),
        (acc, [id, plate]) => (plate.___static ? acc : { ...acc, [id]: plate }),
        {},
      );

      this._callbacks.change(platesDynamic);
    }

    return is_dirty;
  }

  /**
   * Highlights given plates
   *
   * @param plate_ids IDs of plates needed to highlight
   */
  public highlightPlates(plate_ids: number[]) {
    if (!plate_ids) {
      return;
    }

    for (const plate_id in this._plates) {
      this._plates[plate_id].highlightError(false);
    }

    for (const plate_id of plate_ids) {
      if (!(plate_id in this._plates)) {
        throw new RangeError('Plate does not exist');
      }

      this._plates[plate_id].highlightError(true);
    }
  }

  /**
   * Remove a plate
   *
   * @param id ID of the plate needed to remove
   */
  public removePlate(id: number, is_silent?: boolean) {
    if (typeof id === 'undefined') {
      throw new TypeError("Argument 'id' must be defined");
    }

    if (!(id in this._plates)) {
      throw new TypeError(`Plate ${id} does not exist`);
    }

    const plate = this._plates[id];

    plate.dispose();

    delete this._plates[id];

    if (!is_silent) {
      this._callbacks.change({
        id: plate.id,
        action: PlateChangeActionType.Remove,
      });
    }
  }

  public getJumperPlates() {
    return this.getSerializedPlates().filter(
      (plate) => plate.type === JumperPlate.Alias,
    );
  }

  public setJumperPlates(plates: SerializedPlate[]) {
    const jumper_plates = Object.values(this._plates).filter(
      (plate) => plate.alias === JumperPlate.Alias,
    );

    for (const plate of jumper_plates) {
      this.removePlate(plate.id, true);
    }

    for (const plate of plates) {
      this.addPlateSerialized(plate);
    }

    const new_plates = Object.values(this._plates).filter(
      (plate) => plate.alias === JumperPlate.Alias,
    );

    this._callbacks.change(new_plates);
  }

  public setPlatesFields(states: Record<number, ElecUnit>) {
    for (const [plateId, unit] of Object.entries(states)) {
      if (plateId in this._plates) {
        this.setPlateState(Number(plateId), { field: unit.field });
      }
    }
  }

  /**
   * Set the state of a plate
   *
   * @param plate_id    plate identifier
   * @param state       state to set
   */
  public setPlateState(
    plate_id: number,
    state: Partial<SerializedPlateState<PlateState>>,
  ) {
    if (!(plate_id in this._plates)) {
      console.debug('This plate does not exist', plate_id);
      return false;
    }

    const plate = this._plates[plate_id];

    plate.setState(state, true);
  }

  /**
   * Remove all existing plates from the layer
   */
  public removeAllPlates(with_static = false, is_silent = false) {
    for (const plate_id in this._plates) {
      if (!with_static && this._plates[plate_id].___static) {
        continue;
      }

      this.removePlate(Number(plate_id), is_silent);
    }
  }

  /**
   * Attach a layer change event handler
   *
   * @param cb callback function to be called when the layer is changed
   */
  public onChange(cb?: ChangeCallback) {
    this._callbacks.change = cb || (() => undefined);
  }

  /**
   * Attach a 'plate drag start' event handler
   *
   * @param cb callback function to be called when the drag starts
   */
  public onDragStart(cb: () => void) {
    this._callbacks.dragstart = cb || (() => undefined);
  }

  /**
   * Handles context menu item click
   *
   * @param plate_id      plate identifier
   * @param action_alias  context menu item alias
   * @param value         value passed to the item, if possible
   */
  public handlePlateContextMenuItemClick(
    plate_id: number,
    action_alias: string,
    value: any,
  ) {
    if (!this._plates[plate_id]) {
      return;
    }

    const plate = this._plates[plate_id];

    switch (action_alias) {
      case PlateContextMenu.CMI_REMOVE: {
        this.removePlate(plate.id);
        break;
      }
      case PlateContextMenu.CMI_ROTCW: {
        this._plates[plate.id].rotateClockwise();
        break;
      }
      case PlateContextMenu.CMI_ROTCCW: {
        this._plates[plate.id].rotateCounterClockwise();
        break;
      }
      case PlateContextMenu.CMI_DUPLIC: {
        this._duplicatePlate(plate);
        break;
      }
      // case PlateContextMenu.CMI_INPUT: {
      //   plate.setState({ input: value });
      //   break;
      // }
    }
  }

  /**
   * Sets focus on a given plate
   */
  public selectPlate(plate: Plate) {
    /// If the plate hasn't selected previously
    if (this._plate_selected && plate !== this._plate_selected) {
      /// Remove the focus from selected one
      this._plate_selected.deselect();
      /// Disable its events handling
      this.setPlateSelected(plate, false);
      // this._plate_selected.onChange(null);
    }

    /// Enable event handling for required plate
    this.setPlateSelected(plate, true);
    // plate.onChange(this._callbacks.change);
    plate.onDragFinish(() => {
      this._onPlateDragFinish(plate);
    });
    plate.onDragStart(() => {
      this._onPlateDragStart(plate);
      this._callbacks.dragstart(plate);
    });

    /// highlight selection of the plate
    this._plate_selected = plate;
    this._plate_selected.select();
  }

  /**
   * Enables or disables plate selection
   *
   * @param plate     plate instance
   * @param selected  should the plate be editable or not
   *
   * @returns whether the flag was set
   */
  public setPlateSelected(plate: Plate, selected: boolean = true) {
    plate.setSelectedVisual(selected);

    if (selected) {
      plate.onMouseDown((evt: MouseEvent) => {
        this._handlePlateMouseDown(evt, plate);
      });
      plate.onMouseWheel((evt: WheelEvent) => {
        this._onPlateMouseWheel(evt, plate);
      });
    } else {
      plate.onMouseDown();
      plate.onMouseWheel();
    }
  }

  /**
   * Initializes internal SVG groups
   */
  private _initGroups() {
    this._clearGroups();

    this._plates = {};
    this._plategroupstatic = this._container.group();
    this._plategroup = this._container.group();

    this._attachEvents();
    this._addStaticPlates();
  }

  /**
   * Removes SVG groups created previously with {@link _initGroups}
   */
  private _clearGroups() {
    if (this._plategroup) {
      this._plategroup.remove();
    }
    if (this._plategroupstatic) {
      this._plategroupstatic.remove();
    }
  }

  private _addStaticPlates() {
    if (this.__grid.plates) {
      this.setPlates(this.__grid.plates, true);
    }
  }

  /**
   * Toggle layer events
   *
   * @returns whether the flag is set
   */
  private _attachEvents() {
    document.addEventListener('click', this._handleClick, false);
    document.addEventListener('keydown', this._handleKey, false);
    document.addEventListener('keyup', this._handleKey, false);
    document.addEventListener('contextmenu', this._handleContextMenu, false);
  }

  private _setCurrentPlatesEditable(is_editable = false) {
    this._clearContextMenus();

    for (const plate of Object.values(this._plates)) {
      plate.setEditable(is_editable);
    }
  }

  /**
   * Attaches internal handlers to plates events
   *
   * @param plate the plate whose events need to be processed
   */
  private _attachPlateEvents(plate: Plate) {
    if (!plate) {
      throw new TypeError('A `plate` argument must be defined');
    }

    /// Когда на плашку нажали кнопкой мыши
    plate.container.mousedown((evt: MouseEvent) => {
      const target = evt.target as HTMLElement;

      evt.preventDefault();

      if (target.classList.contains(ContextMenu.ItemClass)) {
        return;
      }
      if (target.classList.contains(ContextMenu.ItemInputClass)) {
        return;
      }

      this.selectPlate(plate);

      if (evt.which === 1 && this._plate_selected) {
        this._handlePlateMouseDown(evt, this._plate_selected);
      }
    });
  }

  /**
   * Handles layer click events
   *
   * Free layer click leads to de-focusing any selected plates and disabling its editability.
   */
  private _handleClick(evt: MouseEvent) {
    let el = evt.target as HTMLElement | null | undefined;

    /// Определить, является ли элемент, по которому выполнено нажатие, частью плашки
    while ((el = el?.parentElement) && !el.classList.contains(Plate.Class)) {}

    /// Если не попали по плашке, но есть выделенная плашка
    if (!el && this._plate_selected) {
      /// Снять выделение
      this._plate_selected.deselect();

      /// Отключить её события
      this.setPlateSelected(this._plate_selected, false);
      // this._plate_selected.onChange(null);

      this._plate_selected = undefined;
    }
  }

  /**
   * Handles plate click events
   *
   * The method is usually called on real mouse event when user clicked the plate
   * to change its position on the board.
   *
   * The drag and drop process takes place in 3 stages:
   *  - When the mouse button is down on the plate, listen to mouse movements.
   *  - Handle mouse movement events and drag the plate accordingly.
   *  - When the mouse button is released, stop listening to movements.
   *
   * This method initiates dragging, and it may be needed to do this manually,
   * e.g. to create the feeling of pulling out a plate from the selector menu
   * (see {@link SelectorLayer}), when a plate is instantiated on the fly
   * after clicking on an item in the flyout on another layer.
   *
   * @param evt   real mouse event or pseudo event to imitate it
   * @param plate the instance of the plate that was clicked
   */
  private _handlePlateMouseDown(
    evt: PseudoMouseEvent | MouseEvent,
    plate: Plate,
  ) {
    if (!(this._container.node instanceof SVGSVGElement)) {
      return;
    }

    if (evt.which === 1 && !this._plate_dragging) {
      plate.rearrange();

      if (plate.___static || !isEditingAllowed(this.__mode)) {
        return;
      }

      // subscribe to mouse movements to move the plate while dragging
      document.body.addEventListener('mousemove', this._handleMouseMove, false);
      // until the mouse button is released, so subscribe to mouse btn release to stop dragging
      document.body.addEventListener('mouseup', this._handleMouseUp, false);

      this._cursor_point_mousedown = getCursorPoint(
        this._container.node,
        evt.clientX,
        evt.clientY,
      );

      this._plate_dragging = plate;
      this._cell_supposed = plate.calcSupposedCell();
    }
  }

  /**
   * Handles mouse movements when dragging cursor after clicking down on the plate ({@link _handlePlateMouseDown})
   *
   * @param evt mouse movement event
   */
  private _handleMouseMove(evt: MouseEvent) {
    if (!(this._container.node instanceof SVGSVGElement)) {
      return;
    }

    const cursor_point = getCursorPoint(
      this._container.node,
      evt.clientX,
      evt.clientY,
    );

    const dx = cursor_point.x - this._cursor_point_mousedown.x;
    const dy = cursor_point.y - this._cursor_point_mousedown.y;

    this._cursor_point_mousedown = cursor_point;

    if (dx !== 0 || dy !== 0) {
      this._plate_dragging?.dmove(dx, dy);
    }
  }

  /**
   * Handles mouse button release after clicking down ({@link _handlePlateMouseDown})
   * and dragging ({@link _handleMouseMove})
   *
   * The plate automatically snaps to the nearest possible cell under it.
   *
   * @param evt mouse button release event
   */
  private _handleMouseUp(evt: MouseEvent) {
    if (evt.which === 1) {
      document.body.removeEventListener(
        'mousemove',
        this._handleMouseMove,
        false,
      );
      document.body.removeEventListener('mouseup', this._handleMouseUp, false);

      // Snap & release
      this._plate_dragging?.snap();
      this._plate_dragging = undefined;
    }
  }

  /**
   * Handles mouse wheel scroll event when the cursor is over the plate
   *
   * Mouse wheel is bound to the plate rotation.
   *
   * @param evt   mouse wheel scroll event
   * @param plate the instance of the plate the cursor is over
   */
  private _onPlateMouseWheel(evt: WheelEvent, plate: Plate) {
    if (!isEditingAllowed(this.__mode)) {
      return;
    }

    if (evt.deltaY > 16) {
      plate.rotateClockwise();
    }

    if (evt.deltaY < -16) {
      plate.rotateCounterClockwise();
    }
  }

  /**
   * Handles context menu call event
   */
  private _handleContextMenu(evt: MouseEvent) {
    // ie 9+ only
    let el = evt.target as HTMLElement | null | undefined;

    /// Define if the element clicked is a part of the plate
    while ((el = el?.parentElement) && !el.classList.contains(Plate.Class)) {}

    /// If the element is the part of the plate, proceed to call
    if (el) {
      evt.preventDefault();

      const plate = this._plate_selected;

      const ctxmenu = plate?.getCmInstance();

      if (ctxmenu) {
        this._callContextMenu(ctxmenu, { x: evt.pageX, y: evt.pageY }, [
          // plate.state.input,
        ]);
      }
    }
  }

  /**
   * Handles keyboard button press event
   */
  private _handleKey(evt: KeyboardEvent) {
    const keydown = evt.type === 'keydown';

    if (!isEditingAllowed(this.__mode)) {
      return;
    }

    /// If there is a plate with focus
    if (this._plate_selected) {
      /// Remove opened context menus
      this._clearContextMenus();

      if (keydown) {
        switch (evt.code) {
          case 'Period': // >
            this._plate_selected.inputIncrement();
            evt.preventDefault();
            break;
          case 'Comma': // <
            this._plate_selected.inputDecrement();
            evt.preventDefault();
            break;
          case 'BracketRight':
            this._plate_selected.rotateClockwise();
            evt.preventDefault();
            break;
          case 'BracketLeft':
            this._plate_selected.rotateCounterClockwise();
            evt.preventDefault();
            break;
          case 'ArrowLeft':
            this._plate_selected.shift(-1, 0);
            evt.preventDefault();
            break;
          case 'ArrowRight':
            this._plate_selected.shift(1, 0);
            evt.preventDefault();
            break;
          case 'ArrowUp':
            this._plate_selected.shift(0, -1);
            evt.preventDefault();
            break;
          case 'ArrowDown':
            this._plate_selected.shift(0, 1);
            evt.preventDefault();
            break;
          case 'KeyD':
            this._duplicatePlate(this._plate_selected);
            evt.preventDefault();
            break;
          case 'Delete':
            /// Remove it
            this.removePlate(this._plate_selected.id);
            this._plate_selected = undefined;
            evt.preventDefault();
            break;
        }
      }
    }

    this._plate_selected &&
      this._plate_selected.handleKeyPress(evt.code, keydown);
  }

  /**
   * Duplicates a plate
   *
   * Duplicated plate places at the same cell as the original one.
   * Animation is enabled to highlight the appearance in the same place.
   *
   * @param plate source plate
   */
  private _duplicatePlate(plate: Plate) {
    const new_plate_id = this.addPlate({
      type: plate.alias,
      props: plate.props,
      state: plate.serialize().state,
      animate: true,
    });

    if (!new_plate_id) {
      return;
    }

    this._plates[new_plate_id].setState(plate.serialize().state);
    this._plates[new_plate_id].click();
  }

  /**
   * Handles the start of a plate drag-and-drop
   *
   * All other plates are "frozen".
   *
   * @param plate the plate being dragged
   */
  private _onPlateDragStart(plate: Plate) {
    const id = String(plate.id);

    for (const pl_id in this._plates) {
      if (pl_id !== id) {
        this._plates[pl_id].freeze();
      }
    }
  }

  /**
   * Handles the end of a plate drag-and-drop
   *
   * All other plates are "unfrozen"
   *
   * @param plate the plate being dragged
   */
  private _onPlateDragFinish(plate: Plate) {
    const id = String(plate.id);

    for (const pl_id in this._plates) {
      if (pl_id !== id) {
        this._plates[pl_id].unfreeze();
      }
    }
  }

  /**
   * Converts plate type to its class
   *
   * @param type string plate type alias
   *
   * @returns the class of the plate
   */
  public static typeToPlateClass(
    type: string,
  ): new (args: PlateParams<any, any>) => Plate<any, any> {
    if (!type) {
      throw new TypeError('Parameter `type` is not defined');
    }

    switch (type) {
      case JumperPlate.Alias: {
        return JumperPlate;
      }
      case ResistorPlate.Alias: {
        return ResistorPlate;
      }
      case PhototransistorPlate.Alias: {
        return PhototransistorPlate;
      }
      case PhotoresistorPlate.Alias: {
        return PhotoresistorPlate;
      }
      case ThermoresistorPlate.Alias: {
        return ThermoresistorPlate;
      }
      case TRheostatPlate.Alias: {
        return TRheostatPlate;
      }
      case RheostatPlate.Alias: {
        return RheostatPlate;
      }
      case BridgePlate.Alias: {
        return BridgePlate;
      }
      case ButtonPlate.Alias: {
        return ButtonPlate;
      }
      case TSwitchPlate.Alias: {
        return TSwitchPlate;
      }
      case SwitchPlate.Alias: {
        return SwitchPlate;
      }
      case ReedSwitchPlate.Alias: {
        return ReedSwitchPlate;
      }
      case CapacitorPlate.Alias: {
        return CapacitorPlate;
      }
      case TransistorNpnPlate.Alias: {
        return TransistorNpnPlate;
      }
      case TransistorPnpPlate.Alias: {
        return TransistorPnpPlate;
      }
      case InductorPlate.Alias: {
        return InductorPlate;
      }
      case RelayPlate.Alias: {
        return RelayPlate;
      }
      case LEDPlate.Alias: {
        return LEDPlate;
      }
      case BuzzerPlate.Alias: {
        return BuzzerPlate;
      }
      case RGBPlate.Alias: {
        return RGBPlate;
      }
      case DummyPlate.Alias: {
        return DummyPlate;
      }
      case BatteryPlate.Alias: {
        return BatteryPlate;
      }
      case IndicatorPlate.Alias: {
        return IndicatorPlate;
      }
      case DiodePlate.Alias: {
        return DiodePlate;
      }
      case TempSensorPlate.Alias: {
        return TempSensorPlate;
      }
      case UnkPlate.Alias: {
        return UnkPlate;
      }
      default: {
        throw new RangeError(`Unknown plate type '${type}'`);
      }
    }
  }
}

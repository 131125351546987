import type SVG from 'svg.js';

import { type Cell } from '../../core/Grid';
import {
  Plate,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from '../../core/Plate';
import { mod } from '../../helpers/common';
import { PLATE_ALIASES } from './constants';

type RheostatPlateState = PlateState & {
  field: number;
};

/**
 * T-Rheostat plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class TRheostatPlate extends Plate<PlateProps, RheostatPlateState> {
  static get Alias() {
    return PLATE_ALIASES.T_RHEOSTAT;
  }

  static get Layouts() {
    return ['v5x', 'v8x'];
  }

  private readonly _svginp: SVG.Text;

  private readonly _svginpbg: SVG.Rect;

  protected __defaultAttrs__(): any {
    return {
      ...super.__defaultAttrs__(),

      size: { x: 3, y: 2 },
      surface: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 1, y: 1 },
      ],
      origin: { x: 0, y: 0 },
    };
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {
      field: 0,
    };
  }

  /**
   * @deprecated
   * @inheritdoc
   */
  public get input() {
    return Number(this.state.field);
  }

  /**
   * @inheritdoc
   */
  public inputIncrement() {
    this.setState({ field: mod(Number(this.state.field) + 1, 256) });
  }

  /**
   * @inheritdoc
   */
  public inputDecrement() {
    this.setState({ field: mod(Number(this.state.field) - 1, 256) });
  }

  /**
   * @inheritdoc
   */
  public setState(
    state: Partial<SerializedPlateState<RheostatPlateState>>,
    suppress_events: boolean = false,
  ) {
    if (state.field === undefined) {
      return;
    }

    let field = state.field || 0;

    field = Math.min(Math.max(field, 0), 255);

    super.setState({ field }, suppress_events);

    if (this.options.verbose) {
      this._redrawInput(state.field);
    }
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    if (this.options.verbose) {
      this._redrawInput(this.state.field);
    }

    // this._group.text(`Resistor ${this._params.resistance} Ohm`).font({size: 20});
  }

  /**
   * Draws a rheostat over the plate surface
   *
   * @param qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(2, 0);
    const cell3 = this.__grid.getCell(1, 1);

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);
    const rect3 = this._group.rect(qs, qs);

    rect1
      .cx(cell1.center_rel.x)
      .cy(cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .cx(cell2.center_rel.x)
      .cy(cell2.center_rel.y)
      .addClass('bb-plate-fill');
    rect3
      .cx(cell3.center_rel.x)
      .cy(cell3.center_rel.y)
      .addClass('bb-plate-fill');

    const contact_point = {
      x: cell3.center_rel.x,
      y: cell1.center_rel.y,
    };

    const line_right = this._group.path([
      ['M', cell1.center_rel.x, cell1.center_rel.y],
      ['H', cell3.rel.x],
      ['L', contact_point.x, contact_point.y],
    ]);

    const line_left = this._group.path([
      ['M', cell2.center_rel.x, cell2.center_rel.y],
      ['H', cell3.rel.x + cell3.size.x],
      ['L', contact_point.x, contact_point.y],
    ]);

    const line_middle = this._group.path([
      ['M', cell3.center_rel.x, cell3.center_rel.y],
      ['V', contact_point.y + qs / 2],
    ]);

    line_right.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');
    line_left.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');
    line_middle.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');

    const body = this._group
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .rect(qs * 2, qs / 1.5)
      .stroke({ width: 3 })
      .center(contact_point.x, cell1.center_rel.y);

    // let arrow_height = qs * 1.5;
    // let arrow_width = qs * 2;
    //
    // let arrow = this._group.polyline([
    //     [arrow_width, -arrow_height],
    //     [0, -arrow_height],
    //     [0, 0],
    // ])
    //     .stroke({width: 3})
    //     .fill("none")
    //     .x(body.cx())
    //     .y(body.y() - arrow_height);

    line_middle.marker('end', qs / 2, qs / 2, function (add) {
      add
        .path([
          ['M', 0, 0],
          ['l', -qs / 2, -qs / 4],
          ['l', 0, qs / 2],
          ['l', qs / 2, -qs / 4],
        ])
        .addClass('bb-plate-fill')
        .addClass('bb-plate-stroke')
        .stroke({ width: 0.5 })
        .move(-qs / 4, 0);
    });
  }
}

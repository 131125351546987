import { type Cell } from '../../core/Grid';

import { Plate } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Transistor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class TransistorNpnPlate extends LinearPlate {
  static get Alias() {
    return PLATE_ALIASES.TRANSISTOR_NPN;
  }

  protected __defaultAttrs__(): any {
    return {
      ...super.__defaultAttrs__(),
      rels: [
        { x: 0, y: 0, adj: { x: 0, y: 0 } },
        { x: 1, y: 0, adj: { x: 0, y: +1 / 3 } },
        { x: 2, y: 0, adj: { x: 0, y: 0 } },
      ],
      adjs: {
        [Plate.Orientations.North]: { x: 0, y: 0 },
        [Plate.Orientations.South]: { x: 0, y: 0 },
      },
    };
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected get __length__() {
    return 3;
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    // this._group.text(`Button`).font({size: 20});
  }

  /**
   * Draws a transistor over the plate surface
   *
   * @param {number} qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(1, 0);
    const cell3 = this.__grid.getCell(2, 0);

    // line_top_width
    const ltw = 2;

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);
    const rect3 = this._group.rect(qs, qs);

    rect1
      .cx(cell1.center_rel.x)
      .cy(cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .cx(cell2.center_rel.x)
      .cy(cell2.center_rel.y + qs / 2)
      .addClass('bb-plate-fill');
    rect3
      .cx(cell3.center_rel.x)
      .cy(cell3.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect3.x() - rect1.x() - qs * 2;

    const line_gap = line_len / 8;

    // Top line 1
    this._group
      .path([
        ['M', cell1.center_rel.x, cell1.center_rel.y],
        ['l', qs, -qs],
        ['l', line_len / 2 - line_gap, 0],
        ['l', qs / 2, qs / 2],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: ltw })
      .fill('none');

    // Top line 2
    this._group
      .path([
        ['M', cell3.center_rel.x, cell3.center_rel.y],
        ['l', -qs, -qs],
        ['l', -(line_len / 2 - line_gap), 0],
        ['l', -qs / 2, qs / 2],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: ltw })
      .fill('none');

    // Center line
    this._group
      .path([
        ['M', 0, 0],
        ['l', line_gap * 4, 0],
        ['M', (line_gap * 4) / 2, 0],
        ['l', 0, qs / 2],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: ltw })
      .fill('none')
      .x(cell2.center_rel.x - line_gap * 2)
      .y(cell1.center_rel.y - qs / 2);

    const arrow = this._group
      .polyline([
        [0, 0],
        [qs / 2, 0],
        [qs / 4, -qs / 3],
        [0, 0],
      ])
      .addClass('bb-plate-fill');

    if (this._getLabel() === 'PNP') {
      arrow
        .move(
          cell2.rel.x + cell2.size.x / 2 + qs / 4,
          cell2.center_rel.y - qs / 1.3,
        )
        .rotate(-135);
    } else {
      arrow
        .move(cell2.rel.x + cell2.size.x / 2 + qs / 2, cell2.center_rel.y - qs)
        .rotate(45);
    }

    this._drawLabel(cell1.rel.x + cell1.size.x, cell1.center_rel.y + qs / 2);
  }

  /**
   * Draws a label for the LED
   *
   * @param pos_x
   * @param pos_y
   * @param size label font size
   */
  private _drawLabel(
    pos_x: number,
    pos_y: number,
    size = Plate.LabelFontSizePreferred,
  ) {
    this._group
      .text(this._getLabel())
      .addClass('bb-plate-caption')
      .font({
        size,
      })
      .x(pos_x - size)
      .y(pos_y - size)
      .stroke({ width: 0.5 });
  }

  protected _getLabel() {
    return 'NPN';
  }
}

import type SVG from 'svg.js';
import {
  type ConnItem,
  type UpdateSelectionParams,
} from '~/js/utils/breadboard/components/layers/WireLayer/types';
import { Wire } from '~/js/utils/breadboard/core/Wire/Wire';

/**
 * Represents a wire by connecting two points using the line
 */
export class SimpleWire extends Wire {
  private _path: SVG.Path;
  private _field: SVG.Path;
  private readonly _color_hover: string;

  constructor(container: SVG.Container, id?: number) {
    super(container, id);

    this._color_hover = '#00ff00';
    this._color = '#007503';
  }

  public draw(src: ConnItem): void {
    super.draw(src);

    const line = this._plot();

    this._path = this._container.path(line);
    this._field = this._container.path(line);

    this._field
      .style({ cursor: 'pointer' })
      .stroke({ width: 10, color: 'transparent' });

    this._path.stroke({ width: 3, color: this._color });

    this._field.on('mouseover', () => {
      if (this._is_dragging) {
        return;
      }

      this._path.stroke({
        width: 4,
        color: this._color_hover,
        dasharray: this._editable ? '10 4' : undefined,
      });

      this._callbacks.mouseenter();
      this._callbacks.debugenter(this);
    });

    this._field.on('mouseout', () => {
      if (this._is_dragging) {
        return;
      }

      this._path.stroke({ width: 3, color: this._color, dasharray: 'none' });

      this._callbacks.mouseleave();
      this._callbacks.debugleave(this);
    });

    this._field.on('click', () => {
      if (this._is_dragging) {
        return;
      }

      if (this._editable) {
        this._callbacks.delete?.(this.id);
      }
    });
  }

  public update({ pos_src, pos_dst, is_drag }: UpdateSelectionParams) {
    super.update({ pos_src, pos_dst, is_drag });

    const line = this._plot();

    this._path.plot(line);
    this._field.plot(line);
  }

  public dispose() {
    this._path.node.remove();
    this._field.node.remove();
  }

  private _plot() {
    return [
      'M',
      this.pos.src.x,
      this.pos.src.y,
      'L',
      this.pos.dst.x,
      this.pos.dst.y,
    ];
  }

  public getPath() {
    return this._path.node.getAttribute('d');
  }
}

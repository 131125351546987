import { type PinState } from '~/js/utils/breadboard/core/Layout';

export const generateAnalogPinResetCommands = (
  states: [number, number | PinState][],
) =>
  states.map(([pin_number, state]) => {
    return {
      name: 'arduino_out_read_logical',
      block_id: 'null',
      args: [
        {
          value: `A${pin_number}`,
          type: 'string',
        },
      ],
    };
  });

import {
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from '../../../core/Plate';

export interface AddPlateParams {
  id?: number | null;
  type: string;
  props: PlateProps;
  state?: Partial<SerializedPlateState<PlateState>>;
  animate?: boolean;
  suppressError?: boolean;
  isStatic?: boolean;
}

export interface AddPlateSerializedParams {
  id?: number | null;
  type: string;
  props: PlateProps;
  state?: Partial<SerializedPlateState<PlateState>>;
  animate?: boolean;
  suppressError?: boolean;
  isStatic?: boolean;
}

/**
 * Object describing required conditions for specific type of plate
 * when generating random plate compositions
 *
 * @category Breadboard
 */
export interface PlatePrototype {
  type: string;
  quantity: number;
  props: PlateProps;
}

/**
 * Visual properties of the plate
 *
 * @category Breadboard
 */
export interface PlateStyle {
  quad_size?: number;
  led_size?: number;
  label_font_size?: number;
}

export type SerializedFields = Record<number, PlateState['field']>;

/**
 * Types of user actions notified in plate change events
 *
 * @category Breadboard
 */
export enum PlateChangeActionType {
  /** plate's input/output has been changed */
  State = 'state',
  /** plate has been rotated */
  Rotate = 'rotate',
  /** plate has been moved */
  Move = 'move',
  /** plate has been removed */
  Remove = 'remove',
  Import = 'import',
  Clear = 'clear',
}

/**
 * An object that imitates real mouse events
 * when calling some handlers manually
 *
 * @category Breadboard
 */
export interface PseudoMouseEvent {
  which: number;
  clientX: number;
  clientY: number;
}

import { Plate } from '../../core/Plate';

import { type Cell } from '../../core/Grid';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Buzzer plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class BuzzerPlate extends LinearPlate {
  static get Alias() {
    return PLATE_ALIASES.BUZZER;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();
  }

  /**
   * Draws a buzzer over the plate surface
   *
   * @param qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const ls = this.options.schematic ? 10 : 6;

    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    this._group
      .rect(rect2.x() - rect1.x(), ls)
      .addClass('bb-plate-fill')
      .x(cell1.center_rel.x)
      .cy(cell1.center_rel.y);

    const cx =
      cell1.center_rel.x + (cell2.center_rel.x - cell1.center_rel.x) / 2;
    const cy = cell1.center_rel.y;

    this._group
      .circle(qs * 2)
      .center(cx, cy)
      .addClass('bb-plate-fill');
    this._group
      .circle(qs / 3)
      .center(cx, cy)
      .addClass('bb-plate-fill-inv');
  }
}

import defaultsDeep from 'lodash/defaultsDeep';
import isEqual from 'lodash/isEqual';
import type SVG from 'svg.js';

import { BackgroundLayer } from '../../components/layers/BackgroundLayer';
import { PlateContextMenu } from '../../components/menus';
import { DirsClockwise } from '../../constants';
import { coverObjects, mod } from '../../helpers/common';
import {
  type DeepPartial,
  type DefaultsOf,
  Direction,
  type XYPoint,
} from '../../types';
import { BorderType, Cell, type Grid } from '../Grid';
import {
  LABEL_FONT_SIZE_DEFAULT,
  LED_SIZE_DEFAULT,
  Orientation,
  QUAD_SIZE_DEFAULT,
} from './constants';
import { convertCellsToPosition, convertPositionToCells } from './helpers';
import {
  type PlateAttrs,
  type PlateCallbacks,
  type PlateChangeCallback,
  type PlateMouseCallback,
  type PlateOptions,
  type PlateParams,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
  type SerializedPlateWithId,
} from './types';
import { PlateChangeActionType } from '~/js/utils/breadboard/components/layers/PlateLayer/types';

import './styles.less';

let QUAD_SIZE = QUAD_SIZE_DEFAULT;
let LED_SIZE = LED_SIZE_DEFAULT;
let LABEL_FONT_SIZE = LABEL_FONT_SIZE_DEFAULT;

/**
 * Renders a plate, provides an API to manage its state
 *
 * @category Breadboard
 */
export abstract class Plate<
  Props extends PlateProps = PlateProps,
  State extends PlateState = PlateState,
> {
  /** Plate orientations */
  static get Orientations() {
    return Orientation;
  }

  /** SVG container for the plate */
  static get Class() {
    return 'bb-plate';
  }

  /** Plate type string descriptor, used to instantiate plates from serialized data */
  static get Alias() {
    return 'default';
  }

  static get Layouts(): 'all' | string[] {
    return 'all';
  }

  /** CSS class for shadow iwage */
  static get ShadowImgClass() {
    return 'bb-plate-shadow-img';
  }

  /** Font family for plate captions */
  static get CaptionFontFamily() {
    return "'IBM Plex Mono', 'Lucida Console', Monaco, monospace";
  }

  /** Font weight for plate captions */
  static get CaptionFontWeight() {
    return 'normal';
  }

  /** Default size for square elements used in plate pictograms */
  static get QuadSizeDefault() {
    return QUAD_SIZE_DEFAULT;
  }

  /** Default size for LED elements used in plate pictograms */
  static get LEDSizeDefault() {
    return LED_SIZE_DEFAULT;
  }

  /** Font size for plate captions */
  static get LabelFontSizeDefault() {
    return LABEL_FONT_SIZE_DEFAULT;
  }

  /** Preferred size for square elements used in plate pictograms */
  static get QuadSizePreferred() {
    return QUAD_SIZE;
  }

  /** Preferred size for LED elemets used in plate pictograms */
  static get LEDSizePreferred() {
    return LED_SIZE;
  }

  /** Preferred font size for plate captions */
  static get LabelFontSizePreferred() {
    return LABEL_FONT_SIZE;
  }

  /** Sets preferred size for square elemets used in plate pictograms */
  static set QuadSizePreferred(v) {
    QUAD_SIZE = v;
  }

  /** Sets preferred size for LED elements used in plate pictograms */
  static set LEDSizePreferred(v) {
    LED_SIZE = v;
  }

  /** Sets preferred font size for plate captions */
  static set LabelFontSizePreferred(v) {
    LABEL_FONT_SIZE = v;
  }

  /** static plate instance attributes, evaluates from {@link __defaultProps__}, takes its values from the constructor argument `props` */
  private _props: Props;
  /** dynamic plate instance attributes, changes via {@link setState} */
  private _state: State;
  /** static plate type attributes */
  private _attrs: PlateAttrs;
  /** internal plate options (changed inside the system) */
  private readonly _options: PlateOptions;

  /** local event handlers */
  protected _callbacks: PlateCallbacks<Props>;

  /** extra purpose flag */
  public ___touched?: boolean;
  public ___static?: boolean;

  /** grid on which the plate is placed */
  protected __grid: Grid;

  /** plate identifier */
  private readonly _id: number;
  /** plate type string descriptor */
  private readonly _alias: string;

  /** SVG container in which the plate is rendered */
  protected _container: SVG.Nested;
  /** SVG container in which the plate is rotated */
  protected _group: SVG.G;
  /** plate outline element */
  protected _bezel: SVG.Path | SVG.Rect;
  /** SVG element of the plate shadow, showing the estimated closest location to move in case of drag interruption */
  private _shadowimg: SVG.Rect;
  /** SVG container in which the plate shadow is rendered */
  private readonly _shadow: SVG.Nested;
  /** SVG container in which the plate shadow is rotated */
  private readonly _shadowgroup: SVG.G;
  /** SVG element that highlights the plate */
  private _error_highlighter: any;
  /** parent node of plate container */
  private readonly _node_parent: HTMLElement;

  /** (verbose only) input svg text */
  protected _input_svg_text?: SVG.Text;

  /** a flag that determines if the plate is in the dragging state */
  private _dragging: boolean;
  /** is the plate is drawn or not */
  private _drawn: boolean;
  /** the cell that is currently defined as the closest to drop to if the dragging is interrupted */
  private _cell_supposed: any;
  /** plate placement constraints */
  private _constraints: any;

  private _editable: boolean;

  private _dir_prev: any;

  private readonly _size_px: XYPoint;

  /**
   * Deploys SVG tree for the plate, sets all attributes to its initial state
   */
  constructor({
    container,
    grid,
    id,
    state = {},
    props = {},
    options = {},
  }: PlateParams<Props, State>) {
    if (!container || !grid) {
      throw new TypeError(
        'Both of container and grid arguments should be specified',
      );
    }

    this.__grid = grid;

    this._node_parent = container.node;

    /// Plate type code name
    this._alias = (this as any).constructor.Alias;

    /// Identifier is random number by default
    this._id = id == null ? Math.floor(Math.random() * 10 ** 6) : id;

    /// Structural elements of SVG tree
    this._shadow = container.nested(); // for shadow
    this._container = container.nested(); // for plate rendering and scale transformation
    this._shadowgroup = this._shadow.group(); // for shadow rotations
    this._group = this._container.group(); // for plate rotations

    /// Additional containers
    this._error_highlighter = undefined; // colored overlay of the same shape

    /// Set initial static plate type attributes
    this._options = {
      schematic: options?.schematic || false,
      verbose: options?.verbose || false,
      editable: options?.editable || false,
    };

    this._checkLayoutIntegrity();

    /// Set initial event handlers
    this._callbacks = {
      change: () => {}, // изменения плашки
      mousedown: () => {},
      mousewheel: () => {},
      dragstart: () => {},
      dragfinish: () => {},
    };

    /// Apply its values from the argument
    this.__setProps__(props);
    this.__setAttrs__(this.__defaultAttrs__());
    this.__setState__(this.__defaultState__);
    this.setState(state, true);

    /// Assign a class to the container
    this._container.addClass(Plate.Class);

    /// By default, plate is not dragged
    this._dragging = false;
    /// The plate will be drawn by the `__draw__` method
    this._drawn = false;

    /// Attach mousedown handler calls
    this._group.mousedown((evt: MouseEvent) => {
      this._callbacks.mousedown(evt);
    });

    /// Attach mousewheel handler calls
    if ('onwheel' in document) {
      // IE9+, FF17+, Ch31+
      this._group.node.addEventListener(
        'wheel',
        (evt) => {
          this._callbacks.mousewheel(evt);
        },
        {
          passive: true,
        },
      );
    } else if ('onmousewheel' in document) {
      // устаревший вариант события
      this._group.node.addEventListener('mousewheel', (evt: MouseEvent) => {
        this._callbacks.mousewheel(evt);
      });
    } else {
      // Firefox < 17
      this._group.node.addEventListener(
        'MozMousePixelScroll',
        (evt: MouseEvent) => {
          this._callbacks.mousewheel(evt);
        },
      );
    }
  }

  // Internal getters
  protected abstract get __inheritProps__(): Partial<DefaultsOf<Props>>;
  protected abstract get __inheritState__(): Partial<DefaultsOf<State>>;
  protected abstract __draw__(cell: Cell, orientation: string): void;

  protected get __defaultProps__(): Partial<DefaultsOf<Props>> {
    return {
      inverted: false,
      ...this.__inheritProps__,
    };
  }

  protected get __defaultState__(): State {
    return {
      ...(this.__inheritState__ as State),
      position: {
        cell: this.__grid.getCell(0, 0),
        orientation: Plate.Orientations.West,
        ...this.__inheritState__.position,
      },
    };
  }

  protected get _is_drawn() {
    return this._drawn;
  }

  protected __defaultAttrs__(): PlateAttrs {
    return {
      size: { x: 0, y: 0 },
      origin: { x: 0, y: 0 },
      surface: [{ x: 0, y: 0 }],
      rels: [],
      adjs: {},
    };
  }

  get attrs(): PlateAttrs {
    return this._attrs;
  }

  get options(): PlateOptions {
    return this._options;
  }

  /**
   * @returns plate type alias
   */
  public get alias(): string {
    return (this as any).constructor.Alias;
  }

  /**
   * @deprecated
   * @returns plate subtype
   */
  public get variant(): string {
    return '';
  }

  /**
   * @returns plate identifier
   */
  public get id(): number {
    return this._id;
  }

  /**
   * @deprecated
   * @returns main cell coordinates
   */
  public get pos(): XYPoint {
    return this.state.position.cell.idx;
  }

  /**
   * @deprecated
   * @returns plate length (useful for LED strips and bridges)
   */
  public get length(): number {
    return this._attrs.size.x;
  }

  /**
   * @deprecated
   * @returns current flow adjustments for the cells occupied by the plate
   */
  public get rels() {
    return this._attrs.rels;
  }

  /**
   * @deprecated
   * @returns the location of the cells occupied by the plate
   */
  public get surface() {
    return this._attrs.surface;
  }

  /**
   * @returns static plate instance attributes
   */
  public get props() {
    return this._props;
  }

  /**
   * @returns dynamic plate instance attributes
   */
  public get state() {
    return this._state;
  }

  /**
   * @deprecated
   * @returns current plate input value
   */
  public get input(): any {
    return 0;
    // return this._state.input || 0;
  }

  /**
   * @returns HTML element that contains the plate SVG tree
   */
  public get container(): SVG.Nested {
    return this._container;
  }

  protected get cells() {
    const surfTranslated = this.convertPositionToCells(
      { x: 0, y: 0 },
      this.state.position.orientation,
    );
    return surfTranslated.map((point) => this.__grid.getCell(point.x, point.y));
  }

  /**
   * @returns context menu class
   */
  protected get __ctxmenu__(): typeof PlateContextMenu {
    return PlateContextMenu;
  }

  /**
   * Finds "opposite" cell, in terms of current input and output
   *
   * If {@link cell} is an element input cell, then the method returns an output cell and vice versa.
   * If there are no opposite cell for given cell, then the function returns `undefined`.
   *
   * @param cell an input/output cell
   *
   * @returns an output cell, if an input cell is provided, and vice versa
   */
  protected _getOppositeCell(cell: Cell): Cell | undefined {
    return cell;
  }

  protected __setAttrs__(attrs: PlateAttrs) {
    this._attrs = attrs;
  }

  protected __setState__(state: State) {
    this._state = state;
  }

  /**
   * Sets new props for the plate
   *
   * Props that is not defined by {@link __defaultProps__} will be ignored here.
   *
   * @param props partial props for the plate with values needed to set
   */
  protected __setProps__(props: DeepPartial<Props> | {}) {
    this._props = coverObjects(props, this.__defaultProps__);
  }

  /**
   * Sets plate state
   *
   * @param state             new state of the plate to be updated
   * @param suppress_events   log errors instead of throwing exceptions
   */
  public setState(
    state: Partial<SerializedPlateState<State>>,
    suppress_events = false,
  ) {
    const is_dirty = !isEqual(this.serialize().state, state);

    const posrot =
      state.position?.cells &&
      this.convertCellsToPosition(state.position.cells);

    const newState = {
      ...state,
      ...(posrot && {
        position: {
          cell: this.__grid.getCell(posrot.cell.x, posrot.cell.y),
          orientation: posrot.orientation,
        },
      }),
    };

    this._state = defaultsDeep(newState, this.state);

    if (!suppress_events) {
      if (is_dirty) {
        this._callbacks.change({
          id: this._id,
          action: PlateChangeActionType.State,
        });
      }
    }
  }

  protected sizePx(): XYPoint {
    if (this._size_px) {
      return this._size_px;
    }

    const cell = this.state.position.cell;

    const width =
      cell.size.x * this._attrs.size.x +
      this.__grid.gap.x * 2 * (this._attrs.size.x - 1);

    const height =
      cell.size.y * this._attrs.size.y +
      this.__grid.gap.y * 2 * (this._attrs.size.y - 1);

    return { x: width, y: height };
  }

  /**
   * @returns a context menu instance for the plate
   */
  public getCmInstance() {
    return new this.__ctxmenu__(
      this.id,
      this.alias,
      this.variant,
      this.___static,
      this._editable,
    );
  }

  /**
   * @returns serialized data object defining all the data needed to represent the plate
   */
  public serialize(): SerializedPlateWithId<Props, State> {
    return {
      id: this.id,
      type: this.alias,
      props: this.props,
      state: {
        ...this.state,
        position: {
          cells: this.convertPositionToCells(
            this.state.position.cell.idx,
            this.state.position.orientation,
          ),
        },
      },
      // state: {
      //   ...this.state,
      //   position: {
      //     ...this.state.position,
      //     cell: this.state.position.cell.idx
      //   }
      // },
    };
  }

  public convertCellsToPosition(cells: XYPoint[]) {
    return convertCellsToPosition(cells, this.attrs.surface, this.attrs.origin);
  }

  public convertPositionToCells(
    position: XYPoint,
    orientation: Orientation,
  ): XYPoint[] {
    const surface = this.attrs.surface;
    const origin = this.attrs.origin;

    return convertPositionToCells(surface, origin, position, orientation);
  }

  /**
   * Draws the surface of the plate and its contents in the given position and orientation
   *
   * @param cell        element's position relative to its pivot point
   * @param orientation element's orientation relative to its pivot point
   * @param animate     animate plate appearance
   */
  public draw(cell: Cell, orientation: Orientation, animate: boolean = false) {
    this._checkParams();

    this._beforeReposition();

    const { x: width, y: height } = this.sizePx();

    this._container.size(width, height);
    this._shadow.size(width, height);

    this._shadow.style({ 'pointer-events': 'none' });

    const surf_path = this._generateSurfacePath(BackgroundLayer.CellRadius);

    // TODO: Move surface generation to ComplexPlate and LinearPlate

    if (surf_path) {
      this._bezel = this._group.path(surf_path);
      this._error_highlighter = this._group.path(surf_path);
    } else {
      this._bezel = this._group
        .rect()
        .width('100%')
        .height('100%')
        .radius(BackgroundLayer.CellRadius);
      this._error_highlighter = this._group
        .rect()
        .width('100%')
        .height('100%')
        .radius(BackgroundLayer.CellRadius);
    }

    // Static bezel
    if (this.___static) {
      this._group
        .rect()
        .width('calc(100%)')
        .height('calc(100% - 10px)')
        .x(0)
        .y(5)
        .fill({ opacity: 0 })
        .addClass('bb-plate-bezel-static')
        .stroke({ opacity: 0.5 });
    }

    if (this._options.schematic) {
      this._bezel.fill({ opacity: 0 }).stroke({ opacity: 0 });
    } else {
      this._bezel.addClass('bb-plate-bezel').stroke({ width: 2 });
    }

    this._error_highlighter.fill({ color: '#f00' });

    this._shadowimg = this._shadowgroup.rect(width, height); // изображение тени
    this._shadowimg.fill({ color: '#51ff1e' }).radius(10).opacity(0.4);
    this._shadowimg.addClass(Plate.ShadowImgClass);
    this._hideShadow();

    this._input_svg_text = this._container
      .text('')
      .addClass('bb-plate-caption-debug')
      .font({ anchor: 'end' })
      .x(this._container.width())
      .hide();

    this.highlightError(false);
    this.move(cell, true);
    this.rotate(orientation, true, false);
    this._preventOverflow(true);
    this.__draw__(cell, orientation);

    if (this._options.verbose) {
      if (this.___static) {
        this._container.text(`${this.id}`).addClass('bb-plate-caption-debug');
      }

      this._input_svg_text.show();
    }

    this._drawn = true;

    if (animate) {
      this._bezel.scale(1.15).animate(100).scale(1);
    }

    this._afterReposition();
  }

  protected _redrawInput(input_value: any) {
    this._input_svg_text?.text(
      input_value === undefined ? 'n/a' : String(input_value),
    );

    this._input_svg_text?.style({
      fill: input_value === undefined ? '#F00' : '#006200',
    });
  }

  /**
   * Toggles error highlight
   *
   * @param on turn on the highlight
   */
  public highlightError(on = false) {
    if (on) {
      this._error_highlighter?.opacity(0.3);
    } else {
      this._error_highlighter?.opacity(0);
    }
  }

  /**
   * Triggers plate click event
   */
  public click() {
    this._container.fire('mousedown');
    this.rearrange();
  }

  /**
   * Moves the plate to a new cell
   *
   * @param cell            position of the plate relative to its pivot point
   * @param suppress_events log errors instead of throwing exceptions
   * @param animate         animate the movement
   */
  public move(
    cell: Cell,
    suppress_events: boolean = false,
    animate: boolean = false,
  ) {
    if (cell.grid !== this.__grid) {
      throw new Error("Cell's grid and plate's grid are not the same");
    }

    if (!suppress_events) {
      this._beforeReposition();
    }

    this._state.position.cell = cell;

    const pos = this._getPositionAdjusted(cell);

    this._shadow.move(pos.x, pos.y);

    if (animate) {
      this._container.animate(100, '<>').move(pos.x, pos.y);
    } else {
      this._container.move(pos.x, pos.y);
    }

    if (!suppress_events) {
      this._afterReposition();

      this._callbacks.change({
        id: this._id,
        action: PlateChangeActionType.Move,
      });
    }
  }

  /**
   * Moves the plate (dx, dy) steps
   *
   * @param dx                  the number of steps to move horizontally
   * @param dy                  the number of steps to move vertically
   * @param prevent_overflow    prevent movements outside the grid
   */
  public shift(dx: number, dy: number, prevent_overflow: boolean = true) {
    this.move(
      this.__grid.getCell(
        this.state.position.cell.idx.x + dx,
        this.state.position.cell.idx.y + dy,
        BorderType.Replicate,
      ),
    );

    if (prevent_overflow) {
      this._preventOverflow();
    }
  }

  /**
   * Rotates the plate
   *
   * @param orientation         orientation of the plate relative to pivot point
   * @param suppress_events     do not make preprocessing and postprocessing calls
   * @param prevent_overflow    prevent movements outside the grid
   */
  public rotate(
    orientation: Orientation,
    suppress_events: boolean = false,
    prevent_overflow: boolean = true,
  ) {
    if (this._dragging) {
      return;
    }

    // if (orientation === this.state.position.orientation) {
    //   return;
    // }

    if (orientation === Plate.Orientations.Dummy) {
      console.debug('invalid orientation: dummy');
      return;
    }

    if (!suppress_events) {
      this._beforeReposition();
    }

    const angle = Plate._orientationToAngle(orientation);

    const cell = this.state.position.cell;

    const anchor_point = {
      x:
        this._attrs.origin.x * (this.__grid.gap.x * 2 + cell.size.x) +
        cell.size.x / 2,
      y:
        this._attrs.origin.y * (this.__grid.gap.y * 2 + cell.size.y) +
        cell.size.y / 2,
    };

    this._group.transform({
      rotation: angle,
      cx: anchor_point.x,
      cy: anchor_point.y,
    });
    this._shadowgroup.transform({
      rotation: angle,
      cx: anchor_point.x,
      cy: anchor_point.y,
    });

    this._state.position.orientation = orientation;

    if (!suppress_events) {
      this._afterReposition();

      this._callbacks.change({
        id: this._id,
        action: PlateChangeActionType.Rotate,
      });
    }

    if (prevent_overflow) {
      this._preventOverflow();
    }
  }

  /**
   * Rotates the plate clockwise
   */
  public rotateClockwise() {
    let orientation;

    switch (this.state.position.orientation) {
      case Plate.Orientations.West: {
        orientation = Plate.Orientations.South;
        break;
      }
      case Plate.Orientations.South: {
        orientation = Plate.Orientations.East;
        break;
      }
      case Plate.Orientations.East: {
        orientation = Plate.Orientations.North;
        break;
      }
      case Plate.Orientations.North: {
        orientation = Plate.Orientations.West;
        break;
      }

      default: {
        throw new TypeError('Current orientation is invalid');
      }
    }

    this.rotate(orientation);
  }

  /**
   * Rotates the plate counterclockwise
   */
  public rotateCounterClockwise() {
    let orientation;

    switch (this.state.position.orientation) {
      case Plate.Orientations.West: {
        orientation = Plate.Orientations.North;
        break;
      }
      case Plate.Orientations.South: {
        orientation = Plate.Orientations.West;
        break;
      }
      case Plate.Orientations.East: {
        orientation = Plate.Orientations.South;
        break;
      }
      case Plate.Orientations.North: {
        orientation = Plate.Orientations.East;
        break;
      }
      default: {
        throw new TypeError('Current orientation is invalid');
      }
    }

    this.rotate(orientation);
  }

  /**
   * Increases input value
   */
  public inputIncrement() {
    // this.setState({ input: Number(this.input) + 1 });
  }

  /**
   * Decreases input value
   */
  public inputDecrement() {
    // this.setState({ input: Number(this.input) - 1 });
  }

  /**
   * Highlights plate's outline
   */
  public select() {
    this.rearrange();

    if (this.options.schematic) {
      (this._bezel.animate(100) as any).stroke({
        opacity: 1,
        color: '#0900fa',
        width: 2,
      });
    } else {
      (this._bezel.animate(100) as any).stroke({
        color: '#0900fa',
        width: 2,
      });
    }

    this.highlightError(false);
  }

  /**
   * Removes the highlight from the plate's outline
   */
  public deselect() {
    if (this.options.schematic) {
      (this._bezel.animate(100) as any).stroke({
        opacity: 0,
        color: '#f0eddb',
        width: 2,
      });
    } else {
      (this._bezel.animate(100) as any).stroke({
        color: '#f0eddb',
        width: 2,
      });
    }
  }

  /**
   * Removes plate's SVG tree
   */
  public dispose() {
    this._beforeReposition();

    // this._bezel.scale(1).animate('100ms').scale(0.85).opacity(0);

    // setTimeout(() => {
    this._container.node.remove();
    this._shadow.node.remove();
    // }, 100);

    this._afterReposition();
  }

  /**
   * Handles key press
   *
   * @param key_code  code of the key pressed
   * @param keydown   is the button pressed or released
   */
  public handleKeyPress(key_code: any, keydown: boolean) {}

  /**
   * Attaches user plate change event handler
   *
   * @param cb plate change event handler
   */
  public onChange(cb?: PlateChangeCallback) {
    this._callbacks.change = cb || (() => undefined);
  }

  /**
   * Attaches plate drag start event handler
   *
   * @param cb plate drag start event handler
   */
  public onDragStart(cb?: VoidFunction) {
    this._callbacks.dragstart = cb || (() => undefined);
  }

  /**
   * Attaches plate drag end event handler
   *
   * @param cb plate drag end event handler
   */
  public onDragFinish(cb?: VoidFunction) {
    this._callbacks.dragfinish = cb || (() => undefined);
  }

  /**
   * Attaches plate click event handler
   *
   * @param cb plate click event handler
   */
  public onMouseDown(cb?: PlateMouseCallback) {
    this._callbacks.mousedown = cb || (() => undefined);
  }

  /**
   * Attaches plate scroll event handler
   *
   * @param cb plate scroll event handler
   */
  public onMouseWheel(cb?: PlateMouseCallback) {
    this._callbacks.mousewheel = cb || (() => undefined);
  }

  /**
   * Makes the plate translucent and unresponsive
   *
   * Plate does not trigger mouse events and becomes translucent.
   * This is necessary to prevent interaction conflicts while dragging.
   */
  public freeze() {
    this._container.style('pointer-events', 'none');
    this._container.animate(100).attr({ opacity: 0.5 });
  }

  /**
   * Cancels freezing
   *
   * Plate triggers mouse events and becomes opaque.
   *
   * {@see Plate.freeze}
   */
  public unfreeze() {
    this._container.style('pointer-events', 'inherit');
    this._container.animate(100).attr({ opacity: 1 });
  }

  /**
   * Toggles plate selected state
   *
   * @param selected make plate selected
   */
  public setSelectedVisual(selected = true) {
    if (selected) {
      this._container.style({ cursor: 'move' });
    } else if (!this.___static) {
      this._container.style({ cursor: 'default' });
    }
  }

  public setEditable(editable = true) {
    this._editable = editable;
  }

  /**
   * Moves the pivot point of the plate to the nearest accessible cell
   *
   * The calculation of the nearest cell is based on the {@link calcSupposedCell} method.
   */
  public snap() {
    if (!this._cell_supposed) {
      this._cell_supposed = this.calcSupposedCell();
    }

    this.move(this._cell_supposed, false, true);

    this._hideShadow();

    this._dragging = false;
    this._cell_supposed = undefined;
    this._callbacks.dragfinish();
  }

  /**
   * Moves the plate to the arbitrary point in the document
   *
   * @param x         x position of the point
   * @param y         y position of the point
   * @param animate   whether to animate the movement
   */
  public move_to_point(x: number, y: number, animate: boolean = false) {
    if (animate) {
      this._container.animate(100, '<>').move(x, y);
    } else {
      this._container.move(x, y);
    }
  }

  /**
   * Moves the plate's center to the arbitrary point in the document
   *
   * @param x         x position of the point
   * @param y         y position of the movement
   * @param animate   whether to animate the movement
   */
  public center_to_point(x: number, y: number, animate: boolean = false) {
    if (animate) {
      this._container.animate(100, '<>').center(x, y);
    } else {
      this._container.center(x, y);
    }
  }

  /**
   * Moves the plate by the specified number of units
   *
   * @param dx number of units to move horizontally
   * @param dy number of units to move vertically
   */
  public dmove(dx: number, dy: number) {
    this._container.dmove(dx, dy);

    requestAnimationFrame(() => {
      this._cell_supposed = this.calcSupposedCell();
      this._dropShadowToCell(this._cell_supposed);
    });

    if (!this._dragging) {
      this._showShadow();
      this._callbacks.dragstart();
      this._dragging = true;
    }
  }

  /**
   * Tests parameters values of the plate type
   */
  private _checkParams() {
    if (
      this.attrs.origin.x >= this._attrs.size.x ||
      this.attrs.origin.y >= this._attrs.size.y
    ) {
      this._attrs.origin = { x: 0, y: 0 };
      console.debug(`Invalid origin for plate type '${this._alias}'`);
    }
  }

  /**
   * Moves the shadow to supposed nearest cell
   */
  private _dropShadowToCell(cell: Cell) {
    const pos = this._getPositionAdjusted(cell);

    this._shadow.x(pos.x);
    this._shadow.y(pos.y);
  }

  /**
   * Shows the plate's shadow
   */
  private _showShadow() {
    this._shadow.opacity(1);
  }

  /**
   * Hides the plate's shadow
   */
  private _hideShadow() {
    this._shadow.opacity(0);
  }

  /**
   * Calculates the estimated nearest cell
   *
   * This method is used to position the plates precisely
   * by snapping them to the grid cells when dragging finishes.
   */
  public calcSupposedCell() {
    /// Положениие группы плашки (изм. при вращении)
    const gx = this._group.x();
    const gy = this._group.y();

    /// Положение контейнера плашки (изм. при перемещении)
    const cx = this._container.x();
    const cy = this._container.y();

    const { x: width, y: height } = this.sizePx();

    /// Координаты верхнего левого угла контейнера
    let x = 0;
    let y = 0;

    // Учесть влияние вращения на систему координат
    switch (this.state.position.orientation) {
      case Plate.Orientations.West: {
        x = cx;
        y = cy;
        break;
      }
      case Plate.Orientations.North: {
        x = cx + gx - height;
        y = cy + gy;
        break;
      }
      case Plate.Orientations.East: {
        x = cx + gx - width;
        y = cy + gy - height;
        break;
      }
      case Plate.Orientations.South: {
        x = cx + gx;
        y = cy + gy - width;
        break;
      }
    }

    /// Клетка, над которой находится верхняя левая ячейка плашки
    const cell = this.__grid.getCellByPos(x, y, BorderType.Replicate);
    /// Клетка, над которой находится опорная ячейка плашки
    const cell_orig = this._getCellOriginal(cell);

    /// Ограничения для левой верхней ячейки плашки
    const [Ox, Oy, Nx, Ny] = this._getPlacementConstraints(
      this._state.position.orientation,
    );

    /// Индекс ячейки, находящейся под опорной ячейкой плашки
    let ix = cell_orig.idx.x;
    let iy = cell_orig.idx.y;

    /// Точное положение опорной точки плашки в системе координат
    const px = x - cell.pos.x + cell_orig.pos.x;
    const py = y - cell.pos.y + cell_orig.pos.y;

    /// Проверка на выход за границы сетки ячеек
    if (ix <= Ox) {
      ix = Ox;
    }
    if (iy <= Oy) {
      iy = Oy;
    }

    if (ix >= Nx) {
      ix = Nx;
    }
    if (iy >= Ny) {
      iy = Ny;
    }

    /// Массив соседей ячейки, над которой находится плашка
    const neighbors = [];

    /// Соседи по краям
    if (ix + 1 <= Nx) {
      neighbors.push(this.__grid.getCell(ix + 1, iy));
    }
    if (ix - 1 >= Ox) {
      neighbors.push(this.__grid.getCell(ix - 1, iy));
    }
    if (iy + 1 <= Ny) {
      neighbors.push(this.__grid.getCell(ix, iy + 1));
    }
    if (iy - 1 >= Oy) {
      neighbors.push(this.__grid.getCell(ix, iy - 1));
    }

    /// Соседи по диагоналям
    if (ix + 1 <= Nx && iy + 1 <= Ny) {
      neighbors.push(this.__grid.getCell(ix + 1, iy + 1));
    }
    if (ix + 1 <= Nx && iy - 1 >= Oy) {
      neighbors.push(this.__grid.getCell(ix + 1, iy - 1));
    }
    if (ix - 1 >= Ox && iy + 1 <= Ny) {
      neighbors.push(this.__grid.getCell(ix - 1, iy + 1));
    }
    if (ix - 1 >= Ox && iy - 1 >= Oy) {
      neighbors.push(this.__grid.getCell(ix - 1, iy - 1));
    }

    /// Ближайший сосед
    let nearest = this.__grid.getCell(ix, iy);

    // Расстояния от точки до ближайшего соседа
    let ndx = Math.abs(px - nearest.pos.x);
    let ndy = Math.abs(py - nearest.pos.y);

    for (const neighbor of neighbors) {
      /// Расстояния от точки до соседа
      const dx = Math.abs(px - neighbor.pos.x);
      const dy = Math.abs(py - neighbor.pos.y);

      if (dx < ndx || dy < ndy) {
        // если хотя бы по одному измерению расстояние меньше,
        // взять нового ближайшего соседа
        nearest = neighbor;
        ndx = Math.abs(px - nearest.pos.x);
        ndy = Math.abs(py - nearest.pos.y);
      }
    }

    return nearest;
  }

  /**
   * Defines placement constraints of the plate in the specific orientation
   *
   * This method caches the result for any orientation so it's tolerant to repetitive calls
   *
   * @param orientation possible orientation of the plate
   *
   * @returns plate's placement constraints in the specific orientation
   */
  private _getPlacementConstraints(orientation: string) {
    if (!this._constraints) {
      this._constraints = this._calcPlacementConstraints();
    }

    return this._constraints[orientation];
  }

  /**
   * Evaluates placement constraints for all orientations possible for the plate
   *
   * Placement constraint is the maximum possible coordinates of the pivot plate's cell position in the document.
   * This determines whether the plate can be placed on the board in the specific orientation.
   *
   * @returns plate placement constraints for each orientation
   */
  private _calcPlacementConstraints(): Record<
    string,
    [number, number, number, number]
  > {
    /// Размерность доски
    const Dx = this.__grid.dim.x;
    const Dy = this.__grid.dim.y;

    /// Размерность плашки
    const Sx = this._attrs.size.x;
    const Sy = this._attrs.size.y;

    /// Опорная точка плашки
    const orn = this._attrs.origin;

    /// Количество точек от опорной до края
    const rem = { x: Sx - orn.x, y: Sy - orn.y };

    const constraints: Record<string, [number, number, number, number]> = {};

    // x goes to Nx, y goes to Ny
    constraints[Plate.Orientations.West] = [
      orn.x,
      orn.y,
      Dx - rem.x,
      Dy - rem.y,
    ];
    // -y goes to Nx, x goes to Ny
    constraints[Plate.Orientations.South] = [
      rem.y - 1,
      orn.x,
      Dx - orn.y - 1,
      Dy - rem.x,
    ];
    // -x goes to Nx, -y goes to Ny
    constraints[Plate.Orientations.East] = [
      rem.x - 1,
      rem.y - 1,
      Dx - orn.x - 1,
      Dy - orn.y - 1,
    ];
    // y goes to Nx, -x goes to Ny
    constraints[Plate.Orientations.North] = [
      orn.y,
      rem.x - 1,
      Dx - rem.y,
      Dy - orn.x - 1,
    ];

    return constraints;
  }

  /**
   * Evaluates the cell above which the plate's pivot point is located
   *
   * @param cell the cell above which upper left point of the plate is located
   *
   * @returns the cell above which the pivot point of the plate is located
   */
  private _getCellOriginal(cell: Cell): Cell {
    const ix = cell.idx.x;
    const iy = cell.idx.y;

    const orn = this._attrs.origin;

    /// Количество ячеек, занимаемое плашкой
    const sx = this._attrs.size.x;
    const sy = this._attrs.size.y;

    let dix = 0;
    let diy = 0;

    switch (this.state.position.orientation) {
      case Plate.Orientations.West: {
        dix = orn.x;
        diy = orn.y;
        break;
      }
      case Plate.Orientations.North: {
        dix = sy - orn.y - 1;
        diy = orn.x;
        break;
      }
      case Plate.Orientations.East: {
        dix = sx - orn.x - 1;
        diy = sy - orn.y - 1;
        break;
      }
      case Plate.Orientations.South: {
        dix = orn.y;
        diy = sx - orn.x - 1;
        break;
      }
    }

    return this.__grid.getCell(ix + dix, iy + diy, BorderType.Replicate);
  }

  /**
   * Moves the plate to nearest proper position if it's going out of the grid
   *
   * The method should be called every time the plate is moved or rotated (both automatically and manually)
   *
   * @param throw_error throw an error instead of moving the plate to proper position
   *
   * @private
   */
  private _preventOverflow(throw_error = false) {
    /// Номер ячейки, занимаемой опорной ячейкой плашки
    let ix = this._state.position.cell.idx.x;
    let iy = this._state.position.cell.idx.y;

    const [Ox, Oy, Nx, Ny] = this._getPlacementConstraints(
      this._state.position.orientation,
    );

    let dx = 0;
    let dy = 0;

    if (ix >= Nx) {
      dx = Nx - ix;
    }
    if (iy >= Ny) {
      dy = Ny - iy;
    }

    if (ix <= Ox) {
      dx = Ox - ix;
    }
    if (iy <= Oy) {
      dy = Oy - iy;
    }

    ix += dx;
    iy += dy;

    if ((dx !== 0 || dy !== 0) && throw_error) {
      throw new RangeError(
        `Invalid plate position: an overflow occurred at [${ix}, ${iy}] for plate #${this.id}`,
      );
    }

    // анимировать, но только не в случае незавершённой отрисовки
    this.move(this.__grid.getCell(ix, iy), false, this._drawn);
  }

  /**
   * Moves the plate's DOM node above others inside the parent container
   *
   * Used in cases when the plate needs to be displayed on top of the rest
   * (e.g. while dragging over another plates to keep pointer events active)
   */
  public rearrange() {
    const node_temp = this._container.node;
    this._container.node.remove();
    this._node_parent.appendChild(node_temp);
  }

  /**
   * Performs the actions required before positioning (moving/drawing) the plate
   *
   * Every time the plate is moving, it's needed to free the cells occupied by it
   * previously to re-calculate current lines possibly going through the plate.
   */
  private _beforeReposition() {
    // Освободить все ячейки, занимаемые плашкой
    this._reoccupyCells(true);
  }

  /**
   * Performs the actions required after positioning (moving/drawing) the plate
   *
   * Every time the plate is moving, it;s needed to occupy the cells which
   * will be covered by it to re-calculate current lines possibly going through the plate.
   */
  private _afterReposition() {
    // Занять все ячейки, занимаемые плашкой
    this._reoccupyCells();
  }

  /**
   * Toggles the occupation of the cells covered by the plate at the current position.
   *
   * Has no effect if schematic mode is disabled
   *
   * @param clear whether to free the cells instead of occupation
   */
  private _reoccupyCells(clear: boolean = false) {
    if (!this.options.schematic) {
      return;
    }

    if (!this.attrs.rels) {
      return;
    }

    const abs = this.state.position.cell.idx;

    for (const _rel of this.attrs.rels) {
      // ориентировать относительную ячейку плашки
      const rel = Plate._orientXYObject(_rel, this.state.position.orientation);
      // определить корректировку для отрисовки тока
      const adj_cur = clear
        ? undefined
        : Plate._orientXYObject(_rel.adj, this._state.position.orientation);
      // определить корректировку положения всей плашки
      let adj_pos = this._attrs.adjs
        ? this._attrs.adjs[this._state.position.orientation]
        : undefined;

      // учесть, что корректировка положения всей плашки может отсутствовать
      if (adj_pos) {
        adj_pos = {
          x: adj_pos.x ? adj_pos.x : 0,
          y: adj_pos.y ? adj_pos.y : 0,
        };
      } else {
        adj_pos = { x: 0, y: 0 };
      }

      // сообщить ячейке полученную корректировку
      try {
        const cell = this.__grid.getCell(
          abs.x + rel.x + adj_pos.x,
          abs.y + rel.y + adj_pos.y,
        );

        const opposite = clear ? undefined : this._getOppositeCell(cell);

        cell.reoccupy(adj_cur, opposite);
      } catch (e) {
        console.trace(this.state, { abs, rel });

        console.debug(
          'Tried to get a non-existent cell (in purpose to reoccupy)',
          abs.x + rel.x,
          abs.y + rel.y,
        );
      }
    }
  }

  /**
   * Calculates adjusted coordinates for the plate
   *
   * Does not adjust if schematic mode is enabled
   *
   * @param cell custom cell to use instead of the current position of the plate pivot point
   *
   * @returns adjusted plate coordinates
   */
  private _getPositionAdjusted(cell?: Cell): XYPoint {
    cell = cell || this.state.position.cell;

    /// Опорная точка плашки
    const orn = this.attrs.origin;

    /// Абсолютное положение плашки с учётом того, что ячейка лежит над опорной точкой плашки
    const abs = {
      x: cell.pos.x - orn.x * (cell.size.x + this.__grid.gap.x * 2),
      y: cell.pos.y - orn.y * (cell.size.y + this.__grid.gap.y * 2),
    };

    if (!this.options.schematic) {
      return abs;
    }

    if (!this._attrs.adjs?.[this.state.position.orientation]) {
      return abs;
    }

    const adj = this._attrs.adjs[this.state.position.orientation];

    return {
      x: abs.x + adj.x * cell.size.x,
      y: abs.y + adj.y * cell.size.y,
    };
  }

  /**
   * Generates SVG path for the plate surface
   *
   * Surface is a canvas shaped by merging adjacent cell-sized rounded rectangles.
   *
   * Note that surfaces with holes is not supported, the result may be unsatisfactory.
   *
   * @param radius corner radius
   *
   * @returns SVG path commands to build the shape of the surface
   */
  private _generateSurfacePath(radius = 5): (string | number)[][] | undefined {
    if (this.attrs.surface) {
      const path: Array<Array<string | number>> = [];

      // TODO: Verify closed surfaces

      // If the surface exists, convert it from original format to a [x][y]-indexed array
      const surfcnt = this._convertSurfaceToArray(this.attrs.surface);

      if (!surfcnt) {
        return;
      }

      const surf_point = this.attrs.surface[0];
      const cell = this.__grid.getCell(surf_point.x, surf_point.y);

      return path.concat(this._buildSurfacePath(cell, surfcnt, radius));
    }
  }

  /**
   * Generates SVG path for the plate surface
   *
   * The method is recursive.
   *
   * Path building consists in adding different segments while traversing
   * all the cells of the plate clockwise, starting from the left upper corner.
   *
   * There are two types of segments used to build the path:
   *  - corner + gap push/pull
   *  - corner + pure edge
   *
   * Pure edges are generated in case there are no neighbor cells on the side.
   * Gap pushes are generated when two adjacent cells appears, and the distance between the corners
   * can be different from the size of the cell in the given {@link Grid}.
   *
   * When the gap push is generated, the procedure recursively repeats.
   * After the generation, resulting path is closed by the opposite gap pull.
   * Each gap push should have its opposite gap pull because the surface is closed.
   * Thus, the procedure is ended when the recursion depth becomes zero.
   *
   * ```
   *          <corner>             <gap push>            <corner>
   *                ********|>>>>>>>>>>>>>>>>>>>>>>>|********
   *               **                                       **
   *              *                                           *
   *              -     /-------\               /-------\     —
   *              v     |       |               |       |     v
   *      <edge>  v     |   X   | <-- gap*2 --> |   X   |     v <edge>
   *              v     |       |               |       |     v
   *              -     \-------/               \-------/     -
   *              *                                           *
   *               **                                       **
   *                ********|<<<<<<<<<<<<<<<<<<<<<<<|********
   *         <corner>              <gap pull>            <corner>
   * ```
   *
   * path = offset + part + closure
   * part = edge / (gap push + part + gap pull)
   *
   * @see _buildSurfacePathOffset
   * @see _buildSurfacePathGapPush
   * @see _buildSurfacePathGapPull
   * @see _buildSurfacePathEdge
   * @see _buildSurfacePathClosure
   *
   * @param cell      starting point of the surface
   * @param surfcnt   [x][y]-indexed array of surface points
   * @param radius    corner radius
   * @param dir_idx   direction index
   * @param is_root   whether the method is called from the outside
   *
   * @returns SVG path commands to build the shape of the surface
   */
  private _buildSurfacePath(
    cell: Cell,
    surfcnt: number[][],
    radius: number,
    dir_idx = 0,
    is_root = true,
  ): Array<Array<string | number>> {
    let path: Array<Array<string | number>> = [];

    // clockwise dir sequence
    const dirs = DirsClockwise;

    if (is_root) {
      path = path.concat(this._buildSurfacePathOffset(cell, radius));
    }

    // main drawing procedure
    while (surfcnt[cell.idx.x][cell.idx.y] < dirs.length) {
      dir_idx = mod(dir_idx, dirs.length);

      const dir = dirs[dir_idx % dirs.length];

      // get neighbor cell for current direction
      const nb = cell.neighbor(dir);

      if (
        nb &&
        surfcnt[nb.idx.x] &&
        surfcnt[nb.idx.x].hasOwnProperty(nb.idx.y)
      ) {
        surfcnt[cell.idx.x][cell.idx.y] += 1;

        // skip to suppress redundant deepening
        if (surfcnt[nb.idx.x][nb.idx.y] <= 0) {
          const dir_idx_prev = mod(dir_idx - 1, dirs.length);
          const dir_prev = dirs[dir_idx_prev % dirs.length];

          const dir_idx_next = mod(dir_idx + 1, dirs.length);
          const dir_next = dirs[dir_idx_next % dirs.length];

          // push gap
          path = path.concat(
            this._buildSurfacePathGapPush(dir, dir_prev, radius),
          );
          // if neighbor exists for this direction, draw from it
          path = path.concat(
            this._buildSurfacePath(nb, surfcnt, radius, dir_idx - 1, false),
          );
          // pull gap
          path = path.concat(
            this._buildSurfacePathGapPull(dir, dir_next, radius),
          );
        }
      } else {
        // otherwise we can draw the edge of this direction
        surfcnt[cell.idx.x][cell.idx.y] += 1;

        path = path.concat(this._buildSurfacePathEdge(cell, dir, radius));
      }

      dir_idx++;
    }

    if (is_root) {
      const closure = this._buildSurfacePathClosure(dirs[0], radius);

      if (closure) {
        path.push(closure);
      }
    }

    return path;
  }

  /**
   * Generates a "gap push" for the path
   *
   * Gap push continues the path by the double width/length of the cell's gap.
   * If the path is "pushed" by the gap, then it's needed to pull it on the opposite side.
   *
   * Gap pushes/pulls take place when two adjacent cells in a plate surface's side is presented.
   * The base path continues from the point of previous edge with the corner and another gap push
   *
   * @see _buildSurfacePath
   * @see _buildSurfacePathGapPull
   *
   * @param dir           direction of the path to which the gap is appended
   * @param dir_corner    direction after the corner preceding the gap
   * @param radius        radius of the corner to leave some place to add an arc
   *
   * @returns SVG path commands to build the corner and subsequent gap push
   */
  private _buildSurfacePathGapPush(
    dir: Direction,
    dir_corner: Direction,
    radius: number,
  ): [(string | number)[], (string | number)[]] {
    let corner = this._buildSurfacePathCorner(dir_corner, radius);

    if (corner === null) {
      radius = 0;
      corner = [];
    }

    switch (dir) {
      case Direction.Up: {
        return [corner, ['v', -(this.__grid.gap.y * 2 - radius * 2)]]; // draw up
      }
      case Direction.Right: {
        return [corner, ['h', +(this.__grid.gap.x * 2 - radius * 2)]]; // draw right
      }
      case Direction.Down: {
        return [corner, ['v', +(this.__grid.gap.y * 2 - radius * 2)]]; // draw down
      }
      case Direction.Left: {
        return [corner, ['h', -(this.__grid.gap.x * 2 - radius * 2)]]; // draw left
      }
      default: {
        throw new RangeError('Invalid direction');
      }
    }
  }

  /**
   * Generates a "gap pull" for the surface path
   *
   * Gap pull "reverts" the gap push generated previously at the opposite size of the surface path.
   *
   * @see _buildSurfacePath
   * @see _buildSurfacePathGapPush
   *
   * @param dir           direction of the path to which the gap is appended
   * @param dir_corner    direction after the corner preceding the gap
   * @param radius        radius of the corner to leave some place to add an arc
   *
   * @returns SVG path commands to build the corner and subsequent build gap pull
   */
  private _buildSurfacePathGapPull(
    dir: Direction,
    dir_corner: Direction,
    radius: number,
  ) {
    let corner = this._buildSurfacePathCorner(dir_corner, radius);

    if (corner === null) {
      radius = 0;
      corner = [];
    }

    switch (dir) {
      case Direction.Up: {
        return [corner, ['v', +(this.__grid.gap.y * 2 - radius * 2)]]; // draw down
      }
      case Direction.Right: {
        return [corner, ['h', -(this.__grid.gap.x * 2 - radius * 2)]]; // draw left
      }
      case Direction.Down: {
        return [corner, ['v', -(this.__grid.gap.y * 2 - radius * 2)]]; // draw up
      }
      case Direction.Left: {
        return [corner, ['h', +(this.__grid.gap.x * 2 - radius * 2)]]; // draw right
      }
      default: {
        throw new RangeError('Invalid direction');
      }
    }
  }

  /**
   * Generates an edge part for the path
   *
   * Edge continues the path by the length of the cell.
   *
   * Edges takes place when there is only single neighbor cell on the side.
   * When the side is wider/longer than 1 cell, {@link _buildSurfacePathGapPush} should be used.
   *
   * @see _buildSurfacePath
   *
   * @param cell      cell opposite to which to build
   * @param dir       direction after the corner preceding the edge
   * @param radius    radius of the corner to leave some place to add an arc
   *
   * @returns SVG path commands te build the corner and subsequent edge
   */
  private _buildSurfacePathEdge(
    cell: Cell,
    dir: Direction,
    radius: number,
  ): [Array<string | number>, Array<string | number>] {
    let corner = this._buildSurfacePathCorner(dir, radius);

    if (corner === null) {
      radius = 0;
      corner = [];
    }

    switch (dir) {
      case Direction.Up: {
        return [corner, ['h', +(cell.size.x - radius * 2)]]; // draw right
      }
      case Direction.Right: {
        return [corner, ['v', +(cell.size.y - radius * 2)]]; // draw down
      }
      case Direction.Down: {
        return [corner, ['h', -(cell.size.x - radius * 2)]]; // draw left
      }
      case Direction.Left: {
        return [corner, ['v', -(cell.size.y - radius * 2)]]; // draw up
      }
      default: {
        throw new RangeError('Invalid direction');
      }
    }
  }

  /**
   * Generates movement command to set initial position of the path
   *
   * @param cell      initial cell of the path
   * @param radius    corners radius to leave some place to add an arc
   *
   * @returns SVG path command to move initial point of drawing
   */
  private _buildSurfacePathOffset(cell: Cell, radius: number) {
    const mv_x = cell.idx.x * (cell.size.x + this.__grid.gap.x * 2);
    const mv_y = cell.idx.y * (cell.size.y + this.__grid.gap.y * 2);

    return [['M', mv_x + radius, mv_y]];
  }

  /**
   * Generates finishing part of the path
   *
   * @param dir_curr  current direction of the path
   * @param radius    radius of the corner
   *
   * @returns SVG path command to build finishing rounded corner
   */
  private _buildSurfacePathClosure(dir_curr: Direction, radius: number) {
    if (this._dir_prev == null) {
      return [];
    }

    const closure = this._buildSurfacePathCorner(dir_curr, radius);

    this._dir_prev = null;

    return closure;
  }

  /**
   * Makes a corner part for the SVG path of the plate surface
   *
   * Is is supposed that the base path leaves some place
   * for the arc generated by the path returned from this function.
   *
   * @param dir_curr  current direction of the path "movement" to track the "rotation" of the path
   * @param radius    corner radius
   *
   * @returns corner part to append to the base path
   */
  private _buildSurfacePathCorner(
    dir_curr: Direction,
    radius: number,
  ): (string | number)[] | null {
    const dir_prev = this._dir_prev;

    if (dir_curr === dir_prev) {
      return null;
    }

    let rx = null;
    let ry = null;

    let arc = null;

    if (Cell.IsDirHorizontal(dir_prev) && Cell.IsDirVertical(dir_curr)) {
      rx = dir_prev === Direction.Up ? radius : -radius;
      ry = dir_curr === Direction.Right ? radius : -radius;
    }

    if (Cell.IsDirHorizontal(dir_curr) && Cell.IsDirVertical(dir_prev)) {
      rx = dir_curr === Direction.Up ? radius : -radius;
      ry = dir_prev === Direction.Right ? radius : -radius;
    }

    if (rx !== null && ry !== null) {
      const cw = Cell.IsDirsClockwise(dir_prev, dir_curr) ? 1 : 0;
      arc = ['a', radius, radius, 0, 0, cw, rx, ry];
    }

    const is_first = this._dir_prev == null;

    this._dir_prev = dir_curr;

    return is_first ? [] : arc;
  }

  /**
   * Converts the surface to a sequence of [x][y]-indexed array of zeros
   *
   * @param surface original surface of the plate
   */
  private _convertSurfaceToArray(
    surface: { x: number; y: number }[],
  ): number[][] | undefined {
    const arr: number[][] = [];

    for (const item of surface) {
      if (!arr.hasOwnProperty(item.x)) {
        arr[item.x] = [];
      }

      arr[item.x][item.y] = 0;

      if (arr[item.x].length > this._attrs.size.y) {
        console.error('Invalid surface for Y size, skipping custom bezel');
        return;
      }

      if (arr.length > this._attrs.size.x) {
        console.error('Invalid surface for X size, skipping custom bezel');
        return;
      }
    }

    return arr;
  }

  protected _isLegacyLayout() {
    return (
      this.__grid.layout_name.startsWith('v5') ||
      this.__grid.layout_name.startsWith('v8')
    );
  }

  protected _checkLayoutIntegrity() {
    const layouts = (this.constructor as typeof Plate).Layouts;
    const alias = (this.constructor as typeof Plate).Alias;

    if (layouts === 'all') {
      return;
    }

    if (!layouts.includes(this.__grid.layout_name)) {
      throw new Error(
        `The plate '${alias}' does not support the '${this.__grid.layout_name}' layout`,
      );
    }
  }

  /**
   * @param orientation string descriptor of the orientation
   *
   * @returns is the orientation horizontal
   */
  public static IsOrientationHorizontal(orientation: string) {
    return (
      orientation === Plate.Orientations.East ||
      orientation === Plate.Orientations.West
    );
  }

  /**
   * @param orientation string descriptor of the orientation
   *
   * @returns is the orientation vertical
   */
  public static IsOrientationVertical(orientation: string) {
    return (
      orientation === Plate.Orientations.North ||
      orientation === Plate.Orientations.South
    );
  }

  /**
   * Converts string defining plate orientation to an angle to rotate from the {@link Plate.Orientations.West}
   *
   * @param orientation plate orientation to rotate to
   *
   * @returns rotation angle in degrees
   */
  private static _orientationToAngle(orientation: string) {
    switch (orientation) {
      case Plate.Orientations.West: {
        return 0;
      }
      case Plate.Orientations.North: {
        return 270;
      }
      case Plate.Orientations.East: {
        return 180;
      }
      case Plate.Orientations.South: {
        return 90;
      }
      default: {
        throw new TypeError(`Invalid 'orientation' argument: ${orientation}`);
      }
    }
  }

  /**
   * Translates {@link XYPoint} coordinate increment
   *
   * If any point will be incremented with the result of this method,
   * it will be "rotated" relative to the origin to the specified orientation.
   *
   * @param xyobj
   * @param orientation
   *
   * @returns translated coordinates
   */
  public static _orientXYObject(
    xyobj: { x: number; y: number },
    orientation: string,
  ): { x: number; y: number } {
    let xynew: { x: number; y: number };

    switch (orientation) {
      case Plate.Orientations.West: {
        xynew = { x: xyobj.x, y: xyobj.y };
        break;
      }
      case Plate.Orientations.South: {
        xynew = { x: xyobj.y, y: -xyobj.x };
        break;
      }
      case Plate.Orientations.East: {
        xynew = { x: -xyobj.x, y: -xyobj.y };
        break;
      }
      case Plate.Orientations.North: {
        xynew = { x: -xyobj.y, y: xyobj.x };
        break;
      }
      default: {
        throw new TypeError(`Invalid 'orientation' argument: ${orientation}`);
      }
    }

    return xynew;
  }
}

import Presenter, { on, restore } from '../../core/base/Presenter';
import BoardView from '../../views/common/BoardView';
import type SettingsModel from '../../core/models/SettingsModel';
import { CoreMode } from '~/js/models/constants';
import { CodeModel } from '~/js/models/common/CodeModel';
import { BoardModel } from '~/js/models/common/BoardModel';
import {
  BoardLayoutEvent,
  BoardModeEvent,
  BoardUnknownEvent,
  ElectronicEvent,
  FieldsEvent,
  ForceLayoutEvent,
  PlateEvent,
} from '~/js/models/common/BoardModel/events';
import ModalModel from '~/js/core/models/ModalModel';
import { ColorAccent } from '~/js/core/helpers/styles';
import { BreadboardMode } from '~/js/utils/breadboard/Breadboard.types';
import i18next from 'i18next';
import {
  type VariableDefinition,
  VariableType,
} from '~/js/models/common/CodeModel/types';

export const MAP_MODE_CORE_BB = {
  [CoreMode.Configuring]: BreadboardMode.Default,
  [CoreMode.Virtual]: BreadboardMode.Virtual,
  [CoreMode.Display]: BreadboardMode.Display,
  [CoreMode.Real]: BreadboardMode.Real,
};

export const MAP_MODE_BB_CORE = {
  [BreadboardMode.Default]: CoreMode.Configuring,
  [BreadboardMode.Virtual]: CoreMode.Virtual,
  [BreadboardMode.Display]: CoreMode.Display,
  [BreadboardMode.Real]: CoreMode.Real,
};

export default class BoardPresenter extends Presenter<BoardView.BoardView> {
  private code: CodeModel;
  private board: BoardModel;
  private modal: ModalModel;
  private readonly settings: SettingsModel;

  public getInitialProps() {
    this.code = this.getModel(CodeModel);
    this.board = this.getModel(BoardModel);
    this.modal = this.getModel(ModalModel);

    const { mode, is_demo, layout_name } = this.board.getState();

    return {
      layouts: BoardModel.Layouts,
      layout_name,
      mode: this.mapCoreModeToBoardMode(mode, is_demo),
    };
  }

  @on(BoardView.LayoutChangeRequestEvent)
  private onLayoutChangeRequest(evt: BoardView.LayoutChangeRequestEvent) {
    this.board.forceLayout(evt.layout_name);
  }

  @on(BoardView.PlatesChangeEvent)
  private onUserChange(evt: BoardView.PlatesChangeEvent) {
    this.board.setUserPlates(evt.plates);
  }

  @on(BoardView.FieldsChangeEvent)
  private onUserFieldsChange(evt: BoardView.FieldsChangeEvent) {
    this.board.setUserFields(evt.fields);
  }

  @on(BoardView.ModeRequestEvent)
  private onModeRequest({ mode }: BoardView.ModeRequestEvent) {
    const coreMode = this.mapBoardModeToCoreMode(mode);
    this.board.setMode(coreMode);
  }

  @on(BoardUnknownEvent)
  private onBoardUnknown({ version }: BoardUnknownEvent) {
    this.modal.showToast({
      title: 'Unknown board version',
      content: `No layout found for board version ${version}`,
      status: ColorAccent.Warning,
    });
  }

  @restore()
  @on(BoardLayoutEvent)
  private onCoreLayoutChange({ layout_name }: BoardLayoutEvent) {
    this.view.setLayout(layout_name);
  }

  @restore()
  @on(BoardModeEvent)
  private onOptionsChange({ mode, is_demo }: BoardModeEvent) {
    const viewMode = this.mapCoreModeToBoardMode(mode, is_demo);
    this.view.setMode(viewMode);
  }

  @restore()
  @on(PlateEvent)
  private onplates(evt: PlateEvent) {
    this.view.setPlates(evt.plates);
  }

  @restore()
  @on(FieldsEvent)
  private onfields(evt: FieldsEvent) {
    this.view.setFields(evt.fields);
  }

  @restore()
  @on(ElectronicEvent)
  private onelec(evt: ElectronicEvent) {
    this.view.setElecData(evt.elec_data);
  }

  @restore()
  @on(ForceLayoutEvent)
  private showForceLayoutMessage({
    force_layout_name,
    layout_name,
  }: ForceLayoutEvent) {
    if (force_layout_name) {
      this.view.setLayout(force_layout_name);

      this.modal.showToast({
        status: ColorAccent.Primary,
        title: i18next.t('main:board.connection.force_layout.title', {
          LAYOUT_NAME: force_layout_name,
        }),
        content: i18next.t('main:board.connection.force_layout.content'),
        action: {
          title: i18next.t('main:board.connection.force_layout.action_disable'),
          callback: () => {
            this.board.disableForceLayout();
          },
        },
      });
    } else {
      this.view.setLayout(layout_name);

      this.modal.showToast({
        status: ColorAccent.Primary,
        title: i18next.t('main:board.connection.force_layout_disabled.title'),
        content: i18next.t(
          'main:board.connection.force_layout_disabled.content',
          { LAYOUT_NAME: layout_name },
        ),
        timeout: 5000,
      });
    }
  }

  private extractArduinoVarDefs() {
    const arduinoVarKeys = this.view.getArduinoVariables();

    return arduinoVarKeys.map((key) => ({
      key,
      name: key,
      initialValue: 0,
      type: VariableType.Arduino,
    })) as VariableDefinition[];
  }

  private mapCoreModeToBoardMode(mode: CoreMode, is_demo: boolean) {
    if (is_demo) {
      return BreadboardMode.Virtual;
    }

    return MAP_MODE_CORE_BB[mode] || BreadboardMode.Default;
  }

  private mapBoardModeToCoreMode(mode: BreadboardMode) {
    return MAP_MODE_BB_CORE[mode] || CoreMode.Virtual;
  }
}

// Event channels
export const enum ChannelsCore {
  // communication
  CMC_CONNECT = 'core.cmc.connect',
  CMC_CONNECT_LEGACY = 'connect',
  CMC_SWAP = 'core.cmc.swap',

  // device
  DEV_STATUS = 'core.dev.status',

  // configuration
  CNF_LAYOUT_CONFIRM = 'core.cnf.layout-confirm',

  // board
  BRD_UNITS = 'core.brd.units',
  BRD_STATES = 'core.brd.states',
  BRD_CURRENTS = 'core.brd.currents',

  // program
  PRG_EXECUTE = 'core.prg.execute',
  PRG_VARIABLE = 'core.prg.variable',
  PRG_TERMINATE = 'core.prg.terminate',

  // debug
  DBG_MESSAGE = 'debug',
  DBG_ERROR = 'core.dbg.error',
}

export const enum ChannelsUi {
  // communication
  CMC_CONNECT = 'ui.cmc.connect',
  CMC_DISCONNECT = 'ui.cmc.disconnect',

  // device
  DEV_RESET = 'ui.dev.reset',

  // configuration
  CNF_MODE = 'ui.cnf.mode',
  CNF_LAYOUT = 'ui.cnf.layout',

  // board
  BRD_UNITS = 'ui.brd.units',
  BRD_STATES = 'ui.brd.states',

  // program
  PRG_COMMANDS = 'ui.prg.commands',
  PRG_STOP = 'ui.prg.stop',
  PRG_VARDEFS = 'ui.prg.vardefs',

  // application
  APP_ACTION = 'ui.app.action',
}

export const enum AppActions {
  IssueReportRequest = 'issue_report_request',
  LogDownloadRequest = 'log_download_request',
  SaveLanguage = 'save_language',
  SkipVersion = 'skip_version',
}

export const enum CoreMode {
  Configuring = 'configuring',
  Virtual = 'virtual',
  Real = 'real',
  Display = 'display',
}

export const CORE_MODES_READONLY = [CoreMode.Real, CoreMode.Display];

import { Direction } from '../types';

/**
 *
 *
 * @category Breadboard
 */
export const DirsClockwise = [
  Direction.Up,
  Direction.Right,
  Direction.Down,
  Direction.Left,
];

import { type Cell } from '../../core/Grid';

import { Plate } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Temp sensor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class TempSensorPlate extends LinearPlate {
  static get Alias() {
    return PLATE_ALIASES.TEMP_SENSOR;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected get __length__() {
    return 3;
  }

  /**
   * Нарисовать диод
   *
   * @param {Cell}   position     положение светодиода
   * @param {string}  orientation ориентация светодиода
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();
  }

  /**
   * Draws a diode on the plate surface
   *
   * @param {number} qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(1, 0);
    const cell3 = this.__grid.getCell(2, 0);

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);
    const rect3 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');
    rect3
      .center(cell3.center_rel.x, cell3.center_rel.y)
      .addClass('bb-plate-fill');

    const fs = Plate.LabelFontSizePreferred;

    const ls = qs * 0.7;

    const line_minus_horz = this._group.line([
      [0, ls],
      [ls, ls],
    ]);

    const line_plus_horz = this._group.line([
      [0, ls],
      [ls, ls],
    ]);

    const line_plus_vert = this._group.line([
      [ls, 0],
      [ls, ls],
    ]);

    line_minus_horz
      .addClass('bb-plate-stroke')
      .stroke({ width: 3 })
      .cx(cell1.center_rel.x)
      .cy(this._container.height() - fs);

    line_plus_horz
      .addClass('bb-plate-stroke')
      .stroke({ width: 3 })
      .cx(cell3.center_rel.x)
      .cy(this._container.height() - fs);

    line_plus_vert
      .addClass('bb-plate-stroke')
      .stroke({ width: 3 })
      .cx(cell3.center_rel.x)
      .cy(this._container.height() - fs);

    this._drawLabel(fs);
  }

  private _drawLabel(size = Plate.LabelFontSizePreferred) {
    this._group
      .text('TEMP. SENSOR')
      .addClass('bb-plate-caption')
      .addClass('bb-plate-stroke')
      .font({
        size,
      })
      .cx(this._container.width() / 2)
      .cy(this._container.height() - size)
      .stroke({ width: 0.5 });
  }
}

import { CodeModel } from '~/js/models/common/CodeModel';
import Presenter, { on, restore } from '~/js/core/base/Presenter';
import type VariableView from '~/js/views/common/VariableView';
import {
  CodeVardefUpdateEvent,
  CodeVariableUpdateEvent,
} from '~/js/models/common/CodeModel/events';
import { LessonModel } from '~/js/models/lesson/LessonModel';
import { ProgressModel } from '~/js/models/lesson/ProgressModel';
import {
  ExerciseRunEvent,
  LessonRunEvent,
} from '~/js/models/lesson/ProgressModel/events';
import { lessonVariablesToCore } from '~/js/presenters/lesson/VariablesLessonPresenter/helpers';
import { VariableSource } from '~/js/models/common/CodeModel/types';

export class VariablesLessonPresenter extends Presenter<VariableView.VariableView> {
  private code: CodeModel;
  private lesson: LessonModel;
  private progress: ProgressModel;

  getInitialProps(): any {
    this.code = this.getModel(CodeModel);
    this.lesson = this.getModel(LessonModel);
    this.progress = this.getModel(ProgressModel);

    return {
      variables: this.code.getVariables(),
    };
  }

  @restore()
  @on(CodeVardefUpdateEvent, CodeVariableUpdateEvent)
  private async showVariableValues() {
    this.setViewProps({
      variables: this.code.getVariables(),
    });
  }

  @restore()
  @on(LessonRunEvent, ExerciseRunEvent)
  private async defineVariables() {
    const vardefs = this.getVariableDefinitions();

    this.code.setVariableDefinitions(vardefs || [], VariableSource.Lesson);
  }

  private getVariableDefinitions() {
    const [mission_idx, exercise_idx] = this.progress.getOpenedExerciseIndex();

    const exercise = this.lesson.getExercise(mission_idx, exercise_idx);

    const code = exercise?.module_settings?.code;

    return lessonVariablesToCore(code?.variables || []);
  }
}

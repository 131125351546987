import { type Cell } from '../../Grid';
import { Plate } from '../Plate';
import { type PlateProps, type PlateState } from '../types';

/**
 * Represents plates with a thickness of one cell, so its size can be expressed as length
 *
 * @category Breadboard
 */
export abstract class LinearPlate<
  Props extends PlateProps = PlateProps,
  State extends PlateState = PlateState,
> extends Plate<Props, State> {
  /**
   * Defines length of the plate
   */
  protected get __length__() {
    return 2;
  }

  protected __defaultAttrs__() {
    const arr = [...new Array(this.__length__)];

    return {
      ...super.__defaultAttrs__(),

      size: { x: this.__length__, y: 1 },

      // Относительные точки плашки (координаты в стандартной ориентации)
      // Единица - размер ячейки (в каждом измерении)
      rels: arr.map((_, i) => ({ x: i, y: 0, adj: { x: 0, y: 0 } })),
      surface: arr.map((_, i) => ({ x: i, y: 0 })),
    };
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: any) {
    if (this.attrs.size.x !== 1 && this.attrs.size.y !== 1) {
      throw new RangeError('Invalid size of LinearPlate');
    }

    if (this.attrs.size.x === 1 && this.attrs.size.y === 1) {
      throw new RangeError('1x1 plate is not allowed to be a LinearPlate');
    }
  }

  /**
   * @inheritdoc
   */
  protected _getOppositeCell(cell: Cell) {
    const cell_main = this.state.position.cell;

    if (cell === cell_main) {
      let didx;

      if (this.attrs.size.x !== 1) {
        didx = Plate._orientXYObject(
          { x: this.__length__ - 1, y: 0 },
          this.state.position.orientation,
        );
      } else {
        didx = Plate._orientXYObject(
          { x: 0, y: this.__length__ - 1 },
          this.state.position.orientation,
        );
      }

      return this.__grid.getCell(
        cell_main.idx.x + didx.x,
        cell_main.idx.y + didx.y,
      );
    }

    const didx = Plate._orientXYObject(
      { x: this.attrs.size.x - 1, y: this.attrs.size.y - 1 },
      this.state.position.orientation,
    );

    const cell_last = this.__grid.getCell(
      cell_main.idx.x + didx.x,
      cell_main.idx.y + didx.y,
    );

    if (cell === cell_last) {
      return cell_main;
    }
  }
}

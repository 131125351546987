import Presenter, { on } from '~/js/core/base/Presenter';
import IssueView from '~/js/views/controls/IssueView';
import ModalModel from '~/js/core/models/ModalModel';
import { AlertType } from '~/js/core/views/AlertView';
import { ConnectionModel } from '~/js/models/common/ConnectionModel';
import { IssueReportCompleteEvent } from '~/js/models/common/ConnectionModel/events';

// passed by DefinePlugin in Webpack config
declare const __VERSION__: string;

// @ts-expect-error
export default class IssuePresenter extends Presenter<IssueView.IssueView> {
  private readonly ver_srv: {
    self: number[];
    core: number[];
    verifier: number[];
  };

  private model_modal: ModalModel;
  private model_connection: ConnectionModel;

  getInitialProps() {
    this.model_modal = this.getModel(ModalModel);
    this.model_connection = this.getModel(ConnectionModel);
  }

  @on(IssueView.LogDownloadRequestEvent)
  private requestLogDwnload() {
    this.model_connection.requestLogDownload();
  }

  @on(IssueView.IssueReportRequestEvent)
  private requestIssueReport(evt: IssueView.IssueReportRequestEvent) {
    this.model_connection.requestIssueReport(
      { server: this.ver_srv, web: __VERSION__ },
      evt.message,
    );
  }

  @on(IssueReportCompleteEvent)
  private showIssueReportCompletedAlert() {
    this.model_modal.showAlert(AlertType.IssueReportCompleted);
  }
}

import * as React from 'react';
import { ViewEvent } from '~/js/core/base/Event';
import { type IViewProps, View } from '~/js/core/base/view/View';

import i18next from 'i18next';

require('css/logo.less');
require('css/blocks/about.less');
require('css/blocks/generic/btn.less');

namespace AboutView {
  export class IssuePromptEvent extends ViewEvent<IssuePromptEvent> {}

  export interface Props extends IViewProps {
    ver_web: string;
    ver_cli?: { app: string; core: string };
    ver_srv?: {
      self: string;
      core: string;
      verifier: string;
    };
  }

  export class AboutView extends View<Props> {
    static defaultProps: Props = {
      ver_web: 'n/a',
      ver_cli: { app: 'n/a', core: 'n/a' },
      ver_srv: { self: 'n/a', core: 'n/a', verifier: 'n/a' },
    };

    render(): React.ReactNode {
      const logo_class = i18next.language == 'en' ? 'logo__full_english' : '';

      return (
        <div className="about">
          <div className="about__heading">
            <div className="about__heading-section">
              <div className={`logo logo__full ${logo_class}`} />
              <p className="logo-subscription">
                {i18next.t('main:about.modal.logo_subscription')}
              </p>
            </div>

            <div className="about__heading-section about__version">
              <p className="about__version_line">
                <b>UI:</b> {this.props.ver_web}
              </p>
              <p className="about__version_line">
                <b>App: </b>
                {this.props?.ver_cli?.app}&nbsp; (<b>core: </b>
                {this.props?.ver_cli?.core})
              </p>
              <p className="about__version_line">
                <b>Server: </b>
                {this.props.ver_srv?.self}&nbsp; (<b>core: </b>
                {this.props.ver_srv?.core},&nbsp;
                <b>verifier: </b>
                {this.props.ver_srv?.verifier})
              </p>
            </div>
          </div>

          {this.props.ver_cli?.app != 'n/a' ? (
            <div className="about__footer btn-bar btn-bar_right">
              <div
                className="btn btn_sm btn_danger"
                onClick={async () => {
                  await this.emit(new IssuePromptEvent());
                }}
              >
                {i18next.t('main:about.modal.event_log_download')}
                {/* Сообщить об ошибке */}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  }
}

export default AboutView;

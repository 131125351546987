import type SVG from 'svg.js';

import { type Cell } from '../../core/Grid';
import {
  Plate,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { mod } from '../../helpers/common';
import { PLATE_ALIASES } from './constants';

type PhotoresistorPlateState = PlateState & {
  field: number;
};

/**
 * Photoresistor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class PhotoresistorPlate extends LinearPlate<
  PlateProps,
  PhotoresistorPlateState
> {
  private readonly _svginp: any;
  private readonly _svginpbg: SVG.Rect;
  static get Alias() {
    return PLATE_ALIASES.PHOTORESISTOR;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {
      field: 0,
    };
  }

  /**
   * @deprecated
   * @inheritdoc
   */
  public get input() {
    return Number(this.state.field);
  }

  /**
   * @inheritdoc
   */
  public inputIncrement() {
    this.setState({ field: mod(Number(this.state.field) + 1, 256) });
  }

  /**
   * @inheritdoc
   */
  public inputDecrement() {
    this.setState({ field: mod(Number(this.state.field) - 1, 256) });
  }

  /**
   * @inheritdoc
   */
  public setState(
    state: Partial<SerializedPlateState<PhotoresistorPlateState>>,
    suppress_events: boolean = false,
  ) {
    if (state.field === undefined) {
      return;
    }

    let field = state.field || 0;

    field = Math.round(field * 10e7) / 10e7;

    super.setState({ field }, suppress_events);

    if (this.options.verbose) {
      this._redrawInput(field);
    }
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    if (this.options.verbose) {
      this._redrawInput(this.state.field);
    }
  }

  /**
   *
   * @param {number} qs размер квадратов
   * @private
   */
  protected _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect2.x() - rect1.x();

    this._group
      .polyline([
        [0, 0],
        [line_len, 0],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .fill('none')
      .move(rect1.cx(), rect2.cy());

    this._drawPhotoElement(rect1, line_len, qs);

    const fs = Plate.LabelFontSizePreferred - 2;

    this._group
      .text(this._getCaption())
      .addClass('bb-plate-caption')
      .font({ size: fs, anchor: 'middle' })
      .x(this._container.width() / 2)
      .y(this._container.height() - fs * 2);
  }

  protected _drawPhotoElement(rect1: SVG.Rect, line_len: number, qs: number) {
    const frame = this._group
      .rect(line_len / 3, qs / 1.6)
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .radius(qs / 3)
      .cx(rect1.cx() + line_len / 2)
      .cy(rect1.cy());

    this._group
      .path([
        ['M', 0, 0],
        ['c', 0, qs / 7 + 1, -line_len / 6, 0, -line_len / 6, qs / 7],
        ['c', 0, qs / 7 + 1, line_len / 6, 0, line_len / 6, qs / 7],
        ['c', 0, qs / 7 + 1, -line_len / 6, 0, -line_len / 6, qs / 7],
        ['c', 0, qs / 7 + 1, line_len / 6, 0, line_len / 6, qs / 7],
        ['c', 0, qs / 7 + 1, -line_len / 6, 0, -line_len / 6, qs / 7],
      ])
      .addClass('bb-plate-stroke-inv')
      .fill({ color: 'transparent' })
      .stroke({ width: 1 })
      .cx(frame.cx())
      .y(frame.y());

    this._group
      .line(0, 0, 0, qs / 1.5)
      .addClass('bb-plate-stroke')
      .stroke({ width: qs / 6 })
      .center(frame.cx(), frame.cy());

    this._group.circle(qs / 6).center(frame.cx(), frame.cy());
  }

  protected _getCaption() {
    return 'PH. RESISTOR';
  }
}

import { type Cell } from '../../core/Grid';
import { Plate } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

/**
 * RGB plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class RGBPlate extends LinearPlate {
  static get Alias() {
    return PLATE_ALIASES.RGB;
  }

  protected get __length__() {
    return 4;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    this._drawLabel();
  }

  /**
   * Draws LEDs over the plate surface
   *
   * @param ls size of LEDs
   * @param qs size of rects
   */
  private _drawPicture(
    ls = Plate.LEDSizePreferred,
    qs = Plate.QuadSizePreferred,
  ) {
    const cells = [
      this.__grid.getCell(0, 0),
      this.__grid.getCell(1, 0),
      this.__grid.getCell(2, 0),
      this.__grid.getCell(3, 0),
    ];

    const cell1 = cells[0];
    const cell2 = cells[cells.length - 1];

    const center_x = cell1.center_rel.x + (cell2.pos.x - cell1.pos.x) / 2;
    const center_y = cell1.center_rel.y;

    this._group
      .rect(ls, ls)
      .center(center_x, center_y)
      .fill('#ffffff')
      .stroke({ color: '#e7e7e7', width: 1 })
      .attr('filter', 'url(#glow-led)');

    this._group
      .circle(ls / 1.5)
      .center(center_x, center_y)
      .fill('#e2e2e2');

    if (this._isLegacyLayout()) {
      for (const cell of cells) {
        this._group
          .circle(ls / 1.5)
          .center(cell.center_rel.x, cell.center_rel.y)
          .fill('#1f1f1f')
          .stroke({ color: '#e7e7e7', width: 1 });
      }
    } else {
      for (const cell of cells) {
        this._group
          .rect(qs, qs)
          .center(cell.center_rel.x, cell.center_rel.y)
          .addClass('bb-plate-fill');
      }
    }
  }

  /**
   * Draws labels for LEDs
   *
   * @param size label font size
   */
  private _drawLabel(size = Plate.LabelFontSizePreferred) {
    const cells = [
      this.__grid.getCell(0, 0),
      this.__grid.getCell(1, 0),
      this.__grid.getCell(2, 0),
      this.__grid.getCell(3, 0),
    ];

    const point_y = this._isLegacyLayout()
      ? this._container.height() - size
      : size;

    this._group
      .text(this._isLegacyLayout() ? 'R' : 'RED')
      .addClass('bb-plate-caption')
      .font({
        size,
        anchor: 'center',
      })
      .center(cells[0].center_rel.x, point_y);

    this._group
      .text(this._isLegacyLayout() ? 'G' : 'GREEN')
      .addClass('bb-plate-caption')
      .font({
        size,
        anchor: 'center',
      })
      .center(cells[1].center_rel.x, point_y);

    this._group
      .text(this._isLegacyLayout() ? 'B' : 'BLUE')
      .addClass('bb-plate-caption')
      .font({
        size,
        anchor: 'center',
      })
      .center(cells[2].center_rel.x, point_y);

    if (this._isLegacyLayout()) {
      this._group
        .text('gnd')
        .addClass('bb-plate-caption')
        .font({
          size,
          anchor: 'center',
        })
        .center(cells[3].center_rel.x, point_y);
    } else {
      this._group
        .line(0, 0, 10, 0)
        .center(cells[3].center_rel.x, point_y)
        .stroke({ width: 2 })
        .addClass('bb-plate-stroke');
    }

    // gnd.dy(-gnd.node)

    /**
        label.x(this._container.width())
            .y(this._container.height() - size)
            .stroke({width: 0.5});
         */
  }
}

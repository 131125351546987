import type SVG from 'svg.js';

import { THREAD_WEIGHT_MULTIPLIER } from '~/js/utils/breadboard/core/Current';
import { type Cell } from '~/js/utils/breadboard/core/Grid';
import {
  Plate,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from '../../core/Plate';
import { PLATE_ALIASES } from './constants';

type IndicatorPlateState = PlateState & {
  field: number[];
};

const SEG_INACTIVE_COLOR = '#868686';
const SEG_ACTIVE_COLOR = '#cbff45';

const SEG_MAPPING = [3, 2, 1, 0, 7, 6, 5, 4];

/**
 * Indicator plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class IndicatorPlate extends Plate<PlateProps, IndicatorPlateState> {
  static get Alias() {
    return PLATE_ALIASES.INDICATOR;
  }

  private readonly _svginp: SVG.Text;

  private readonly _svginpbg: SVG.Rect;

  private _segments: SVG.Shape[];

  protected __defaultAttrs__(): any {
    return {
      ...super.__defaultAttrs__(),

      size: { x: 5, y: 2 },
      surface: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: 2, y: 1 },
        { x: 3, y: 1 },
        { x: 4, y: 1 },
      ],
      origin: { x: 0, y: 0 },
    };
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {
      field: 0,
    };
  }

  /**
   * @deprecated
   * @inheritdoc
   */
  public get input() {
    return Number(this.state.field);
  }

  /**
   * @inheritdoc
   */
  public setState(
    state: Partial<SerializedPlateState<IndicatorPlateState>>,
    suppress_events: boolean = false,
  ) {
    if (state.field == undefined) {
      return;
    }

    super.setState(state, suppress_events);

    this._updateSegments();

    if (this.options.verbose) {
      this._redrawInput(
        state.field.map((val: number) => (val === 0 ? '0' : '1')).join(''),
      );
    }
  }

  protected _updateSegments() {
    for (const segmentIdx in this._segments) {
      const segmentIdxMapped = SEG_MAPPING[segmentIdx];

      const segment = this._segments[segmentIdx];
      let weight = this.state.field?.[segmentIdxMapped] || 0;

      weight *= THREAD_WEIGHT_MULTIPLIER;

      // Keep value in [0..1] interval
      const k = 1.6;
      weight = 1 - 1 / (1 + k * weight);

      segment.fill({
        opacity: weight || 0.1,
        color: weight ? SEG_ACTIVE_COLOR : SEG_INACTIVE_COLOR,
      });
    }
  }

  protected __draw__(cell: Cell, orientation: string): void {
    this._drawPicture();
    this._updateSegments();
  }

  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cells = {
      top: [...Array(5)].map((_, i) => this.__grid.getCell(i, 0)),
      btm: [...Array(5)].map((_, i) => this.__grid.getCell(i, 1)),
    };

    // Cell rects
    cells.top.forEach((cell) =>
      this._group
        .rect(qs, qs)
        .center(cell.center_rel.x, cell.center_rel.y)
        .addClass('bb-plate-fill'),
    );
    cells.btm.forEach((cell) =>
      this._group
        .rect(qs, qs)
        .center(cell.center_rel.x, cell.center_rel.y)
        .addClass('bb-plate-fill'),
    );

    const label_texts = {
      top: ['D', 'C', 'B', 'A', '-'],
      btm: ['DP', 'G', 'F', 'E', '-'],
    };

    const fs = Plate.LabelFontSizePreferred - 2;

    // Cell labels
    cells.top.forEach((cell, i) => {
      this._drawCellLabel(cell, label_texts.top[i], qs, fs);
    });
    cells.btm.forEach((cell, i) => {
      this._drawCellLabel(cell, label_texts.btm[i], qs, fs);
    });

    this._group
      .text('INDICATOR')
      .addClass('bb-plate-caption')
      .font({ size: fs })
      .cx(this._container.width() / 2)
      .cy(this._container.height() / 2);

    // Indicator background
    const ind_bg = this._group
      // .rect(
      //   cells.top[1].rel.x - cells.top[0].rel.x - qs,
      //   cells.btm[1].rel.y - cells.top[1].rel.y - qs,
      // )
      .rect(
        (cells.top[1].rel.x + cells.top[0].rel.x) / 4 + qs,
        cells.btm[1].rel.y + cells.top[1].rel.y + qs,
      )
      .center(
        (cells.top[0].center_rel.x + cells.top[1].center_rel.x) / 2 - qs / 4,
        (cells.top[0].center_rel.y + cells.btm[0].center_rel.y) / 2,
      )
      // .addClass('bb-plate-stroke')
      .fill({ opacity: 0 });

    const ssgroup = this._group.nested();
    ssgroup.viewbox(-10, -10, 120, 200);
    ssgroup
      .width(ind_bg.height() / 2)
      .height(ind_bg.height())
      .cx(ind_bg.cx())
      .y(ind_bg.y());

    // Bounding rect
    // this._group
    //   .rect(ssgroup.width() + 5, ssgroup.height() - 4)
    //   .cx(ind_bg.cx())
    //   .y(ind_bg.y() + 2)
    //   .fill({ opacity: 0 })
    //   .stroke({ color: '#000', opacity: 0.2 });
    //
    //  AAAA
    // F    B
    //  GGGG
    // E    C
    //  DDDD

    const polys_shapes = [
      // A
      [
        [10, 10],
        [20, 0],
        [80, 0],
        [90, 10],
        [80, 20],
        [20, 20],
      ],
      // B
      [
        [90, 10],
        [100, 20],
        [100, 80],
        [90, 90],
        [80, 80],
        [80, 20],
      ],
      // C
      [
        [90, 90],
        [100, 100],
        [100, 160],
        [90, 170],
        [80, 160],
        [80, 100],
      ],
      // D
      [
        [90, 170],
        [80, 180],
        [20, 180],
        [10, 170],
        [20, 160],
        [80, 160],
      ],
      // E
      [
        [10, 170],
        [0, 160],
        [0, 100],
        [10, 90],
        [20, 100],
        [20, 160],
      ],
      // F
      [
        [10, 90],
        [0, 80],
        [0, 20],
        [10, 10],
        [20, 20],
        [20, 80],
      ],
      // G
      [
        [10, 90],
        [20, 80],
        [80, 80],
        [90, 90],
        [80, 100],
        [20, 100],
      ],
    ];

    const polys: SVG.Shape[] = polys_shapes.map((path) =>
      ssgroup.polygon(path),
    );

    polys.push(ssgroup.circle(20).cx(100).cy(180));

    polys.forEach((poly) =>
      poly
        .fill({ color: SEG_INACTIVE_COLOR })
        .stroke({ opacity: 0.6 })
        .addClass('bb-plate-stroke'),
    );

    this._segments = polys;

    // polys.forEach((poly) => poly.fill({ opacity: 0.1 }));
  }

  private _drawCellLabel(
    cell: Cell,
    text: string,
    qs = Plate.QuadSizePreferred,
    size = Plate.LabelFontSizePreferred,
  ) {
    if (text === '-') {
      const ls = qs * 0.7;

      const line_minus_horz = this._group.line([
        [0, ls],
        [ls, ls],
      ]);

      line_minus_horz
        .addClass('bb-plate-stroke')
        .stroke({ width: 3 })
        .x(cell.rel.x)
        .cy(cell.center_rel.y);

      return;
    }

    this._group
      .text(text)
      .addClass('bb-plate-caption')
      .font({
        size,
        anchor: 'end',
      })
      .x(cell.rel.x + qs * 1.1)
      .cy(cell.center_rel.y);
  }
}

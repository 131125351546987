import { type Cell } from '../../core/Grid';

import { Plate, type PlateProps } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { BackgroundLayer } from '../layers/BackgroundLayer';
import { PLATE_ALIASES } from './constants';

type UnkPlateProps = PlateProps & {
  type: number;
};

/**
 * Unknown plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class UnkPlate extends LinearPlate<UnkPlateProps> {
  static get Alias() {
    return PLATE_ALIASES.UNK;
  }

  /**
   * @inheritdoc
   */
  protected get __length__() {
    return 1;
  }

  protected get __inheritProps__() {
    return {
      type: -1,
    };
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected __setProps__(props: UnkPlateProps) {
    super.__setProps__({ ...props, type: Number(props.type) });
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    const cell = this.__grid.getCell(0, 0);

    this._bezel = this._group
      .rect()
      .width('100%')
      .height('100%')
      .radius(BackgroundLayer.CellRadius)
      .style({ fill: '#ffbebe' });

    this._group
      .text(`${this.props.type}`)
      .font({
        size: Plate.LabelFontSizePreferred + 6,
        family: Plate.CaptionFontFamily,
        weight: 'bold',
      })
      .center(cell.center_rel.x, cell.center_rel.y);

    this._group
      .circle(5)
      .center(cell.rel.x + cell.size.x - 10, cell.rel.y + 10);
  }
}

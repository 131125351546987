import { CodeModel } from '~/js/models/common/CodeModel';
import Presenter, { on, restore } from '../../core/base/Presenter';
import BlocklyView, {
  BlocklyCodeChangeEvent,
} from '../../views/common/BlocklyView';
import {
  CodeCommandExecutedEvent,
  CodeLaunchedEvent,
  CodeTerminatedEvent,
  CodeVardefUpdateEvent,
} from '~/js/models/common/CodeModel/events';
import { KeyUpEvent } from '~/js/core/models/KeyboardModel';

export default class BlocklyPresenter extends Presenter<BlocklyView> {
  private model: CodeModel;

  public getInitialProps() {
    this.model = this.getModel(CodeModel);

    return {
      initialVardefs: this.model.getVariableDefinitions(),
    };
  }

  @restore()
  @on(CodeVardefUpdateEvent)
  private async showVariableValues(evt: CodeVardefUpdateEvent) {
    this.view.setVariables(evt.vardefs);
  }

  @on(BlocklyCodeChangeEvent)
  private onCodeChange(evt: BlocklyCodeChangeEvent) {
    const chains = BlocklyView.getChainset(evt.workspace);

    this.model.setChainset(chains);
  }

  @on(CodeLaunchedEvent)
  private onCodeLaunched() {
    this.view.lock();
  }

  @on(CodeCommandExecutedEvent)
  private onCommandExecuted(evt: CodeCommandExecutedEvent) {
    this.view.highlightBlock(evt.block_id);
  }

  @on(CodeTerminatedEvent)
  private onCodeTerminated() {
    this.view.highlightBlock(null);
    this.view.unlock();
  }

  @on(KeyUpEvent)
  private onKeyUp(evt: KeyUpEvent) {
    this.model.executeButtonHandlerChain(evt.key);
  }
}

import { AppActions, ChannelsCore, ChannelsUi } from '~/js/models/constants';
import { type Connection } from '~/js/models/common/ConnectionModel/types';
import AsynchronousModel, {
  connect,
  disconnect,
  listen,
  timeout,
  waiting,
} from '~/js/core/base/model/AsynchronousModel';
import { ConnectionStatusEvent } from './events';
import { type ServerGreeting } from './types';
import { BoardErrorEvent } from '~/js/models/common/BoardModel/events';

export class ConnectionModel extends AsynchronousModel<Connection> {
  static alias = 'connection';

  protected defaultState: Connection = { is_active: undefined };
  private lang: string;

  public requestSaveLanguage(lang: string) {
    this.lang = lang;
    this.send(ChannelsUi.APP_ACTION, {
      command: AppActions.SaveLanguage,
      data: { lang },
    });
  }

  public requestIssueReport(versions: object, message: string = '') {
    this.send(ChannelsUi.APP_ACTION, {
      command: AppActions.IssueReportRequest,
      data: { versions, message },
    });
  }

  public requestLogDownload() {
    this.send(ChannelsUi.APP_ACTION, {
      command: AppActions.LogDownloadRequest,
    });
  }

  public requestSkipVersion(version: string) {
    this.send(ChannelsUi.APP_ACTION, {
      command: AppActions.SkipVersion,
      data: { version },
    });
  }

  @listen(ChannelsCore.DBG_MESSAGE)
  private receiveDebugMessage(message: string) {
    // noop
  }

  /**
   * Receive error data reported by the core
   *
   * @param message
   * @param code
   */
  @listen(ChannelsCore.DBG_ERROR)
  private receiveError({ message, code }: any) {
    this.emit(new BoardErrorEvent({ message, code }));
  }

  @connect()
  private onConnect(greeting: ServerGreeting) {
    const app = greeting.app || {
      version: null,
      version_skip: null,
    };

    this.setState({ is_active: true });
    this.emit(
      new ConnectionStatusEvent({
        status: 'connected',
        version: {
          app: app.version || 'n/a',
          core: greeting.version.core || 'n/a',
        },
        version_skip: {
          app: app.version_skip || 'n/a',
        },
      }),
    );

    if (this.lang) {
      // request to ensure if previous request failed
      this.requestSaveLanguage(this.lang);
    }
  }

  @disconnect()
  private onDisconnect() {
    this.setState({ is_active: false });

    this.emit(new ConnectionStatusEvent({
      status: 'disconnected',
      version: {
        app: 'n/a',
        core: 'n/a',
      },
      version_skip: {
        app: 'n/a',
      },
    }));
  }

  @listen(ChannelsCore.CMC_CONNECT_LEGACY)
  private onConnectLegacy(greeting: any) {
    let appVersion = greeting?.app_details?.version;

    if (!appVersion) {
      console.warn('App version is unknown. Will not redirect')
      return;
    }

    if (Array.isArray(appVersion)) {
      appVersion = appVersion.join('.');
    }

    console.warn(`Legacy client v${appVersion} is trying to connect`);

    const hostUrl = new URL(window.location.href);

    if (hostUrl.hostname === 'localhost') {
      console.error('Non-legacy frontend is used. Please run legacy frontend or update to latest client');
      return;
    }

    const hostnameParts = hostUrl.hostname.split('.');

    const hostnameTopSubdomain = hostnameParts.shift();

    if (hostnameTopSubdomain === 'v1') {
      throw new Error('Legacy client tried to connect to non-legacy frontend on legacy host');
    }

    if (hostnameTopSubdomain === 'dev') {
      throw new Error('Restricted to use legacy clients on stage hosts');
    }

    hostUrl.hostname = `v1.${hostnameParts.join('.')}`

    window.location.href = hostUrl.href;
  }

  @listen(ChannelsCore.CMC_SWAP)
  private onServerLeft() {
    console.log('The client was temporarily abandoned by the server.');
    this.setState({ is_active: false });
    this.emit(new ConnectionStatusEvent({ status: 'disconnected' }));
  }

  @timeout()
  private reportTimeout() {
    this.emit(new ConnectionStatusEvent({ status: 'timeout' }));
  }

  @waiting()
  private reportWaiting() {
    this.emit(new ConnectionStatusEvent({ status: 'waiting' }));
  }
}

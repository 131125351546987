import { AuxPointCategory } from '../../core/Grid';
import {
  CellRole,
  DomainSlice,
  PinState,
  type Layout,
} from '../../core/Layout';
import { DomainSchematicStyle } from '../layers/BackgroundLayer/types';
import { LEDPlateColor } from '../plates';
import { PLATE_ALIASES } from '../plates/constants';

const ARD_GND_Y = -144;

const res_200 = {
  type: PLATE_ALIASES.RESISTOR,
  props: { resistance: 200 },
} as const;

const res_1k = {
  ...res_200,
  props: { resistance: 1000 },
} as const;

const res_10k = {
  ...res_200,
  props: { resistance: 10000 },
} as const;

const res_30k = {
  ...res_200,
  props: { resistance: 30000 },
} as const;

const transistor_pnp = {
  type: PLATE_ALIASES.TRANSISTOR_PNP,
  props: {},
} as const;

const temp_sensor = {
  type: PLATE_ALIASES.TEMP_SENSOR,
  props: {},
} as const;

const transistor_npn = {
  type: PLATE_ALIASES.TRANSISTOR_NPN,
  props: {},
} as const;

const rgb = {
  type: PLATE_ALIASES.RGB,
} as const;

const photoresistor = {
  type: PLATE_ALIASES.PHOTORESISTOR,
  props: {},
};

const thermoresistor = {
  type: PLATE_ALIASES.THERMORESISTOR,
  props: {},
};

const transistor_photo = {
  type: PLATE_ALIASES.PHOTOTRANSISTOR,
  props: {},
};

const led = {
  type: PLATE_ALIASES.LED,
};

const buzzer = {
  type: PLATE_ALIASES.BUZZER,
};

const ind100 = {
  type: PLATE_ALIASES.INDUCTOR,
  props: { inductance: 100 },
};

const relay = {
  type: PLATE_ALIASES.RELAY,
  props: {},
};

const rheostat = {
  type: PLATE_ALIASES.RHEOSTAT,
  props: {},
};

const cap_100 = {
  type: PLATE_ALIASES.CAPACITOR,
  props: { capacitance: 1e-4 },
};

const cap_1k = {
  type: PLATE_ALIASES.CAPACITOR,
  props: { capacitance: 1e-3 },
};

const indicator7 = {
  type: PLATE_ALIASES.INDICATOR,
};

const diode = {
  type: PLATE_ALIASES.DIODE,
  props: {},
};

const btn = {
  type: PLATE_ALIASES.BUTTON,
};

const swtch = {
  type: PLATE_ALIASES.SWITCH,
};

const reed_switch = {
  type: PLATE_ALIASES.REED_SWITCH,
};

const battery_1v = {
  type: PLATE_ALIASES.BATTERY,
  props: {
    volt: 1,
  },
};

export const v9x: Layout = {
  name: 'v9x',

  restrict_plates: true,

  plate_style: {
    label_font_size: 14,
    quad_size: 18,
  },

  label_style: {
    font_size: 20,
    text_bias: 10,
  },

  wrap: { x: 850, y: 1500 },
  size: { x: 660, y: 1320 },
  gap: { x: 10, y: 0 },
  dim: { x: 8, y: 16 },
  pos: { x: 10 + (850 - 660) / 2, y: (1300 - 1140) / 2 },

  ddecls: [
    // Top line - Plus pins
    {
      slice: DomainSlice.Horizontal,
      field: { src: { x: 0, y: 0 }, dst: { x: 3, y: 0 } },
      props: {
        role: CellRole.Plus,
        bias_inv: true,
        label_pos: 'top',
      },
    },
    // Bottom line - Minus pins
    {
      slice: DomainSlice.Horizontal,
      field: { src: { x: 4, y: 15 }, dst: { x: -1, y: 15 } },
      props: {
        role: CellRole.Minus,
        label_pos: 'bottom',
      },
      virtual: {
        // Arduino plate ground pins that analog cells are mapped to
        src: { x: 0, y: ARD_GND_Y },
        dst: { x: 8, y: ARD_GND_Y },
      },
    },
    // Top line - Arduino pins (A0-A3)
    {
      slice: DomainSlice.Horizontal,
      field: { src: { x: 4, y: 0 }, dst: { x: -1, y: 0 } },
      minus: (x) => ({ x, y: ARD_GND_Y }),
      props: {
        role: CellRole.Analog,
        style: DomainSchematicStyle.Dotted,
        pins_src: 0,
        bias_inv: true,
        line_before: 1,
        label_pos: 'top',
        value_orientation: 'north',
        pin_state_initial: PinState.Input,
      },
    },

    // Bottom line - Arduino pins (A4-A7)
    {
      slice: DomainSlice.Horizontal,
      field: { src: { x: 0, y: 15 }, dst: { x: 3, y: 15 } },
      minus: (i) => ({ x: 0, y: ARD_GND_Y }),
      props: {
        role: CellRole.Analog,
        style: DomainSchematicStyle.Dotted,
        pins_src: 4,
        line_after: 1,
        label_pos: 'bottom',
        value_orientation: 'south',
        pin_state_initial: PinState.Output,
      },
    },

    // Rects of plate pins
    {
      slice: DomainSlice.Arbitrary,
      // first 5 rows (full width, 8 pins)
      field: { src: { x: 0, y: 1 }, dst: { x: 7, y: 5 } },
    },
    {
      // row with A13 (1 pin is free, so the width is 7 pins)
      slice: DomainSlice.Arbitrary,
      field: { src: { x: 0, y: 6 }, dst: { x: 6, y: 6 } },
    },
    {
      // row between A13 and A12 (full width, 8 pins)
      slice: DomainSlice.Arbitrary,
      field: { src: { x: 0, y: 7 }, dst: { x: 7, y: 7 } },
    },
    // 5 rows with A12 - A8 (1 pin is free on each row, width is 7 pins)
    {
      slice: DomainSlice.Arbitrary,
      field: { src: { x: 0, y: 8 }, dst: { x: 6, y: 12 } },
    },
    // 2 rows full width
    {
      slice: DomainSlice.Arbitrary,
      field: { src: { x: 0, y: 13 }, dst: { x: 7, y: 14 } },
    },
  ],

  aux_point_cats: [AuxPointCategory.SourceV8],

  plates: [
    // resistors
    {
      ...res_200,
      id: -1,
      state: {
        position: {
          cells: [
            { x: 0, y: 1 },
            { x: 1, y: 1 },
          ],
        },
      },
    },
    {
      ...res_200,
      id: -2,
      state: {
        position: {
          cells: [
            { x: 0, y: 2 },
            { x: 1, y: 2 },
          ],
        },
      },
    },
    {
      ...res_200,
      id: -3,
      state: {
        position: {
          cells: [
            { x: 0, y: 3 },
            { x: 1, y: 3 },
          ],
        },
      },
    },

    {
      ...res_1k,
      id: -4,
      state: {
        position: {
          cells: [
            { x: 0, y: 4 },
            { x: 1, y: 4 },
          ],
        },
      },
    },
    {
      ...res_1k,
      id: -5,
      state: {
        position: {
          cells: [
            { x: 0, y: 5 },
            { x: 1, y: 5 },
          ],
        },
      },
    },
    {
      ...res_1k,
      id: -6,
      state: {
        position: {
          cells: [
            { x: 0, y: 6 },
            { x: 1, y: 6 },
          ],
        },
      },
    },

    {
      ...res_10k,
      id: -7,
      state: {
        position: {
          cells: [
            { x: 0, y: 7 },
            { x: 1, y: 7 },
          ],
        },
      },
    },

    {
      ...res_10k,
      id: -8,
      state: {
        position: {
          cells: [
            { x: 0, y: 8 },
            { x: 1, y: 8 },
          ],
        },
      },
    },

    {
      ...res_30k,
      id: -9,
      state: {
        position: {
          cells: [
            { x: 0, y: 9 },
            { x: 1, y: 9 },
          ],
        },
      },
    },

    {
      ...res_30k,
      id: -10,
      state: {
        position: {
          cells: [
            { x: 0, y: 10 },
            { x: 1, y: 10 },
          ],
        },
      },
    },

    // transistors
    {
      ...transistor_pnp,
      id: -12,
      state: {
        position: {
          cells: [
            { x: 0, y: 11 },
            { x: 1, y: 11 },
            { x: 2, y: 11 },
          ],
        },
      },
    },
    // {
    //   ...temp_sensor,
    //   id: -14,
    //   state: {
    //     position: {
    //       cells: [
    //         { x: 0, y: 13 },
    //         { x: 1, y: 13 },
    //         { x: 2, y: 13 },
    //       ],
    //     },
    //   },
    // },
    {
      ...transistor_photo,
      id: -15,
      state: {
        position: {
          cells: [
            { x: 0, y: 14 },
            { x: 1, y: 14 },
          ],
        },
      },
      props: {
        data_pos: [9],
      },
    },
    {
      ...transistor_npn,
      id: -209,
      state: {
        position: {
          cells: [
            { x: 2, y: 9 },
            { x: 3, y: 9 },
            { x: 4, y: 9 },
          ],
        },
      },
    },

    // capacitors
    // 100 muF
    {
      ...cap_100,
      id: -601,
      state: {
        position: {
          cells: [
            { x: 6, y: 1 },
            { x: 7, y: 1 },
          ],
        },
      },
    },
    {
      ...cap_100,
      id: -602,
      state: {
        position: {
          cells: [
            { x: 6, y: 2 },
            { x: 7, y: 2 },
          ],
        },
      },
    },

    // 1000 muF
    {
      ...cap_1k,
      id: -603,
      state: {
        position: {
          cells: [
            { x: 6, y: 3 },
            { x: 7, y: 3 },
          ],
        },
      },
    },
    {
      ...cap_1k,
      id: -604,
      state: {
        position: {
          cells: [
            { x: 6, y: 4 },
            { x: 7, y: 4 },
          ],
        },
      },
    },
    {
      ...transistor_npn,
      id: -500,
      state: {
        position: {
          cells: [
            { x: 5, y: 9 },
            { x: 6, y: 9 },
            { x: 7, y: 9 },
          ],
        },
      },
    },

    {
      ...ind100,
      id: -207,
      state: {
        position: {
          cells: [
            { x: 2, y: 7 },
            { x: 3, y: 7 },
          ],
        },
      },
    },

    // diode
    {
      ...diode,
      id: -404,
      state: {
        position: {
          cells: [
            { x: 4, y: 4 },
            { x: 5, y: 4 },
          ],
        },
      },
    },

    // DIGITAL INPUT UNITS (6)
    // 3 buttons
    {
      ...btn,
      id: -400,
      state: {
        position: {
          cells: [
            { x: 4, y: 1 },
            { x: 5, y: 1 },
          ],
        },
      },
      props: { data_pos: [5] },
    },
    {
      ...btn,
      id: -401,
      state: {
        position: {
          cells: [
            { x: 4, y: 2 },
            { x: 5, y: 2 },
          ],
        },
      },
      props: { data_pos: [0] },
    },
    {
      ...btn,
      id: -402,
      state: {
        position: {
          cells: [
            { x: 4, y: 3 },
            { x: 5, y: 3 },
          ],
        },
      },
      props: { data_pos: [1] },
    },

    // 2 switches
    {
      ...swtch,
      id: -204,
      state: {
        position: {
          cells: [
            { x: 2, y: 5 },
            { x: 3, y: 5 },
            { x: 4, y: 5 },
          ],
        },
      },
      props: { data_pos: [3] },
    },
    {
      ...swtch,
      id: -505,
      state: {
        position: {
          cells: [
            { x: 5, y: 5 },
            { x: 6, y: 5 },
            { x: 7, y: 5 },
          ],
        },
      },
      props: { data_pos: [4] },
    },

    {
      ...reed_switch,
      id: -605,
      state: {
        position: {
          cells: [
            { x: 6, y: 7 },
            { x: 7, y: 7 },
          ],
        },
      },
      props: {
        data_pos: [2],
      },
    },

    // PWM UNITS
    {
      ...led,
      id: -200,
      state: {
        position: {
          cells: [
            { x: 2, y: 1 },
            { x: 3, y: 1 },
          ],
        },
      },
      props: {
        data_pos: [5],
        color: LEDPlateColor.Red,
      },
    },

    {
      ...led,
      id: -201,
      state: {
        position: {
          cells: [
            { x: 2, y: 2 },
            { x: 3, y: 2 },
          ],
        },
      },
      props: {
        data_pos: [10],
        color: LEDPlateColor.Green,
      },
    },

    {
      ...led,
      id: -202,
      state: {
        position: {
          cells: [
            { x: 2, y: 3 },
            { x: 3, y: 3 },
          ],
        },
      },
      props: {
        data_pos: [12],
        color: LEDPlateColor.Blue,
      },
    },

    {
      ...led,
      id: -203,
      state: {
        position: {
          cells: [
            { x: 2, y: 4 },
            { x: 3, y: 4 },
          ],
        },
      },
      props: {
        data_pos: [9],
        color: LEDPlateColor.Red,
      },
    },

    {
      ...rgb,
      id: -13,
      state: {
        position: {
          cells: [
            { x: 0, y: 12 },
            { x: 1, y: 12 },
            { x: 2, y: 12 },
            { x: 3, y: 12 },
          ],
        },
      },
      props: {
        data_pos: [16, 15, 14],
      },
    },

    {
      ...indicator7,
      id: -301,
      state: {
        position: {
          cells: [
            { x: 3, y: 13 },
            { x: 4, y: 13 },
            { x: 5, y: 13 },
            { x: 6, y: 13 },
            { x: 7, y: 13 },
            { x: 3, y: 14 },
            { x: 4, y: 14 },
            { x: 5, y: 14 },
            { x: 6, y: 14 },
            { x: 7, y: 14 },
          ],
        },
      },
      props: {
        data_pos: [4, 3, 0, 1, 2, 6, 7, 11],
      },
    },

    // buzzer
    {
      ...buzzer,
      id: -205,
      state: {
        position: {
          cells: [
            { x: 2, y: 6 },
            { x: 3, y: 6 },
          ],
        },
      },
      props: {
        data_pos: [17],
      },
    },

    {
      ...photoresistor,
      id: -406,
      state: {
        position: {
          cells: [
            { x: 4, y: 7 },
            { x: 5, y: 7 },
          ],
        },
      },
      props: {
        data_pos: [6],
      },
    },

    {
      ...relay,
      id: -208,
      state: {
        position: {
          cells: [
            { x: 2, y: 8 },
            { x: 3, y: 8 },
            { x: 4, y: 8 },
            { x: 5, y: 8 },
            { x: 6, y: 8 },
          ],
        },
      },
    },

    {
      ...thermoresistor,
      id: -210,
      state: {
        position: {
          cells: [
            { x: 2, y: 10 },
            { x: 3, y: 10 },
          ],
        },
      },
      props: {
        data_pos: [8],
      },
    },

    {
      ...rheostat,
      id: -405,
      state: {
        position: {
          cells: [
            { x: 4, y: 6 },
            { x: 5, y: 6 },
            { x: 6, y: 6 },
          ],
        },
      },
      props: {
        data_pos: [7],
      },
    },
  ],

  // TODO: сделать универсальный формат
  // Y-координата верхней линии, где рисовать прямые токи
  curr_straight_top_y: 0,
  curr_straight_bottom_y: -1,
};

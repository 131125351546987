import Blockly from 'blockly';
import { BLOCK_INPUTS_CONSTRAINTS, DATATYPES } from '../constants';
import { getArguments } from '../_common';

const DEFAULT_PIN = BLOCK_INPUTS_CONSTRAINTS.PINS[0][1];

const BlocklyJSONGenerators = {
  arduino_pin: (block) => {
    return ['arduino_pin', Blockly.JSON.ORDER_ATOMIC];
  },

  arduino_pin_number: (block) => {
    const pin = block.getFieldValue('PIN') || DEFAULT_PIN;

    return [pin, Blockly.JSON.ORDER_ATOMIC];
  },

  arduino_out_value: (block) => {
    const pin = block.getFieldValue('PIN') || DEFAULT_PIN;

    return [pin, Blockly.JSON.ORDER_ATOMIC];
  },

  arduino_out_write_logical: (block) => {
    return (
      JSON.stringify({
        name: 'arduino_out_write_logical',
        block_id: block.id,
        args: getArguments(block, [
          {
            name: 'PIN',
            default: DEFAULT_PIN,
            datatype: DATATYPES.STRING,
            complex: true,
          },
          {
            name: 'LVL_LOG',
            default: 0,
            datatype: DATATYPES.NUMBER,
            complex: false,
          },
        ]),
      }) + ','
    );
  },

  arduino_out_read_logical: (block) => {
    return (
      JSON.stringify({
        name: 'arduino_out_read_logical',
        block_id: block.id,
        args: getArguments(block, [
          {
            name: 'PIN',
            default: DEFAULT_PIN,
            datatype: DATATYPES.STRING,
            complex: false,
          },
        ]),
      }) + ','
    );
  },

  arduino_out_write_pwm: (block) => {
    const args = getArguments(block, [
      {
        name: 'PIN',
        default: DEFAULT_PIN,
        datatype: DATATYPES.STRING,
        complex: false,
      },
      {
        name: 'LVL_PWM',
        default: 0,
        datatype: DATATYPES.STRING,
        complex: true,
      },
    ]);

    return (
      JSON.stringify({
        name: 'arduino_out_write_pwm',
        block_id: block.id,
        args: getArguments(block, [
          {
            name: 'PIN',
            default: DEFAULT_PIN,
            datatype: DATATYPES.STRING,
            complex: false,
          },
          {
            name: 'LVL_PWM',
            default: 0,
            datatype: DATATYPES.NUMBER,
            complex: true,
          },
        ]),
      }) + ','
    );
  },

  arduino_out_read_pwm: (block) => {
    return (
      JSON.stringify({
        name: 'arduino_out_read_pwm',
        block_id: block.id,
        args: getArguments(block, [
          {
            name: 'PIN',
            default: DEFAULT_PIN,
            datatype: DATATYPES.STRING,
            complex: false,
          },
        ]),
      }) + ','
    );
  },
};

export default BlocklyJSONGenerators;

// @ts-nocheck
import Model from '../base/model/Model';
import { type ILayoutMode, type ILayoutPane } from '../views/LayoutView';
import type DummyDatasource from '../base/model/datasources/DummyDatasource';
import { type PaneOrientation } from '../views/layout/Pane';
import { type WidgetInfo } from '../helpers/types';
import { ModelEvent } from '../base/Event';

const UNITS_ALLOWED = ['px', '%'];

/**
 * @category Core.Models
 */
interface Widget {
  alias: string;
  label: string;
}

/**
 * @category Core.Models
 */
interface Pane {
  name: string;
  title?: string;
  size_min?: string | number;
  size_max?: string | number;
  resizable?: boolean;
  fixed?: string | number;
  size?: string;
  widgets?: Widget[];
  panes?: Pane[];
  composer?: string;
}

/**
 * @category Core.Models
 */
interface LayoutMode {
  policy: string;
  panes: Pane[];
}

/**
 * @category Core.Models
 */
interface LayoutOptions {
  show_headers?: boolean;
}

/**
 * @category Core.Models
 */
interface LayoutModelState {
  modes: Record<string, LayoutMode>;
  current_mode: string;
  options?: LayoutOptions;
}

/**
 * @category Core.Models
 * @subcategory Events
 */
export class SetModeEvent extends ModelEvent<SetModeEvent> {
  mode: string;
}

/**
 * @category Core.Models
 * @subcategory Events
 */
// TODO: Remove
export class FinishModeEvent extends ModelEvent<FinishModeEvent> {
  mode: string;
}

/**
 * Модель разметки
 *
 * @property modes {} режимы разметки
 *
 * @category Core.Models
 */
export default class LayoutModel extends Model<
  LayoutModelState,
  DummyDatasource
> {
  static alias = 'layout';

  protected defaultState: LayoutModelState = {
    options: { show_headers: true },
    current_mode: 'default',
    modes: {},
  };

  getOptions(): LayoutOptions {
    return this.state.options;
  }

  setMode(mode: string) {
    if (mode in this.state.modes) {
      this.setState({ current_mode: mode });
      this.emit(new SetModeEvent({ mode }));
    } else {
      console.error(`Mode '${mode}' does not exist`);
    }
  }

  reportModeFinished(mode: string) {
    if (!(mode in this.state.modes)) { throw new Error(`Mode ${mode} does not exist`); }

    this.emit(new FinishModeEvent({ mode }));
  }

  /**
   * Выполнить предварительную обработку конфигурации разметки
   *
   * В даном случае выполняется проверка правильности составленного объекта
   */
  getModes(): Record<string, ILayoutMode> {
    const modes_formatted: Record<string, ILayoutMode> = {};

    for (const [k, mode] of Object.entries(this.state.modes)) {
      modes_formatted[k] = {
        policy: mode.policy as PaneOrientation,
        panes: [],
      };

      for (const [key, pane] of mode.panes.entries()) {
        modes_formatted[k].panes[key] = this.formatPane(pane);
      }
    }

    return modes_formatted;
  }

  /**
   * Обработать конфигурацию панели
   *
   * Метод применяется рекурсивно ко всем вложенным панелям.
   *
   * @param pane
   */
  formatPane(pane: Pane): ILayoutPane {
    let panes;

    // Выполнить перебор вложенных панелей (головная рекурсия)
    if (pane.panes) {
      panes = [];

      for (const [key, subpane] of pane.panes.entries()) {
        panes[key] = this.formatPane(subpane);
      }
    }

    const title = this.formatPaneTitle(pane);
    const size = this.formatPaneSize(pane);
    const { size_min, size_max, resizable } = this.formatPaneLimits(pane);
    const widgets = this.formatPaneViews(pane);
    const composer = pane.composer;

    return {
      name: pane.name,
      title,
      size,
      size_min,
      size_max,
      resizable,
      widgets,
      panes,
      composer,
    };
  }

  formatPaneTitle(pane: Pane): string {
    return pane.title || pane.name;
  }

  /**
   * Обработать единицы измерения, заданные в настройках панели
   *
   * Выполняется проверка корректности единиц измерения в полях, отвечающих за
   * размер панелей, а также приведение формата к однозначному виду.
   *
   * Основная задача - разобрать поле size таким образом, чтобы его можно было разложить
   * * на два поля в `ILayoutPane` - size и size_unit - для всех возможных вариантов значений поля size.
   *
   * По умолчанию, если единица измерения не указана, используется вариант `px`.
   * Если единица измерения указана, но её нет в списке доступных, метод выбрасывает исключение.
   *
   * @param pane
   */
  formatPaneSize(pane: Pane): string {
    let size, size_unit;

    /**
     * Панели с null-размером являются свободными (не имеющими начального размера).
     * Такие панели обрабатывать не нужно.
     */
    if (pane.size == null) { return null; }

    const matches = /^(\d+)(\D+)/gm.exec(pane.size);
    if (matches.length == 3) {
      if (!UNITS_ALLOWED.includes(matches[2])) {
        throw new Error(`Invalid size unit: ${matches[2]}`);
      }

      size = Number(matches[1]);
      size_unit = matches[2];
    }

    if (size == null) { size = Number(pane.size); }
    if (size_unit == null) { size_unit = 'px'; }

    if (Number.isNaN(size)) { size = null; }

    return size + size_unit;
  }

  formatPaneLimits(pane: Pane): {
    size_min: string;
    size_max: string;
    resizable: boolean;
  } {
    let resizable, size_min, size_max;

    size_min = pane.fixed ? pane.fixed : pane.size_min;
    size_max = pane.fixed ? pane.fixed : pane.size_max;

    size_min = this.formatSizeLimitValue(size_min);
    size_max = this.formatSizeLimitValue(size_max);

    if (pane.resizable == null) {
      resizable = true;
    } else {
      resizable = pane.resizable;
    }

    if (size_min == size_max && size_max != null) {
      resizable = false;
    }

    return { size_min, size_max, resizable };
  }

  formatPaneViews(pane: Pane): WidgetInfo[] {
    if (pane.widgets && pane.panes) {
      console.error(pane);
      throw new Error(
        `Only one of 'widgets' or 'panes' can be used for pane '${pane.name}'`,
      );
    }

    if (pane.widgets) {
      if (!Array.isArray(pane.widgets)) {
        throw new Error(`Invalid type of 'widgets' for pane '${pane.name}'`);
      }

      // TODO validate item types
    } else {
      return [];
    }

    return pane.widgets;
  }

  formatSizeLimitValue(value: string | number): string | null {
    if (value == null) { return null; }

    if (typeof value === 'string') {
      // if (value.slice(-2)) {
      //     value = value.slice(0, -2);
      // }
      //
      // value = Number(value);
    }

    if (typeof value === 'number') {
      value = `${value}px`;
    }

    return value;
  }
}

import { type Cell } from '../../core/Grid';

import { Plate, type PlateProps } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

type ResistorPlateProps = PlateProps & {
  resistance: number;
};

/**
 * Resistor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class ResistorPlate extends LinearPlate<ResistorPlateProps> {
  static get Alias() {
    return PLATE_ALIASES.RESISTOR;
  }

  protected get __inheritProps__() {
    return {
      resistance: 200,
    };
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  public get variant() {
    return `${this._shortLabel()} Ω`;
  }

  /**
   * @inheritdoc
   */
  protected __setProps__(props: ResistorPlateProps) {
    const resistance = Number(props.resistance);

    if (resistance <= 0) {
      throw RangeError('Resistance cannot be less than 0');
    }

    super.__setProps__({ ...props, resistance });
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();
    this._drawLabel();
  }

  /**
   * Draws a resistor image over the plate surface
   *
   * @param {number} qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect2.x() - rect1.x();

    this._group
      .polyline([
        [0, 0],
        [line_len, 0],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .fill('none')
      .move(rect1.cx(), rect2.cy());

    this._group
      .rect(line_len / 2.5, qs / 2)
      .stroke({ width: 1 })
      .addClass('bb-plate-fill-bg')
      .addClass('bb-plate-stroke')
      .cx(rect1.cx() + line_len / 2)
      .cy(rect1.cy());
  }

  /**
   * Draws label with the nominal value of the resistor
   *
   * @param size label font size
   */
  private _drawLabel(size = Plate.LabelFontSizePreferred) {
    this._group
      .text(this._shortLabel())
      .addClass('bb-plate-caption')
      .font({
        size,
      })
      .cx(this._container.width() / 2)
      .cy(this._container.height() / 4)
      .stroke({ width: 0.5 });
  }

  /**
   * @returns short designation of the resistor nominal
   */
  private _shortLabel() {
    if (this.props.resistance / 1000 >= 1) {
      return this.props.resistance / 1000 + ' kOhm';
    } else if (this.props.resistance / 1000000 >= 1) {
      return this.props.resistance / 1000000 + ' MOhm';
    } else {
      return `${this.props.resistance} Ohm`;
    }
  }
}

import { ModelEvent } from '~/js/core/base/Event';
import { type MissionData, type ValidationVerdict } from './types';

export class LessonPassEvent extends ModelEvent<LessonPassEvent> {}

export class MissionPassEvent extends ModelEvent<MissionPassEvent> {
  mission_idx: number;
  no_prompt?: boolean;
}

export class ExercisePassEvent extends ModelEvent<ExercisePassEvent> {
  mission_idx: number;
  exercise_idx: number;
  no_prompt?: boolean;
}

export class LessonRunEvent extends ModelEvent<LessonRunEvent> {}

export class ExerciseRunEvent extends ModelEvent<ExerciseRunEvent> {
  mission_idx: number;
  exercise_idx: number;
}

export class MissionRunEvent extends ModelEvent<MissionRunEvent> {
  mission_idx: number;
  data: MissionData;
}

export class ExerciseSolutionCommittedEvent extends ModelEvent<ExerciseSolutionCommittedEvent> {}

export class ExerciseSolutionValidatedEvent extends ModelEvent<ExerciseSolutionValidatedEvent> {
  error: string;
  verdict: ValidationVerdict;
}

import { PhotoresistorPlate } from '~/js/utils/breadboard/components/plates/PhotoresistorPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Thermoresistor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class ThermoresistorPlate extends PhotoresistorPlate {
  static get Alias() {
    return PLATE_ALIASES.THERMORESISTOR;
  }

  protected _getCaption(): string {
    return 'TH. RESISTOR';
  }
}

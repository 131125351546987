import type { LessonProgress } from '~/js/models/lesson/ProgressModel/types';

export function getLessonExercisesStats(lesson?: LessonProgress) {
  if (!lesson?.missions) {
    return { total: 0, passed: 0 };
  }

  return {
    total: lesson.missions.reduce((sum, m) => sum + m.exercises.length, 0),
    passed: lesson.missions.reduce(
      (sum, m) =>
        sum + m.exercises.reduce((sum, e) => sum + Number(e.is_passed), 0),
      0,
    ),
  };
}

export function getLessonMissionsStats(lesson?: LessonProgress) {
  if (!lesson?.missions) {
    return { total: 0, passed: 0 };
  }

  return {
    total: lesson.missions.length,
    passed: lesson.missions.filter((m) => m.is_passed).length,
  };
}

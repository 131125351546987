import AsynchronousModel, {
  listen,
} from '~/js/core/base/model/AsynchronousModel';
import {
  type BlocklyModelState,
  type CommandChain,
  type VariableDefinition,
  VariableSource,
  type VariableStateDataPackage,
} from './types';
import { type CodeChainset } from '~/js/models/common/CodeModel/types';
import { ChannelsCore, ChannelsUi } from '../../constants';
import {
  CodeCommandExecutedEvent,
  CodeLaunchedEvent,
  CodeTerminatedEvent,
  CodeVardefUpdateEvent,
  CodeVariableUpdateEvent,
} from './events';
import { AsyncDatasourceStatus } from '~/js/core/base/model/datasources/AsynchronousDatasource';
import { BUTTON_CODES_TO_KEYS, DEFAULT_VARDEFS } from './constants';
import { generateAnalogPinResetCommands } from '~/js/models/common/CodeModel/helpers';
import { type PinState } from '~/js/utils/breadboard/core/Layout';

export class CodeModel extends AsynchronousModel<BlocklyModelState> {
  static alias = 'code';

  protected launching: boolean | undefined = undefined;

  protected defaultState: BlocklyModelState = {
    chainset: undefined,
    vardefs: {
      [VariableSource.Default]: DEFAULT_VARDEFS,
    },
    variables: {},
  };

  public isMainChainEmpty() {
    const chainset = this.state.chainset;

    return !chainset?.main || chainset.main.commands.length === 0;
  }

  public setChainset(chainset: CodeChainset) {
    this.setState({ chainset });
  }

  public setVariableDefinitions(
    vardefs: VariableDefinition[],
    source: VariableSource,
  ) {
    this.setState({ vardefs: { ...this.state.vardefs, [source]: vardefs } });
    this.emit(
      new CodeVardefUpdateEvent({ vardefs: this.getVariableDefinitions() }),
    );
  }

  public getVariableDefinitions() {
    return Object.values(this.state.vardefs || {}).flat();
  }

  public getVariables() {
    const { variables } = this.getState();

    return (
      this.getVariableDefinitions().map((vardef) => {
        return {
          key: vardef.key,
          name: vardef.name,
          type: vardef.type,
          value:
            variables?.[vardef.key] != null
              ? variables?.[vardef.key]
              : vardef.initialValue,
        };
      }) || []
    );
  }

  public resetVariables() {
    const vars = this.state.variables || {};
    const vardefs = this.getVariableDefinitions();

    this.setState({
      variables: Object.entries(vars).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: vardefs.find((vd) => vd.key === key)?.initialValue || value,
        }),
        {},
      ),
    });

    this.emit(new CodeVariableUpdateEvent(this.state.variables));
  }

  public executeOnce(commands: any) {
    this.send(ChannelsUi.PRG_COMMANDS, {
      launch: true,
      pause: 0,
      commands,
    });

    this.launching = false;
  }

  public executeMainChain() {
    if (this.launching) {
      console.warn('Another program is launching now, skipping');
      return;
    }

    // update vardefs
    this.send(ChannelsUi.PRG_VARDEFS, this.getVariableDefinitions());

    // reset vars
    this.resetVariables();

    const chainset = this.getState().chainset;

    if (!(this.data_source.status === AsyncDatasourceStatus.Connected)) {
      this.emit(new CodeTerminatedEvent({}));
      return;
    }

    if (!chainset?.main || chainset.main.commands.length === 0) {
      this.emit(new CodeTerminatedEvent({}));
      return;
    }

    this.send(ChannelsUi.PRG_COMMANDS, {
      launch: true,
      pause: chainset.main.pause,
      commands: chainset.main.commands,
    });

    this.launching = false;
  }

  public executeButtonHandlerChain(key: string) {
    if (this.launching) {
      console.warn('Another program is launching now, skipping');
      return;
    }

    const chainset = this.getState().chainset;

    let chain: CommandChain | undefined;

    if (key == null) {
      return;
    }
    if (!chainset) {
      return;
    }

    for (const chain_ of Object.values(chainset)) {
      if (BUTTON_CODES_TO_KEYS[chain_.btn] === key) {
        chain = chain_;
        break;
      }
    }

    if (!chain || chain.commands.length === 0) {
      return;
    }

    this.send(ChannelsUi.PRG_COMMANDS, {
      launch: false,
      pause: chain.pause,
      commands: chain.commands,
    });

    this.launching = false;
  }

  public interruptMainChain() {
    this.send(ChannelsUi.PRG_STOP);
  }

  public executeAnalogPinResetChain(states: [number, number | PinState][]) {
    this.send(ChannelsUi.PRG_COMMANDS, {
      launch: true,
      pause: 0,
      commands: generateAnalogPinResetCommands(states),
    });
  }

  @listen(ChannelsCore.PRG_EXECUTE)
  protected onCommandExecuted(block_id: string) {
    this.emit(new CodeCommandExecutedEvent({ block_id }));

    if (!this.launching) {
      this.launching = true;
      this.emit(new CodeLaunchedEvent());
    }
  }

  @listen(ChannelsCore.PRG_TERMINATE)
  protected onCodeTerminated() {
    this.emit(new CodeTerminatedEvent());
    this.launching = undefined;
  }

  @listen(ChannelsCore.PRG_VARIABLE)
  protected onVariableChange(data: VariableStateDataPackage) {
    this.setState({
      variables: { ...this.state.variables, ...data },
    });

    this.emit(new CodeVariableUpdateEvent({ vars: data }));
  }
}

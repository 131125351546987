import type SVG from 'svg.js';

import { Layer, type LayerOptions } from '../../../core/Layer';
import {
  type AuxPoint,
  AuxPointCategory,
  AuxPointType,
  BorderType,
  Cell,
  type Grid,
} from '../../../core/Grid';

import { GRADIENTS } from '../../../styles/gradients';
import { DomainSchematicStyle } from './types';
import { minmaxfixCell } from '../../../core/Grid/helpers';
import { getSourceLinePath } from '../../../core/Layer/helpers';

import './styles.less';
import { FLOWER_SVG } from '~/js/utils/breadboard/components/layers/BackgroundLayer/constants';
import { BreadboardMode } from '~/js/utils/breadboard/Breadboard.types';

enum Classes {
  BgRect = 'bb-bg-rect',
}

/**
 * Contains background canvas and some visual details for the breadboard, such as:
 *  - mount points (cells) and their contact groups (domains)
 *  - voltage source element
 *  - usb contact lines
 *  - debug information (cursor positioning feedback)
 *
 * This layer has two styles: schematic (default) and photographic (which is obsolete).
 *
 * The _cell_ is a point at which a plate can be mounted. Each plate occupies at least one cell at the moment.
 * The _domain_ (or contact group) is the group of interconnected cells.
 *
 * Connected cells creates a contact so current can flow through it.
 * To connect cells from different groups, you need a {@link Plate} mounted on cells from each of the groups,
 * and this {@link Plate} should be able to pass current through itself (which depends on its type).
 * This is why it's needed to visually display the contact groups.
 *
 * @category Breadboard
 * @subcategory Layers
 */
export class BackgroundLayer extends Layer {
  /** CSS class of the layer */
  static Class = 'bb-layer-background';

  /** the radius of the cell point (which is virtually a circle) */
  static CellRadius = 5;

  /** an offset of domain lines relative to cell positions in schematic mode */
  static DomainSchematicBias = 20;

  /** layer's main SVG container */
  protected _container: SVG.Container;

  /** SVG group for the board background */
  private _boardgroup: SVG.Container;
  /** SVG group for domain lines */
  private _domaingroup: SVG.Container;
  /** SVG group for cells  */
  private _cellgroup: SVG.Container;
  /** SVG group for decorative elements (such as voltage source lines and poles) */
  private _decogroup: SVG.Container;
  /** SVG group for elements shown in DEFAULT mode */
  private _defaultgroup: SVG.Container;

  /** SVG cells (2D)  */
  private _gcells: any[];

  private _bg_visible: boolean;

  /**
   * @inheritdoc
   */
  constructor(container: SVG.Container, grid: Grid, options: LayerOptions) {
    super(container, grid, options);

    this._container.addClass(BackgroundLayer.Class);

    this._gcells = [];

    this._initGroups();
  }

  /**
   * Controls board background rectangle visibility
   *
   * The method is expected to call before the {@link __compose__} is called
   *
   * @param is_visible
   */
  public setBgVisible(is_visible = false) {
    this._bg_visible = is_visible;
  }

  protected _applyMode() {
    if (this.__mode === BreadboardMode.Default) {
      this._domaingroup.hide();
      this._cellgroup.hide();
      this._decogroup.hide();

      this._defaultgroup.show();
    } else {
      this._domaingroup.show();
      this._cellgroup.show();
      this._decogroup.show();

      this._defaultgroup.hide();
    }
  }

  /**
   * Draws contents for the layer
   */
  public __compose__() {
    const bgrect = this._boardgroup
      .rect()
      .addClass(Classes.BgRect)
      .width('99%')
      .height('99%') /// 99 из-за обрезания рамки
      .radius(20)
      .move(4, 4);

    if (!this._bg_visible) {
      bgrect.opacity(0);
    }

    this._drawAuxPoints();
    this._drawDomains();
    this._drawCells();
    this._drawDefault();
  }

  /**
   * @inheritdoc
   */
  public recompose(options: LayerOptions) {
    super.recompose(options);

    this._initGroups();
    this.compose();
  }

  /**
   * Initializes internal SVG groups
   *
   * Removes previously created groups and re-attaches event handlers
   */
  private _initGroups() {
    this._clearGroups();

    this._boardgroup = this._container.group();
    this._domaingroup = this._container.group();
    this._cellgroup = this._container.group();
    this._decogroup = this._container.group();
    this._defaultgroup = this._container.group();

    this._defaultgroup.hide();
  }

  /**
   * Removes SVG groups created previously with {@link _initGroups}
   */
  private _clearGroups() {
    this._boardgroup?.remove();
    this._domaingroup?.remove();
    this._cellgroup?.remove();
    this._decogroup?.remove();
    this._defaultgroup?.remove();
  }

  /**
   * Draws auxiliary points (special points which fall outside the regular grid)
   */
  private _drawAuxPoints() {
    this._drawAuxPointSource();
    this._drawAuxPointUsbs();
  }

  /** Draws cells (points of the regular grid) */
  private _drawCells() {
    this._gcells = [];

    for (const col of this.__grid.cells) {
      for (const cell of col) {
        this._drawCell(this._cellgroup, cell);
      }
    }
  }

  private _drawDefault() {
    this._drawEmptyText((add) => {
      add.tspan('The breadboard is not initialized.');
      add
        .tspan('Please connect to the core or enforce the virtual mode.')
        .newLine();
    });
  }

  private _drawEmptyText(text: (tspan: SVG.Tspan) => void) {
    const flower = this._defaultgroup.nested().addClass('bb-flower');

    flower.node.innerHTML = FLOWER_SVG;

    flower.size(40, 40).move('calc(50% - 30px)', 'calc(50% - 100px');

    this._defaultgroup.text(text).move('50%', '50%').addClass('bb-bg-default');
  }

  /**
   * Draws cell domains (contact lines which groups the cells into the "domains",
   * see {@link BackgroundLayer})
   */
  private _drawDomains() {
    if (!this.__grid.domains) {
      return;
    }

    for (const d of Object.values(this.__grid.domains)) {
      const { field, props } = d;
      const { src, dst } = field;

      const d_src = this.__grid.getCell(src.x, src.y, BorderType.Wrap).idx;
      const d_dst = this.__grid.getCell(dst.x, dst.y, BorderType.Wrap).idx;

      if (props?.style === DomainSchematicStyle.None) {
        continue;
      }

      this._drawDomain(
        this._domaingroup,
        this.__grid.getCell(d_src.x, d_src.y),
        this.__grid.getCell(d_dst.x, d_dst.y),
        this.__schematic ? '#777' : GRADIENTS.GOLD.HORZ,
        props?.style === DomainSchematicStyle.Dotted,
        !!props?.bias_inv,
        props?.line_after,
        props?.line_before,
      );
    }
  }

  /**
   * Draws separate contact line (also called "domain" here)
   *
   * In schematic mode, the contact line has an offset in order not to block
   * the contact cells themselves (which looks as the dots in this mode).
   * The line and cells are connected with the notches.
   *
   * You can prepend and append the line without the notches
   * by setting the `after` and `before` parameters to draw adjacent domains
   * with different styles continuously, which is needed for some of the board configurations.
   *
   * @see DomainDecl
   *
   * @param container   SVG parent element to render the content to
   * @param cell_src   Starting cell of the domain
   * @param cell_dst     End cell of the domain
   * @param color       Color of the domain
   * @param dotted      (schematic mode only) apply dotted style
   * @param inversed    (schematic mode only) invert line offset
   * @param after       (schematic mode only) append the line for N cells after
   * @param before      (schematic mode only) prepend the line for N cells before
   */
  private _drawDomain(
    container: SVG.Container,
    cell_src: Cell,
    cell_dst: Cell,
    color: string = '#D4AF37',
    dotted: boolean = false,
    inversed: boolean = false,
    after: number = 0,
    before: number = 0,
  ) {
    if (this.__schematic && typeof color !== 'string') {
      console.error('String color is not supported in schematic mode');
      return;
    }

    const [cell_min, cell_max] = minmaxfixCell(cell_src, cell_dst);
    const append = after !== 0 || before !== 0;

    if (this.__schematic) {
      const is_horizontal = Cell.IsLineHorizontal(cell_src, cell_dst);
      const is_vertical = Cell.IsLineVertical(cell_src, cell_dst);

      if (append) {
        let cell_min_add, cell_max_add;

        if (after !== 0) {
          cell_min_add = this.__grid.getCell(cell_dst.idx.x, cell_dst.idx.y);
          cell_max_add = this.__grid.getCell(
            cell_dst.idx.x + after * Number(is_horizontal),
            cell_dst.idx.y + after * Number(is_vertical),
          );
        }

        if (before !== 0) {
          cell_max_add = this.__grid.getCell(cell_src.idx.x, cell_src.idx.y);
          cell_min_add = this.__grid.getCell(
            cell_src.idx.x - before * Number(is_horizontal),
            cell_src.idx.y - before * Number(is_vertical),
          );
        }

        if (!cell_min_add || !cell_max_add) {
          throw new Error('cell_min(max)_add is undefined');
        }

        [cell_min_add, cell_max_add] = minmaxfixCell(
          cell_min_add,
          cell_max_add,
        );

        this._drawDomainLine(
          container,
          cell_min_add,
          cell_max_add,
          inversed,
          false,
          color,
          dotted,
        );
      }

      this._drawDomainLine(
        container,
        cell_min,
        cell_max,
        inversed,
        true,
        color,
        dotted,
      );
    } else {
      this._drawDomainRect(container, cell_src, cell_dst, color);
    }
  }

  /**
   * Draws separate cell (regular point of the {@link Grid}
   *
   * @param container SVG parent element to render the content to
   * @param cell related {@link Cell} of the grid
   */
  private _drawCell(container: SVG.Container, cell: Cell) {
    if (this._gcells[cell.idx.x] == null) {
      this._gcells[cell.idx.x] = [];
    }

    if (this.__schematic) {
      // in default schematic mode, show only dots in 0 row
      // in detailed schematic mode, show all dots
      if (cell.isAt(null, 0) || this.__detailed) {
        this._gcells[cell.idx.x][cell.idx.y] = container
          .circle(10)
          .center(cell.center.x, cell.center.y)
          .fill({ color: '#555' });
      }

      return;
    }

    // quad style
    this._gcells[cell.idx.x][cell.idx.y] = container
      .rect(cell.size.x, cell.size.y)
      .move(cell.pos.x, cell.pos.y)
      .fill({ color: '#D4AF37', opacity: 1 })
      .radius(BackgroundLayer.CellRadius);

    // [quad] lines
    container
      .path([
        ['M', 0, 0],
        ['M', (cell.size.x * 1) / 3, 0],
        ['l', 0, cell.size.y],
        ['M', (cell.size.x * 2) / 3, 0],
        ['l', 0, cell.size.y],
        ['M', 0, (cell.size.y * 1) / 3],
        ['l', cell.size.x, 0],
        ['M', 0, (cell.size.y * 2) / 3],
        ['l', cell.size.x, 0],
      ])
      .fill({ opacity: 0 })
      .stroke({ color: '#FFF', width: 2, opacity: 0.2 })
      .move(cell.pos.x, cell.pos.y);
  }

  /**
   * Draws separate domain as a line (intended to use in schematic mode)
   *
   * @see _drawDomain
   *
   * @param container     SVG parent element to render the content to
   * @param cell_src      Source cell of the line
   * @param cell_dst      Destination cell of the line
   * @param inversed      (schematic mode only) invert line offset
   * @param use_notches   Whether to draw notches to connect the cells with the line
   * @param color         Color of the line
   * @param dotted        (schematic mode only) apply dotted style
   */
  private _drawDomainLine(
    container: SVG.Container,
    cell_src: Cell,
    cell_dst: Cell,
    inversed: boolean,
    use_notches: boolean,
    color: string,
    dotted: boolean,
  ) {
    const is_horizontal = Cell.IsLineHorizontal(cell_src, cell_dst);
    const is_vertical = Cell.IsLineVertical(cell_src, cell_dst);

    let len_x = Math.abs(cell_src.pos.x - cell_dst.pos.x);
    let len_y = Math.abs(cell_src.pos.y - cell_dst.pos.y);

    let bias_x = 0;
    let bias_y = 0;

    len_x = len_x >= len_y ? len_x : 0;
    len_y = len_x < len_y ? len_y : 0;

    const bias_cont_x = 0;
    const bias_cont_y = 0;

    if (this.__detailed) {
      // add notches if required
      if (use_notches) {
        this._drawDomainLineNotches(
          container,
          cell_src,
          cell_dst,
          inversed,
          color,
        );
      }

      bias_x = is_horizontal ? 0 : BackgroundLayer.DomainSchematicBias;
      bias_y = is_vertical ? 0 : BackgroundLayer.DomainSchematicBias;

      if (inversed) {
        bias_x *= -1;
        bias_y *= -1;
      }
    }

    container
      .line(0, 0, len_x + bias_cont_x, len_y + bias_cont_y)
      .stroke({
        color,
        width: 6,
        linecap: 'round',
        dasharray: dotted ? '16' : '',
      })
      .move(
        cell_src.center.x + bias_x - bias_cont_x,
        cell_src.center.y + bias_y - bias_cont_y,
      )
      .opacity(0.5);
  }

  /**
   * Draws notches connecting cells and a line in the domain (intended to use in schematic mode)
   *
   * @see _drawDomainLine
   *
   * @param container SVG parent element to render the content to
   * @param cell_src  Starting cell of the line
   * @param cell_dst  End cell of the line
   * @param color     Notch color
   */
  private _drawDomainLineNotches(
    container: SVG.Container,
    cell_src: Cell,
    cell_dst: Cell,
    inverted: boolean,
    color: string,
  ) {
    const is_horizontal = Cell.IsLineHorizontal(cell_src, cell_dst);
    const is_vertical = Cell.IsLineVertical(cell_src, cell_dst);

    let pos_src = is_horizontal ? cell_src.idx.x : cell_src.idx.y;
    let pos_dst = is_horizontal ? cell_dst.idx.x : cell_dst.idx.y;

    // swap
    if (pos_src > pos_dst) {
      [pos_dst, pos_src] = [pos_src, pos_dst];
    }

    for (let pos = pos_src; pos <= pos_dst; pos++) {
      const cell = is_horizontal
        ? this.__grid.getCell(pos, cell_src.idx.y)
        : this.__grid.getCell(cell_src.idx.x, pos);

      const bias_x = is_horizontal ? 0 : BackgroundLayer.DomainSchematicBias;
      const bias_y = is_vertical ? 0 : BackgroundLayer.DomainSchematicBias;

      const corr_x = inverted ? -bias_x : 0;
      const corr_y = inverted ? -bias_y : 0;

      container
        .line(0, 0, bias_x, bias_y)
        .stroke({ color, width: 6, linecap: 'round' })
        .move(cell.center.x + corr_x, cell.center.y + corr_y)
        .opacity(0.5);
    }
  }

  /**
   * Draws separate domain as a rectangle (intended to use in non-schematic mode)
   *
   * Note: non-schematic mode is now obsolete as the board faceplate does not differ from
   * schematic representation. It's still needed to keep this for older board versions though.
   *
   * @see _drawDomain
   *
   * @param container SVG parent element to render the content to
   * @param cell_src  Starting cell of the line
   * @param cell_dst  End cell of the line
   * @param color     Rectangle color
   */
  private _drawDomainRect(
    container: SVG.Container,
    cell_src: Cell,
    cell_dst: Cell,
    color: string,
  ) {
    const width = Math.abs(cell_src.pos.x - cell_dst.pos.x);
    const height = Math.abs(cell_src.pos.y - cell_dst.pos.y);

    container
      .rect(width + cell_src.size.x, height + cell_src.size.y)
      .fill({ color })
      .stroke({ color })
      .move(cell_src.pos.x, cell_src.pos.y)
      .radius(10);
  }

  /**
   * Draws voltage source element (as a group of auxiliary points of the {@link Grid}
   * if required in the domain config specified in {@link setDomainConfig})
   *
   * @see AuxPointCategory
   * @see _drawAuxPoints
   */
  private _drawAuxPointSource() {
    if (
      !this.__grid.isAuxPointCatRequired(AuxPointCategory.SourceV5) &&
      !this.__grid.isAuxPointCatRequired(AuxPointCategory.SourceV8)
    ) {
      return;
    }

    const rise = 40;

    const p_gnd = this.__grid.auxPoint(AuxPointType.Gnd);
    const p_vcc = this.__grid.auxPoint(AuxPointType.Vcc);

    // Top/bottom bias (detailed schematic view only)
    const bias =
      this.__schematic && this.__detailed
        ? BackgroundLayer.DomainSchematicBias
        : 0;

    const [path_gnd, path_vcc] = getSourceLinePath(p_gnd, p_vcc, bias);
    const pos_gnd = { x: path_gnd[0][1], y: path_gnd[0][2] };
    const pos_vcc = { x: path_vcc[0][1], y: path_vcc[0][2] };

    // Voltage source line, actually
    const el_gnd = this._decogroup.path(path_gnd);
    const el_vcc = this._decogroup.path(path_vcc);

    for (const el of [el_gnd, el_vcc]) {
      el.fill({ color: 'none' })
        .stroke({ color: '#777', width: 6, linecap: 'round' })
        .opacity(0.5);
    }

    this._decogroup
      .line(0, 0, rise * 2.5, 0)
      .center(pos_vcc.x, pos_vcc.y)
      .stroke({ color: '#f00', width: 6, opacity: 1, linecap: 'round' });

    this._decogroup
      .line(0, 0, rise * 1.5, 0)
      .center(pos_gnd.x, pos_gnd.y)
      .stroke({ color: '#00f', width: 6, opacity: 1, linecap: 'round' });

    const cap_size = 42;
    const cap_pos_x = pos_vcc.x - rise * 1.25;

    // Pole caption 1
    this._decogroup
      .text('+')
      .fill({ color: '#f00' })
      .font({
        size: cap_size,
        family: "'Lucida Console', Monaco, monospace",
        weight: 'bold',
      })
      .center(cap_pos_x, pos_vcc.y - cap_size / 2);

    // Pole caption 2
    this._decogroup
      .text('-')
      .fill({ color: '#00f' })
      .font({
        size: cap_size,
        family: "'Lucida Console', Monaco, monospace",
        weight: 'bold',
      })
      .center(cap_pos_x, pos_gnd.y + cap_size / 2);

    // } catch (re) {
    //     console.error("Invalid reference cells has been selected to draw voltage source line");
    // }
  }

  /**
   * Draws usb ports (as a groups of auxiliary points of the {@link Grid}
   * if required in the domain config specified in {@link setDomainConfig})
   *
   * @see AuxPointCategory
   * @see _drawAuxPoints
   */
  private _drawAuxPointUsbs() {
    if (this.__grid.isAuxPointCatRequired(AuxPointCategory.Usb1)) {
      this._drawAuxPointUsb(
        this.__grid.auxPoint(AuxPointType.U1Vcc),
        this.__grid.auxPoint(AuxPointType.U1Gnd),
        this.__grid.auxPoint(AuxPointType.U1Analog1),
        this.__grid.auxPoint(AuxPointType.U1Analog2),
      );
    }

    if (this.__grid.isAuxPointCatRequired(AuxPointCategory.Usb3)) {
      this._drawAuxPointUsb(
        this.__grid.auxPoint(AuxPointType.U3Vcc),
        this.__grid.auxPoint(AuxPointType.U3Gnd),
        this.__grid.auxPoint(AuxPointType.U3Analog1),
        this.__grid.auxPoint(AuxPointType.U3Analog2),
      );
    }
  }

  /**
   * Draws specific USB port from its {@link AuxPointCategory}
   *
   * @see _drawAuxPointUsbs
   *
   * @param p_vcc an auxiliary point for VCC contact of the USB port
   * @param p_gnd an auxiliary point for gnD contact of the USB port
   * @param p_an1 an auxiliary point for Analog1 contact of the USB port
   * @param p_an2 an auxiliary point for Analog2 contact of the USB port
   */
  private _drawAuxPointUsb(
    p_vcc: AuxPoint,
    p_gnd: AuxPoint,
    p_an1: AuxPoint,
    p_an2: AuxPoint,
  ) {
    this._drawAuxPointUsbPath(p_vcc, BackgroundLayer.DomainSchematicBias);
    this._drawAuxPointUsbPath(p_gnd, BackgroundLayer.DomainSchematicBias);
    this._drawAuxPointUsbPath(p_an1);
    this._drawAuxPointUsbPath(p_an2);
  }

  /**
   * Draws the contact line for the specific contact of USB port
   *
   * @see _drawAuxPointUsb
   *
   * @param point         an auxiliary point for the contact
   * @param bias_domain   an offset of the domain lines relative to cell positions in schematic mode
   */
  private _drawAuxPointUsbPath(point: AuxPoint, bias_domain: number = 0) {
    const needs_bias = this.__schematic && this.__detailed;
    bias_domain = bias_domain * Number(needs_bias);

    const cell_x = needs_bias
      ? point.cell.center.x
      : point.cell.pos.x + point.cell.size.x;

    try {
      this._decogroup
        .path([
          ['M', point.pos.x, point.pos.y],
          ['l', -(point.bias || 0), 0],
          ['l', 0, point.cell.center.y - point.pos.y],
          ['l', cell_x - point.pos.x + (point?.bias || 0) + bias_domain, 0],
        ])
        .fill({ color: 'none' })
        .stroke({ color: '#777', width: 6, linecap: 'round' })
        .opacity(0.5);
    } catch (re) {
      console.error(
        'Invalid reference cells has been selected to draw voltage source line',
      );
    }
  }
}

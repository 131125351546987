import * as React from 'react';
import { type AllProps, type IViewProps, View } from '~/js/core/base/view/View';
import classNames from 'classnames';
import { VariableType } from '~/js/models/common/CodeModel/types';
import i18next from 'i18next';

require('../../../css/blocks/variables.less');

export namespace VariableView {
  export interface Variable {
    key: string;
    name: string;
    type: string;
    value?: string | number;
  }

  export interface Props extends IViewProps {
    variables: Variable[];
  }

  export class VariableView extends View<Props> {
    constructor(props: AllProps<Props>) {
      super(props);
    }

    render(): React.ReactNode {
      return (
        <div className="variables">
          {this.props.variables.map((variable) => {
            const klasses = classNames({
              variable: true,
              variable_cat_default: true,
              [`variable_${variable.type}`]: true,
            });

            if (variable.type === VariableType.Arduino) {
              return null;
            }

            const value =
              variable.type == 'colour' ? '#' + variable.value : variable.value;

            return (
              <div key={variable.key} className={klasses}>
                <div className="variable__name">
                  {i18next.t(variable.name, variable.name)}
                </div>
                <div className="variable__value">{`${value}`}</div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default VariableView;

import Datasource from '../Datasource';

/**
 * An implementation of synchronous data exchange protocol
 *
 * Synchronous protocols are usually represent the series of actions,
 * i.e. makes request-response or read-write sequences.
 *
 * @category Core
 * @subcategory Model
 */
export default abstract class SynchronousDatasource extends Datasource {}

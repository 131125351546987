import type SVG from 'svg.js';

import { type Cell } from '../../core/Grid';
import {
  Plate,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { SwitchPlateContextMenu } from '../menus/plate';
import { PLATE_ALIASES } from './constants';

type ButtonPlateState = PlateState & {
  field: boolean;
};

/**
 * Button plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class ButtonPlate extends LinearPlate<PlateProps, ButtonPlateState> {
  static get Alias() {
    return PLATE_ALIASES.BUTTON;
  }

  private _rect1: SVG.Rect;
  private _rect2: SVG.Rect;
  private _jumper: SVG.Line;
  private readonly _svginp: any;
  private readonly _svginpbg: SVG.Rect;

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {
      field: true,
    };
  }

  /**
   * @protected
   */
  protected get __ctxmenu__() {
    return SwitchPlateContextMenu;
  }

  public handleKeyPress(key_code: string, keydown: boolean) {
    super.handleKeyPress(key_code, keydown);

    if (key_code === 'KeyQ' && keydown) {
      this.setState({ field: !this.state.field });
    }
  }

  /**
   * @inheritdoc
   */
  public setState(
    state: Partial<SerializedPlateState<ButtonPlateState>>,
    suppress_events: boolean = false,
  ) {
    if (state.field == undefined) {
      return;
    }

    state = { field: !!state.field };

    super.setState(state, suppress_events);

    this._toggleJumper();

    if (this.options.verbose) {
      this._redrawInput(state.field);
    }
  }

  /**
   * @inheritdoc
   */
  public inputIncrement() {
    this.setState({ field: !this.state.field });
  }

  /**
   * @inheritdoc
   */
  public inputDecrement() {
    this.inputIncrement();
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    if (this.options.verbose) {
      this._redrawInput(this.state.field);
    }

    this._toggleJumper(true);
  }

  /**
   * Updates the jumper position depending on the state
   */
  protected _toggleJumper(force = false) {
    if (!this._is_drawn && !force) {
      return;
    }

    const line_len = this._rect2.x() - this._rect1.x();
    const line_gap = line_len / 6;

    if (this.state.field) {
      this._jumper.rotate(
        -25,
        this._rect1.cx() + line_len / 2 - line_gap,
        this._rect1.cy(),
      );
    } else {
      this._jumper.rotate(
        0,
        this._rect1.cx() + line_len / 2 - line_gap,
        this._rect1.cy(),
      );
    }
  }

  /**
   * Draws a button over the plate surface
   *
   * @param qs size of squares
   */
  protected _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    this._rect1 = this._group.rect(qs, qs).addClass('bb-plate-fill');
    this._rect2 = this._group.rect(qs, qs).addClass('bb-plate-fill');

    this._rect1.center(cell1.center_rel.x, cell1.center_rel.y);
    this._rect2.center(cell2.center_rel.x, cell2.center_rel.y);

    const line_len = this._rect2.x() - this._rect1.x();
    const line_gap = line_len / 6;

    this._group
      .path([
        ['M', 0, 0],
        ['l', line_len / 2 - line_gap, 0],
        ['m', line_gap * 2, 0],
        ['l', line_len / 2 - line_gap, 0],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 3 })
      .fill('none')
      .move(this._rect1.cx(), this._rect1.cy());

    this._jumper = this._group
      .line(0, 0, line_gap * 2, 0)
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .move(this._rect1.cx() + line_len / 2 - line_gap, this._rect1.cy());

    this._group
      .addClass('bb-plate-fill')
      .circle(this._rect1.width() / 3)
      .center(this._rect1.cx() + line_len / 2 - line_gap, this._rect1.cy());
    this._group
      .addClass('bb-plate-fill')
      .circle(this._rect1.width() / 3)
      .center(this._rect1.cx() + line_len / 2 + line_gap, this._rect1.cy());

    this._toggleJumper();
  }
}

import { type XYPoint, type XYRange } from '~/js/utils/breadboard/types';

export const isRangeConnected = (a: XYRange, b: XYRange) => {
  return (
    isSamePoint(a.src, b.src) ||
    isSamePoint(a.dst, b.dst) ||
    isSamePoint(a.src, b.dst) ||
    isSamePoint(a.dst, b.src)
  );
};

export const isSameRange = (a: XYRange, b: XYRange) => {
  return (
    (isSamePoint(a.src, b.src) && isSamePoint(a.dst, b.dst)) ||
    (isSamePoint(a.src, b.dst) && isSamePoint(a.dst, b.src))
  );
};

export const isSamePoint = (a: XYPoint, b: XYPoint) => {
  if (!a || !b) { return false; }

  return a.x === b.x && a.y === b.y;
};

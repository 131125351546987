import Blockly from 'blockly';
import { DATATYPES, VAR_NONE } from '../constants';
import { getArguments } from '../_common';

const VarJSONGenerators = {
  set_var: (block) => {
    return (
      JSON.stringify({
        name: 'var_out_write',
        block_id: block.id,
        args: getArguments(block, [
          {
            name: 'VAR',
            default: VAR_NONE,
            datatype: DATATYPES.STRING,
            complex: true,
          },
          {
            name: 'VAL',
            default: 0,
            datatype: DATATYPES.NUMBER,
            complex: true,
          },
        ]),
      }) + ','
    );
  },

  get_var: (block) => {
    const var_name = block.getFieldValue('VAR') || 'var-default';

    return [var_name, Blockly.JSON.ORDER_ATOMIC];
  },
};

export default VarJSONGenerators;

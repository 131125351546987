import { ModelEvent } from '~/js/core/base/Event';
import { type VariableDefinition } from '~/js/models/common/CodeModel/types';

export class CodeLaunchedEvent extends ModelEvent<CodeLaunchedEvent> {}

export class CodeTerminatedEvent extends ModelEvent<CodeTerminatedEvent> {}

export class CodeCommandExecutedEvent extends ModelEvent<CodeCommandExecutedEvent> {
  block_id: string;
}

export class CodeVariableUpdateEvent extends ModelEvent<CodeVariableUpdateEvent> {
  vars: Record<string, string>;
}

export class CodeVardefUpdateEvent extends ModelEvent<CodeVardefUpdateEvent> {
  vardefs: VariableDefinition[];
}

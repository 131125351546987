export enum ColorAccent {
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum ToastPosition {
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  TopLeft = 'top-left',
  TopRight = 'top-right',
}

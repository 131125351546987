import { on, restore } from '~/js/core/base/Presenter';

import i18next from 'i18next';
import ModalPresenter from '~/js/core/presenters/ModalPresenter';
import { LessonModel } from '~/js/models/lesson/LessonModel';
import { ProgressModel } from '~/js/models/lesson/ProgressModel';
import { ExerciseRunEvent } from '~/js/models/lesson/ProgressModel/events';

export default class PopoverLessonPresenter extends ModalPresenter {
  private lesson: LessonModel;
  private progress: ProgressModel;

  getInitialProps(): any {
    this.lesson = this.getModel(LessonModel);
    this.progress = this.getModel(ProgressModel);

    return super.getInitialProps();
  }

  @restore()
  @on(ExerciseRunEvent)
  private async showIntroModal() {
    const [mission_idx, exercise_idx] = this.progress.getOpenedExerciseIndex();
    const exercise = this.lesson.getExercise(mission_idx, exercise_idx);

    if (!exercise) {
      return;
    }

    for (const [i, popover] of exercise.popovers.entries()) {
      const go_forward = await this.showPopoverModal(
        popover.title ||
          i18next.t('main:lesson.modal.popover.title', {
            num: exercise_idx + 1,
          }),
        popover.content,
      );

      if (!go_forward) {
        break;
      }
    }
  }

  private async showPopoverModal(
    title: string,
    content: string,
  ): Promise<boolean> {
    this.lesson.setPopoverContent(content);

    return await new Promise((resolve) => {
      this.pushModal(
        {
          is_closable: true,
          widget_alias: 'popover_content',
          dialog: {
            heading: title,
            label_accept: i18next.t('main:lesson.modal.popover.accept'),
            is_acceptable: true,
            on_action: () => {
              resolve(true);
            },
          },
        },
        'popover',
      );
    });
  }

  protected updateModals() {}
}

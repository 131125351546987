import { ThemeMode } from '~/js/utils/breadboard/components/layers/ControlsLayer/types';
import { BreadboardMode } from '../../../Breadboard.types';

export const THEME_MODE_TO_TEXT = {
  [ThemeMode.System]: 'SYS',
  [ThemeMode.Light]: 'LGT',
  [ThemeMode.Dark]: 'DRK',
};

export const BOARD_MODE_TO_TEXT = {
  [BreadboardMode.Default]: 'DEFA',
  [BreadboardMode.Virtual]: 'VIRT',
  [BreadboardMode.Display]: 'DISP',
  [BreadboardMode.Real]: 'REAL',
};

import { CATEGORIES, FIELDTYPES } from '../constants';
import { appendShadowBlock } from '../_common';
import { getVars } from '../globals';
import Blockly from 'blockly';
import i18next from 'i18next';

Blockly.Extensions.register('dynamic_menu_extension', function () {
  this.getInput('VAR_TMP').appendField(
    new Blockly.FieldDropdown(() => {
      return getVars()
        .filter((v) => v)
        .map((v) => [v.name, v.key]);
    }),
    'VAR',
  );
});

const JSONBlocks = {
  set_var: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: i18next.t('blockly:blocks.set_var.message'),
        args0: [
          {
            type: 'input_dummy',
            name: 'VAR_TMP',
          },
          {
            type: 'input_value',
            name: 'VAL',
            check: FIELDTYPES.NUMBER,
          },
        ],
        extensions: ['dynamic_menu_extension'],
        previousStatement: null,
        nextStatement: null,
        inputsInline: true,
        colour: CATEGORIES.VAR.colour,
        tooltip: i18next.t('blockly:blocks.set_var.tooltip'),
      });
      appendShadowBlock(this, 'VAL', 'math_number');
    },
  },

  get_var: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: i18next.t('blockly:blocks.get_var.message'),
        args0: [
          {
            type: 'input_dummy',
            name: 'VAR_TMP',
          },
        ],
        extensions: ['dynamic_menu_extension'],
        inputsInline: false,
        colour: CATEGORIES.VAR.colour,
        output: FIELDTYPES.NUMBER,
        tooltip: i18next.t('blockly:blocks.get_var.tooltip'),
      });
    },
  },
};
export default JSONBlocks;

// Переопределение сторонних генераторов
import Blockly from 'blockly';
import { getArgument } from '../_common';
import { DATATYPES, POSTFIXES } from '../constants';

const BlocklyJSONGenerators = {
  controls_wait_seconds: (block) => {
    return (
      JSON.stringify({
        name: 'controls_wait_seconds',
        block_id: block.id,
        args: [getArgument(block, 'SECS', 1, DATATYPES.NUMBER, true)],
      }) + ','
    );
  },

  controls_repeat_ext: (block) => {
    const branch = Blockly.JSON.statementToCode(block, 'DO', false);

    const head = JSON.stringify({
      name: 'controls_repeat_ext',
      block_id: block.id,
      args: [getArgument(block, 'TIMES', 1, DATATYPES.NUMBER, true)],
    });

    const tail = JSON.stringify({
      name: 'controls_repeat_ext' + '.' + POSTFIXES.END,
      block_id: block.id,
      args: [],
    });

    return `${head},${branch}${tail},`;
  },

  controls_while: (block) => {
    const branch = Blockly.JSON.statementToCode(block, 'DO', false);

    const head = JSON.stringify({
      name: 'controls_while',
      block_id: block.id,
      args: [getArgument(block, 'BOOL', 1, DATATYPES.EXPRSN, true)],
    });

    const tail = JSON.stringify({
      name: 'controls_while' + '.' + POSTFIXES.END,
      block_id: block.id,
      args: [],
    });

    return `${head},${branch}${tail},`;
  },

  controls_while_forever: (block) => {
    const branch = Blockly.JSON.statementToCode(block, 'DO', false);

    const head = JSON.stringify({
      name: 'controls_while',
      block_id: block.id,
      args: [{ type: DATATYPES.EXPRSN, value: 1 }],
    });

    const tail = JSON.stringify({
      name: 'controls_while' + '.' + POSTFIXES.END,
      block_id: block.id,
      args: [],
    });

    return `${head},${branch}${tail},`;
  },
};

export default BlocklyJSONGenerators;

import { type ModelState } from '~/js/core/base/model/Model';

export interface CommandChain {
  commands: string[];
  btn: number; // TODO: Use key (string) instead of code
  pause: number;
}

export interface CodeChainset {
  [key: string]: CommandChain;

  main: CommandChain;
}

export enum VariableType {
  Int = 'int',
  Float = 'float',
  String = 'string',
  Arduino = 'arduino',
}

export interface IntValidation {
  min?: number;
  max?: number;
}

export interface FloatValidation extends IntValidation {
  precision?: number;
}

export enum VariableSource {
  Default = 'default',
  Arduino = 'arduino',
  Lesson = 'lesson',
}

export type VariableDefinition = {
  key: string;
  name: string;
  initialValue: number | string;
  type: VariableType;
} & (
  | {
      type: VariableType.Int;
      initialValue: number;
      validation?: IntValidation;
    }
  | {
      type: VariableType.Float;
      initialValue: number;
      validation?: FloatValidation;
    }
  | {
      type: VariableType.String;
      initialValue: string;
    }
);

export interface BlocklyModelState extends ModelState {
  chainset?: CodeChainset;
  variables?: Record<string, number | string>;
  vardefs?: Partial<Record<VariableSource, VariableDefinition[]>>;
}

export type VariableStateDataPackage = Record<string, string>;

import Presenter, { on, restore } from '../../core/base/Presenter';
import BoardView from '../../views/common/BoardView';
import SettingsModel, {
  SettingsChangeEvent,
} from '../../core/models/SettingsModel';
import ModalModel from '../../core/models/ModalModel';

import i18next from 'i18next';
import { type CoreMode } from '~/js/models/constants';
import { MAP_MODE_CORE_BB } from '~/js/presenters/common/BoardPresenter';
import { BoardModel } from '~/js/models/common/BoardModel';
import { ColorAccent } from '~/js/core/helpers/styles';
import { AlertType } from '~/js/core/views/AlertView';
import { BreadboardMode } from '~/js/utils/breadboard/Breadboard.types';

export default class BoardLessonPresenter extends Presenter<BoardView.BoardView> {
  private board: BoardModel;
  private modal: ModalModel;
  private settings: SettingsModel;
  private sctoast: number;

  public getInitialProps() {
    this.modal = this.getModel(ModalModel);
    this.board = this.getModel(BoardModel);
    this.settings = this.getModel(SettingsModel);

    const { mode, layout_name } = this.board.getState();

    const is_demo = this.settings.getBoolean('general.is_demo');

    return {
      layout_name,
      mode: is_demo
        ? BreadboardMode.Virtual
        : this.mapCoreModeToBoardMode(mode),
      verbose: this.settings.getBoolean('board.is_verbose'),
    };
  }

  @restore()
  @on(SettingsChangeEvent)
  private updateSettingsChange() {
    const is_demo = this.settings.getBoolean('general.is_demo', true);

    if (!is_demo) {
      this.board.disableForceLayout();
    }

    this.board.setDemo(this.settings.getBoolean('general.is_demo', true));
    this.view.setVerbose(this.settings.getBoolean('board.is_verbose', true));
  }

  @on(BoardView.ShortCircuitStartEvent)
  private showShortCircuitAlert() {
    const readonly = !(
      this.settings.getValue('general.is_demo') ||
      !this.board.getState().readonly
    );

    this.shortCircuitEndAlert();

    if (readonly) {
      this.modal.showAlert(AlertType.ShortCircuit);
    } else {
      this.sctoast = this.modal.showToast({
        title: i18next.t('main:alert.short_circuit.title'),
        content: i18next.t('main:alert.short_circuit.content'),
        status: ColorAccent.Danger,
      });
    }
  }

  @on(BoardView.ShortCircuitEndEvent)
  private shortCircuitEndAlert() {
    if (this.sctoast != null) {
      this.modal.hideToast(this.sctoast);
    } else {
      this.modal.hideAlert(AlertType.ShortCircuit);
    }
  }

  private mapCoreModeToBoardMode(mode: CoreMode) {
    return MAP_MODE_CORE_BB[mode] || BreadboardMode.Default;
  }
}

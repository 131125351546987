import {
  type VariableDefinition,
  VariableType,
} from '~/js/models/common/CodeModel/types';

export const DEFAULT_VARDEFS: VariableDefinition[] = [
  {
    key: 'variable_1',
    name: 'blockly:variables.var1',
    type: VariableType.Int,
    initialValue: 0,
  },
  {
    key: 'variable_2',
    name: 'blockly:variables.var2',
    type: VariableType.Int,
    initialValue: 0,
  },
];
export const BUTTON_CODES_TO_KEYS: Record<number, string> = {
  48: '0',
  81: 'q',
  65: 'a',
  38: 'ArrowUp',
  49: '1',
  87: 'w',
  83: 's',
  40: 'ArrowDown',
  50: '2',
  69: 'e',
  68: 'd',
  37: 'ArrowLeft',
  51: '3',
  82: 'r',
  70: 'f',
  39: 'ArrowRight',
  52: '4',
  84: 't',
  71: 'g',
  53: '5',
  89: 'y',
  72: 'h',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
};

import { type Cell } from '../../core/Grid';

import { Plate } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Diode plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class DiodePlate extends LinearPlate {
  static get Alias() {
    return PLATE_ALIASES.DIODE;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * Нарисовать диод
   *
   * @param {Cell}   position     положение светодиода
   * @param {string}  orientation ориентация светодиода
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();
  }

  /**
   * Draws a diode on the plate surface
   *
   * @param {number} qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect2.x() - rect1.x();

    this._group
      .polyline([
        [0, 0],
        [line_len, 0],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .fill('none')
      .move(rect1.cx(), rect2.cy());

    const ptrpath = [
      [0, 0],
      [qs / 2, -qs / 2],
      [qs / 2 - 7, -qs / 2 + 4],
      [qs / 2 - 4, -qs / 2 + 7],
      [qs / 2, -qs / 2],
    ];

    const trng = this._group
      .polyline([
        [0, 0],
        [0, (qs * 3) / 2],
        [qs, (qs * 3) / 4],
        [0, 0],
      ])
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .cx(rect1.cx() + line_len / 2)
      .cy(rect1.cy());

    const line = this._group
      .line([
        [0, 0],
        [0, (qs * 3) / 2],
      ])
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .cx(rect1.cx() + line_len / 2 + trng.width() / 2)
      .cy(rect2.cy());
  }
}

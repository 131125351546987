/**
 * Type of context menu item input
 *
 * @category Breadboard
 */
export enum ContextMenuItemInputType {
  String = 'string',
  Number = 'number',
  File = 'file',
}

/**
 * Properties of context menu item input
 *
 * If needed, `min` and `max` might be set for {@link ContextMenuItemInputType.Number} items.
 *
 * @category Breadboard
 */
export interface ContextMenuItemInputProps {
  type: ContextMenuItemInputType;
  min?: number;
  max?: number;
}

/**
 * Context menu item properties
 *
 * @category Breadboard
 */
export interface ContextMenuItemProps {
  /** Text label which will be displayed to user */
  label: string | Function;

  /** String alias to reference in sources */
  alias?: string;
  /**
   * Whether the item can be activated
   *
   * Inactive items are used when it's needed to display some information
   * or to add a sort of a delimiter to the item list.
   */
  active: boolean;

  /**
   * Indicates whether the item is disabled or not.
   */
  disabled?: boolean;

  /**
   * Optional input type
   */
  input?: ContextMenuItemInputProps;

  /**
   * Keyboard shortcut / shortcut combination
   * NB: it would still be required to handle it manually
   */
  shortcut?: string | string[];

  /**
   * Keyboard shortcut options. Use it when the item has multiple binds.
   * NB: it would still be required to handle it manually
   * NB2: if the `shortcut` option is specified, this option will overwrite it.
   */
  shortcuts?: string[];

  /**
   * Present the item _as_ another item with the type already registered in {@link ContextMenu}
   * to prevent alias duplication.
   *
   * Someteimes it's needed to provide a menu item that is functionally the same,
   * but with some modification. This option allows to fire the same event but with the different alias.
   */
  as?: {
    /** Alias of original menu item which is going to be duplicated */
    alias: string;
    /** Custom item click handler to modify menu's internal state or smth else */
    beforeClick?: (
      value: number | string | boolean | File | void,
    ) => number | string | boolean | void | File;
  };
}

import { type Cell } from '../../core/Grid';
import { Plate, type PlateProps } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

type CapacitorPlateProps = PlateProps & {
  capacitance: number;
};

/**
 * Capacitor plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class CapacitorPlate extends LinearPlate<CapacitorPlateProps> {
  static get Alias() {
    return PLATE_ALIASES.CAPACITOR;
  }

  protected get __inheritProps__() {
    return {
      capacitance: 0.001,
    };
  }

  protected get __inheritState__() {
    return {};
  }

  public get variant() {
    return this._shortLabel() + 'Ф';
  }

  protected __setProps__(props: CapacitorPlateProps) {
    super.__setProps__({ ...props, capacitance: Number(props.capacitance) });
  }

  /**
   * @inheritdoc
   */
  __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    this._drawLabel();
  }

  /**
   * Draws a capacitor over the plate surface
   *
   * @param qs size of squares
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(
      this.attrs.size.x - 1,
      this.attrs.size.y - 1,
    );

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .center(cell1.center_rel.x, cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .center(cell2.center_rel.x, cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const line_len = rect2.x() - rect1.x();

    this._group
      .path([
        ['M', 0, 0],
        ['l', line_len / 2 - qs / 4, 0],
        ['m', qs / 2, 0],
        ['l', line_len / 2 - (qs * 2) / 4, 0],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .fill('none')
      .move(rect1.cx(), rect2.cy());

    const line1 = this._group
      .line(0, 0, 0, qs * 1.5)
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .x(rect1.x() + line_len / 2 + qs / 4)
      .cy(rect1.cy());
    const line2 = this._group
      .line(0, 0, 0, qs * 1.5)
      .addClass('bb-plate-stroke')
      .stroke({ width: 1 })
      .x(rect2.x() - line_len / 2 + (qs * 3) / 4)
      .cy(rect2.cy());

    // this._group
    //   .text('+')
    //   .addClass('bb-plate-caption')
    //   .move(line2.x() + 4, line2.y() - 8);
  }

  /**
   * Draws a label with the capacitor designation
   *
   * @param size label font size
   */
  private _drawLabel(size = Plate.LabelFontSizePreferred) {
    this._group
      .text(this._shortLabel() + 'F')
      .font({ size })
      .addClass('bb-plate-caption')
      .cx(this._container.width() / 2)
      .y(this._container.height() - (size - 2) * 2)
      .stroke({ width: 0.5 });
  }

  /**
   * @returns short designtation of the capacitor
   */
  private _shortLabel(): string {
    let text = String(this.props.capacitance);

    const num = Number(text);

    if (num * 1e6 >= 1) {
      text = String(Number(num * 1e6).toPrecision()) + ' μ';
    }
    // if (num * 1e3 >= 1)    {text = String(Number(num * 1e3).toPrecision())   + 'н'}
    if (num >= 1) {
      text = String(Number(num).toPrecision()) + ' p';
    }

    return text;
  }
}

import { type Cell } from '../../core/Grid';

import { Plate } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Relay plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class RelayPlate extends LinearPlate {
  static get Alias() {
    return PLATE_ALIASES.RELAY;
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * @inheritdoc
   */
  protected get __length__() {
    return 5;
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    if (this._isLegacyLayout()) {
      this._drawPictureV5();
    } else {
      this._drawPictureV5();
    }

    // this._group.text(`Resistor ${this._params.resistance} Ohm`).font({size: 20});
  }

  /**
   * Draws a relay over the plate surface
   *
   * @param qs size of squares
   */
  protected _drawPictureV5(qs = Plate.QuadSizePreferred) {
    const cells = [];
    const rects = [];
    const paths = [];

    for (let i = 0; i < 5; i++) {
      const cell = this.__grid.getCell(i, 0);

      const rect = this._group.rect(qs, qs);

      rect.center(cell.center_rel.x, cell.center_rel.y);
      rect.addClass('bb-plate-fill');

      cells.push(cell);
      rects.push(rect);
    }

    const block = this._group.rect(qs * 0.8, qs * 1.6);
    block.center(
      cells[0].center_rel.x / 2 + cells[1].center_rel.x / 2,
      cells[0].center_rel.y,
    );

    block.addClass('bb-plate-stroke');
    block.fill({ color: 'transparent' });
    block.stroke({ width: 2 });

    paths[0] = this._group
      .polyline([
        [cells[0].center_rel.x, cells[0].center_rel.y],
        [cells[0].center_rel.x, cells[0].center_rel.y + qs],
        [block.cx(), cells[0].center_rel.y + qs],
        [block.cx(), block.y() + block.height()],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    paths[1] = this._group
      .polyline([
        [block.cx(), block.y()],
        [block.cx(), cells[1].center_rel.y - qs],
        [cells[1].center_rel.x, cells[1].center_rel.y - qs],
        [cells[1].center_rel.x, cells[1].center_rel.y],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    const cells_32x_one_thirds =
      (cells[3].center_rel.x - cells[2].center_rel.x) / 3;
    const cells_32x_two_thirds = cells_32x_one_thirds * 2;

    const cells_32x_half = (cells[3].center_rel.x - cells[2].center_rel.x) / 2;

    paths[2] = this._group
      .polyline([
        [cells[2].center_rel.x, cells[2].center_rel.y],

        [cells[2].center_rel.x + cells_32x_one_thirds, cells[2].center_rel.y],
        [
          cells[2].center_rel.x + cells_32x_two_thirds,
          cells[3].center_rel.y - qs / 2,
        ],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2, color: '#000' })
      .fill('none');

    paths[3] = this._group
      .polyline([
        [cells[3].center_rel.x, cells[3].center_rel.y],
        [cells[3].center_rel.x, cells[3].center_rel.y + qs],
        [cells[3].center_rel.x - cells_32x_half, cells[3].center_rel.y + qs],
        [
          cells[3].center_rel.x - cells_32x_half,
          cells[3].center_rel.y + qs / 2,
        ],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    paths[4] = this._group
      .polyline([
        [
          cells[2].center_rel.x + cells_32x_half,
          cells[2].center_rel.y - qs / 3,
        ],
        [cells[2].center_rel.x + cells_32x_half, cells[2].center_rel.y - qs],

        [cells[4].center_rel.x, cells[4].center_rel.y - qs],
        [cells[4].center_rel.x, cells[4].center_rel.y],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');
  }

  protected _drawPictureV9(qs = Plate.QuadSizePreferred) {
    const cells = [];
    const rects = [];
    const paths = [];

    for (let i = 0; i < 5; i++) {
      const cell = this.__grid.getCell(i, 0);

      const rect = this._group.rect(qs, qs);

      rect.center(cell.center_rel.x, cell.center_rel.y);
      rect.addClass('bb-plate-fill');

      cells.push(cell);
      rects.push(rect);
    }

    const block = this._group.rect(qs * 0.8, qs * 1.6);
    block.center(
      cells[3].center_rel.x / 2 + cells[4].center_rel.x / 2,
      cells[3].center_rel.y,
    );

    block.addClass('bb-plate-stroke');
    block.fill({ color: 'transparent' });
    block.stroke({ width: 2 });

    const celldist_x = cells[2].center_rel.x - cells[1].center_rel.x;

    const point_12middle = {
      x: cells[1].center_rel.x + celldist_x / 2,
      y: cells[1].center_rel.y - qs / 2,
    };

    const point_12threefourths = {
      x: cells[1].center_rel.x + (celldist_x * 3) / 4,
      y: cells[1].center_rel.y,
    };

    paths[0] = this._group
      .polyline([
        [cells[3].center_rel.x, cells[3].center_rel.y],
        [cells[3].center_rel.x, cells[3].center_rel.y + qs * 1.4],
        [block.cx(), cells[3].center_rel.y + qs * 1.4],
        [block.cx(), block.y() + block.height()],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    paths[1] = this._group
      .polyline([
        [block.cx(), block.y()],
        [block.cx(), cells[1].center_rel.y - qs * 1.4],
        [cells[4].center_rel.x, cells[4].center_rel.y - qs * 1.4],
        [cells[4].center_rel.x, cells[4].center_rel.y],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    paths[2] = this._group
      .polyline([
        [point_12middle.x, point_12middle.y],
        [point_12middle.x, cells[1].center_rel.y - qs],
        [cells[1].center_rel.x, cells[1].center_rel.y - qs],
        [cells[1].center_rel.x, cells[1].center_rel.y],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    paths[3] = this._group
      .polyline([
        [cells[0].center_rel.x, cells[0].center_rel.y],
        [cells[0].center_rel.x, cells[0].center_rel.y + qs],
        [point_12middle.x, cells[0].center_rel.y + qs],
        [point_12middle.x, cells[0].center_rel.y + qs / 3],
        [point_12threefourths.x, cells[2].center_rel.y],
        [cells[2].center_rel.x, cells[2].center_rel.y],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');
  }
}

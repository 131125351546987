import { SettingType } from '../../core/datatypes/settings';

export default function (
  lang: string | undefined,
  allow_demo: boolean,
  is_demo_default: boolean,
  verbose = false,
) {
  return {
    general: {
      title: 'settings:general.title',
      groups: [
        {
          settings: {
            is_demo: {
              title: 'settings:general.settings.is_demo.title',
              type: SettingType.Boolean,
              default: is_demo_default,
              is_locked: !allow_demo,
            },
            theme: {
              title: 'settings:general.settings.theme.title',
              type: SettingType.ChoiceSingle,
              choices: [
                {
                  value: 'system',
                  label: 'settings:general.settings.theme.system',
                },
                {
                  value: 'light',
                  label: 'settings:general.settings.theme.light',
                },
                {
                  value: 'dark',
                  label: 'settings:general.settings.theme.dark',
                },
              ],
              is_locked: true,
              default: 'light',
            },
            language: {
              title: 'settings:general.settings.language.title',
              type: SettingType.ChoiceSingle,
              choices: [
                { value: 'en', label: 'English' },
                { value: 'ru', label: 'Русский' },
              ],
              default: lang || 'en',
            },
          },
        },
      ],
    },
    board: {
      title: 'settings:board.title',
      groups: [
        {
          title: 'settings:board.groups.debug.title',
          settings: {
            is_verbose: {
              title: 'settings:board.settings.is_verbose',
              type: SettingType.Boolean,
              default: verbose,
            },
          },
        },
      ],
    },
  };
}

import { type Cell } from '../../core/Grid';

import { Plate } from '../../core/Plate';
import { ButtonPlate } from './ButtonPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Switch plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class ReedSwitchPlate extends ButtonPlate {
  static get Alias() {
    return PLATE_ALIASES.REED_SWITCH;
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    if (this.options.verbose) {
      this._redrawInput(this.state.field);
    }

    // this._group.text(`Switch`).font({size: 20});
  }

  /**
   * @inheritdoc
   */
  protected _toggleJumper() {}

  /**
   * Draws a switch over the plate surface
   *
   * @param {number} qs size of squares
   */
  protected _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(1, 0);

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);

    rect1
      .cx(cell1.center_rel.x)
      .cy(cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .cx(cell2.center_rel.x)
      .cy(cell2.center_rel.y)
      .addClass('bb-plate-fill');

    const elem_pos_y = this._container.height() / 5;

    this._group
      .path([
        ['M', cell1.center_rel.x, cell1.center_rel.y],
        ['V', elem_pos_y],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    this._group
      .path([
        ['M', cell2.center_rel.x, cell2.center_rel.y],
        ['V', elem_pos_y],
      ])
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    this._group
      .rect(cell2.center_rel.x - cell1.center_rel.x + qs / 2, qs / 2)
      .center(this._container.width() / 2, elem_pos_y)
      .addClass('bb-plate-fill')
      .fill('none');

    const fs = Plate.LabelFontSizePreferred;

    this._group
      .text((add) => {
        add.tspan('REED');
        add.tspan('SWITCH').newLine();
      })
      .addClass('bb-plate-caption')
      .font({ size: fs, anchor: 'middle' })
      .x(this._container.width() / 2)
      .cy(this._container.height() - fs * 1.6);
  }
}

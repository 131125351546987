import type SVG from 'svg.js';

import { type Cell } from '../../core/Grid';
import {
  Plate,
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from '../../core/Plate';
import { mod } from '../../helpers/common';
import { PLATE_ALIASES } from './constants';

type RheostatPlateState = PlateState & {
  field: number;
};

/**
 * Rheostat plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class RheostatPlate extends Plate<PlateProps, RheostatPlateState> {
  static get Alias() {
    return PLATE_ALIASES.RHEOSTAT;
  }

  static get Layouts() {
    return ['v9x', 'v10.3'];
  }

  private readonly _svginp: SVG.Text;

  private readonly _svginpbg: SVG.Rect;

  protected __defaultAttrs__(): any {
    return {
      ...super.__defaultAttrs__(),

      size: { x: 3, y: 1 },
      surface: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
      ],
      origin: { x: 0, y: 0 },
    };
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {
      field: 0,
    };
  }

  /**
   * @deprecated
   * @inheritdoc
   */
  public get input() {
    return Number(this.state.field);
  }

  /**
   * @inheritdoc
   */
  public inputIncrement() {
    this.setState({ field: mod(Number(this.state.field) + 1, 256) });
  }

  /**
   * @inheritdoc
   */
  public inputDecrement() {
    this.setState({ field: mod(Number(this.state.field) - 1, 256) });
  }

  /**
   * @inheritdoc
   */
  public setState(
    state: Partial<SerializedPlateState<RheostatPlateState>>,
    suppress_events: boolean = false,
  ) {
    if (state.field === undefined) {
      return;
    }

    let field = state.field || 0;

    field = Math.min(Math.max(field, 0), 255);

    super.setState({ field }, suppress_events);

    if (this.options.verbose) {
      this._redrawInput(state.field);
    }
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    if (this._isLegacyLayout()) {
      this._drawPictureV5();
    } else {
      this._drawPictureV9();
    }

    if (this.options.verbose) {
      this._redrawInput(this.state.field);
    }

    // this._group.text(`Resistor ${this._params.resistance} Ohm`).font({size: 20});
  }

  /**
   * Draws a rheostat over the plate surface
   *
   * @param qs size of squares
   */
  private _drawPictureV5(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(1, 0);
    const cell3 = this.__grid.getCell(2, 0);

    const rect1 = this._group.rect(qs, qs);
    const rect3 = this._group.rect(qs, qs);

    rect1
      .cx(cell1.center_rel.x)
      .cy(cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect3
      .cx(cell3.center_rel.x)
      .cy(cell3.center_rel.y)
      .addClass('bb-plate-fill');

    const contact_point = {
      x: cell3.center_rel.x,
      y: cell1.center_rel.y,
    };

    const line_right = this._group.path([
      ['M', cell1.center_rel.x, cell1.center_rel.y],
      ['H', cell3.rel.x],
      ['L', contact_point.x, contact_point.y],
    ]);

    const line_left = this._group.path([
      ['M', cell2.center_rel.x, cell2.center_rel.y],
      ['H', cell3.rel.x + cell3.size.x],
      ['L', contact_point.x, contact_point.y],
    ]);

    const line_middle = this._group.path([
      ['M', cell3.center_rel.x, cell3.center_rel.y],
      ['V', contact_point.y + qs / 2],
    ]);

    line_right.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');
    line_left.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');
    line_middle.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');

    const body = this._group
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .rect(qs * 2, qs / 1.5)
      .stroke({ width: 3 })
      .center(contact_point.x, cell1.center_rel.y);

    // let arrow_height = qs * 1.5;
    // let arrow_width = qs * 2;
    //
    // let arrow = this._group.polyline([
    //     [arrow_width, -arrow_height],
    //     [0, -arrow_height],
    //     [0, 0],
    // ])
    //     .stroke({width: 3})
    //     .fill("none")
    //     .x(body.cx())
    //     .y(body.y() - arrow_height);

    line_middle.marker('end', qs / 2, qs / 2, function (add) {
      add
        .path([
          ['M', 0, 0],
          ['l', -qs / 2, -qs / 4],
          ['l', 0, qs / 2],
          ['l', qs / 2, -qs / 4],
        ])
        .addClass('bb-plate-fill')
        .addClass('bb-plate-stroke')
        .stroke({ width: 0.5 })
        .move(-qs / 4, 0);
    });
  }

  private _drawPictureV9(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(1, 0);
    const cell3 = this.__grid.getCell(2, 0);

    const rect3 = this._group.rect(qs, qs);
    const rect12 = this._group.rect(cell1.size.x + (cell2.rel.x + cell1.rel.x) / 2, qs);

    const body_center = {
      x: (cell2.center_rel.x + cell1.center_rel.x) / 2,
      y: (cell2.center_rel.y + cell1.center_rel.y) / 2,
    };

    rect3
      .cx(cell3.center_rel.x)
      .cy(cell3.center_rel.y)
      .addClass('bb-plate-fill');

    rect12
      .cx(body_center.x)
      .cy(body_center.y)
      .addClass('bb-plate-fill');

    const line_height = this._container.height() / 6;

    const line_left = this._group.path([
      ['M', cell1.center_rel.x, cell2.center_rel.y],
      ['L', cell1.center_rel.x, line_height],
      ['H', body_center.x - 15],
      ['V', body_center.y],
    ]);

    const line_center = this._group.path([
      ['M', cell2.center_rel.x, cell2.center_rel.y],
      ['L', cell2.center_rel.x, line_height],
      ['H', body_center.x],
      ['V', body_center.y],
      ['M', body_center.x + 10, body_center.y],
      ['V', line_height + 10],
      ['H', cell2.center_rel.x - 10],
    ]);

    const line_right = this._group.path([
        ['M', cell3.center_rel.x, cell3.center_rel.y],
        ['L', cell3.center_rel.x, line_height + 10],
        ['L', cell2.center_rel.x + 10, line_height + 10],
    ])

    const arrow = this._group.path([
      ['M', cell2.center_rel.x, cell2.center_rel.y - qs / 2],
      ['L', cell2.center_rel.x + qs / 3, cell2.center_rel.y - qs],
      ['H', cell2.center_rel.x - qs / 3],
      ['Z'],
    ]);

    line_left.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');
    line_center.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');
    line_right.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');
    arrow.addClass('bb-plate-stroke').addClass('bb-plate-fill');

    const body = this._group
      .addClass('bb-plate-fill')
      .addClass('bb-plate-stroke')
      .rect(qs * 3, qs)
      .stroke({ width: 2 })
      .center(body_center.x, body_center.y + 15);
  }
}

import {
  BreadboardMode,
  type BreadboardOptions,
} from '~/js/utils/breadboard/Breadboard.types';
import { LAYOUTS } from '~/js/utils/breadboard/components/layouts';

export const DEFAULT_OPTIONS: BreadboardOptions = {
  layouts: LAYOUTS,
  layout_name: Object.keys(LAYOUTS)[0],
  admin: false,
  verbose: false,
  detailed: true,
  schematic: true,
  mode: BreadboardMode.Default,
  bgVisible: true,
  showControlsDefault: true,
  showSourceCurrents: true,
  useCustomTheme: false,
};

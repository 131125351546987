import { HttpModel } from '~/js/core/models/HttpModel';
import { RequestMethod } from '~/js/core/models/datasources/HttpDatasource';

export class ServerModel extends HttpModel<any> {
  static alias = 'server';

  protected defaultState: any;

  async getVersion() {
    try {
      const version = await this.request('meta/version', {
        method: RequestMethod.GET,
      });
      return version;
    } catch (e) {
      console.warn('Cannot retrieve server version');
      console.error(e);
    }

    return { self: 'n/a', core: 'n/a', verifier: 'n/a' };
  }

  async getLatestClient(): Promise<{ version: string; file: string }> {
    const response = await this.request('distros/latest', {
      method: RequestMethod.GET,
    });

    return {
      version: response.version,
      file: response.file,
    };
  }
}

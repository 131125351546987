export const PLATE_ALIASES = {
  BATTERY: 'Vss',
  BRIDGE: 'bridge',
  BUTTON: 'button',
  BUZZER: 'buzzer',
  CAPACITOR: 'capacitor',
  DIODE: 'diode',
  DUMMY: 'dummy',
  INDICATOR: 'indicator',
  INDUCTOR: 'inductor',
  JUMPER: 'jumper',
  LED: 'LED',
  PHOTORESISTOR: 'photoresistor',
  PHOTOTRANSISTOR: 'phototransistor',
  PROTOTYPE: 'prototype',
  REED_SWITCH: 'reed_switch',
  RELAY: 'relay',
  RESISTOR: 'resistor',
  RGB: 'rgb',
  RHEOSTAT: 'rheostat',
  SWITCH: 'switch',
  TEMP_SENSOR: 'temp_sensor',
  THERMORESISTOR: 'thermoresistor',
  TRANSISTOR_NPN: 'transistor_npn',
  TRANSISTOR_PNP: 'transistor_pnp',
  T_RHEOSTAT: 'rheostat_t',
  T_SWITCH: 'switch_t',
  UNK: 'undef',
};

import { LAYOUTS } from '~/js/utils/breadboard/components/layouts';

import AsynchronousModel, {
  connect,
  disconnect,
  listen,
} from '~/js/core/base/model/AsynchronousModel';
import {
  DEV_TIMEOUT,
  DEVICE_VERSION_TO_LAYOUT,
} from '~/js/models/common/BoardModel/constants';
import {
  type DeviceInfo,
  type DeviceStatus,
  type ServerGreeting,
} from '~/js/models/common/ConnectionModel/types';
import { type ElecData } from '~/js/utils/breadboard/core/Current';
import { Grid } from '~/js/utils/breadboard/core/Grid';
import {
  CellRole,
  type Layout,
  type PinState,
} from '~/js/utils/breadboard/core/Layout';
import {
  type PlateState,
  type SerializedPlateWithId,
} from '~/js/utils/breadboard/core/Plate';
import { extractLabeledCells } from '~/js/utils/breadboard/helpers/common';
import {
  ChannelsCore,
  ChannelsUi,
  CORE_MODES_READONLY,
  CoreMode,
} from '../../constants';
import {
  BoardLayoutEvent,
  BoardModeEvent,
  BoardStatusEvent,
  BoardTimeoutEvent,
  BoardUnknownEvent,
  ElectronicEvent,
  FieldsEvent,
  ForceLayoutEvent,
  PlateEvent,
  UserPlateEvent,
} from './events';
import {
  type BoardModelSnapshot,
  type BreadboardModelState,
  type CoreElecData,
  type CoreUnitData,
  type CoreUnitFields,
} from './types';

export class BoardModel extends AsynchronousModel<BreadboardModelState> {
  static alias = 'board';

  static Layouts: Record<string, Layout> = LAYOUTS;

  static LayoutDefault = 'v10.3';

  protected defaultState: BreadboardModelState = {
    plates: [],
    elec_data: {
      board: { currents: [], voltages: {} },
      units: {},
      counter: -1,
    },
    force_layout_name: undefined,
    layout_name: BoardModel.LayoutDefault,
    layout_confirmed: false,
    snapshot_limit: 1000,
    snapshot_ttl: 30000, // ms
    is_connected: false,
    mode: CoreMode.Configuring,
    silent: false,
    is_demo: false,
  };

  private last_snapshot_time: number = 0;

  private readonly snapshots: BoardModelSnapshot[] = [];
  private __legacy_onuserchange: Function;
  private devTimerId?: number;

  private saveSnapshot() {
    this.last_snapshot_time = Date.now();
    this.snapshots.push({
      time: this.last_snapshot_time,
      data: this.state,
    });

    if (this.snapshots.length > this.state.snapshot_limit) {
      this.snapshots.shift();
    }
  }

  public getSnapshots(): BoardModelSnapshot[] {
    const snapshots = [];

    const last_snapshot_time = this.snapshots[this.snapshots.length - 1].time;

    for (let i = this.snapshots.length - 1; i >= 0; i--) {
      const snapshot = this.snapshots[i];

      if (last_snapshot_time - snapshot.time > this.state.snapshot_ttl) {
        break;
      }

      snapshots.unshift(snapshot);
    }

    return snapshots;
  }

  /**
   * Get board layout currently applied to the board
   */
  public getCurrentLayout(): string {
    return this.state.layout_name || BoardModel.LayoutDefault;
  }

  /**
   * Set plates defined by the client via editor
   *
   * @param plates
   */
  public setUserPlates(plates: SerializedPlateWithId[]): void {
    this.setState({ plates });
    this.sendPlates(this.state.plates);

    this.emit(new UserPlateEvent({ plates }));

    this.__legacy_onuserchange?.(plates);
  }

  public setUserFields(fields: Record<number, PlateState['field']>) {
    this.sendFields(fields);
  }

  /**
   * Attach listener for user changes
   *
   * This method is left here to maintain compatibility with legacy apps
   * such as server admin widgets
   *
   * @deprecated
   *
   * @param cb
   */
  public onUserChange(cb: Function) {
    this.__legacy_onuserchange = cb;
  }

  public setDemo(is_demo: boolean) {
    this.setState({ is_demo });
    this.setMode(is_demo ? CoreMode.Virtual : CoreMode.Real);
  }

  public getElecLayout(layout_name: string, embed_arduino = true) {
    if (layout_name !== 'v8x') {
      embed_arduino = true;
    }

    return Grid.layoutToElecLayout(
      BoardModel.Layouts[layout_name],
      embed_arduino,
    );
  }

  public forceLayout(layout_name: string) {
    this.requestLayout(layout_name, true);
    this.emit(
      new ForceLayoutEvent({ force_layout_name: layout_name, layout_name }),
    );
  }

  public disableForceLayout() {
    if (this.state.force_layout_name) {
      this.state.force_layout_name = undefined;
      this.requestDeviceLayout();
      this.emit(
        new ForceLayoutEvent({
          force_layout_name: undefined,
          layout_name: this.state.layout_name,
        }),
      );
    }
  }

  /**
   * Send meta information about the board (incl. layout name and structure)
   */
  @connect()
  private onConnect(greeting: ServerGreeting) {
    this.setMode(greeting.mode, true);

    this.reportDevStatus(greeting.dev);

    if (!greeting.dev.status) {
      this.requestLayout(this.state.layout_name || BoardModel.LayoutDefault);
    }

    this.requestDeviceTimeout(greeting.dev?.status);

    if (CORE_MODES_READONLY.includes(greeting.mode)) {
      this.setPlates([]);
      this.setElectronics({
        board: { currents: [], voltages: {} },
        units: {},
        counter: -1,
      });
    }
  }

  private requestDeviceLayout() {
    const layout_name = this.state.dev_version
      ? DEVICE_VERSION_TO_LAYOUT[this.state.dev_version]
      : this.getCurrentLayout();

    if (!layout_name && !this.state.force_layout_name) {
      console.warn(`Unknown board version ${this.state.dev_version}.`);
      this.emit(new BoardUnknownEvent({ version: this.state.dev_version }));

      return;
    }

    this.requestLayout(layout_name);
  }

  public requestLayout(layout_name: string, force = false) {
    if (this.state.silent) {
      this.receiveBoardLayoutName(layout_name);
    }

    if (force) {
      // keep force choice for further requests
      this.setState({ force_layout_name: layout_name });
    } else if (this.state.force_layout_name) {
      // if force is kept, omit the request
      console.warn(
        `Layout ${this.state.force_layout_name} is forced, omitting ${layout_name}`,
      );
      return;
    }

    // disallow board data before confirmation
    this.setState({ layout_confirmed: false, layout_name });

    const board_info = this.getElecLayout(layout_name);

    this.send(ChannelsUi.CNF_LAYOUT, { layout_name, board_info });
  }

  @listen(ChannelsCore.DEV_STATUS)
  private reportDevStatus({ version, port, status }: DeviceInfo) {
    this.setState({
      is_connected: status === 'defined',
      dev_version: status === 'defined' ? version : undefined,
      dev_port: status === 'defined' ? port : undefined,
    });

    this.emit(
      new BoardStatusEvent({
        status,
        version: status === 'defined' ? version : undefined,
        port: status === 'defined' ? port : undefined,
      }),
    );

    if (status === 'defined') {
      this.requestDeviceLayout();
    }
  }

  public setMode(mode: CoreMode, isSilent = false) {
    const modePrev = this.state.mode;

    this.setState({ mode });

    if (!isSilent) {
      this.send(ChannelsUi.CNF_MODE, mode);

      // send latest received plates after switching to virtual mode
      if (modePrev === CoreMode.Real && mode === CoreMode.Virtual) {
        this.sendPlates(this.state.plates);
      }
    }

    this.emit(
      new BoardModeEvent({
        mode,
        is_demo: this.state.is_demo,
      }),
    );
  }

  public getUnits() {
    return this.platesToUnits(this.state.plates);
  }

  public setUnits(units: CoreUnitData) {
    this.setPlates(this.unitsToPlates(units));
  }

  /**
   * Receive board layout name to update the visual configuration and
   * to validate new data packages correctly
   *
   * This handler calls usually after {@link requestLayout} request
   * to verify successful layout switch on the backend.
   *
   * @param layout_name
   */
  @listen(ChannelsCore.CNF_LAYOUT_CONFIRM)
  private receiveBoardLayoutName(layout_name: string) {
    if (!this.state.silent && this.state.layout_name !== layout_name) {
      console.error(
        `Board layout is not confirmed: received ${layout_name}, expected ${this.state.layout_name}`,
      );
      return;
    }

    // confirm board data change
    this.setState({ layout_confirmed: true, layout_name });

    this.emit(new BoardLayoutEvent({ layout_name }));

    if (this.state.mode === CoreMode.Virtual) {
      this.sendPlates(this.state.plates);
    } else {
      this.setPlates([]);
      this.setElectronics({
        board: { currents: [], voltages: {} },
        units: {},
        counter: -1,
      });
    }
  }

  /**
   * Receive plate data update from the backend
   *
   * This method verifies the layout currently applied.
   * If you need to force the data you may need to call {@link setPlates} from developer console.
   *
   * @param units an array of raw plate data objects
   */
  @listen(ChannelsCore.BRD_UNITS)
  private receivePlates(units: CoreUnitData) {
    if (!this.state.layout_confirmed) {
      return;
    }

    this.setUnits(units);
  }

  @listen(ChannelsCore.BRD_STATES)
  private receiveFields(fields: CoreUnitFields) {
    if (!this.state.layout_confirmed) {
      return;
    }

    this.setFields(fields);
  }

  /**
   * Receive electronic data update from the backend
   *
   * This method verifies the layout currently applied.
   *
   * @param elec_data
   */
  @listen(ChannelsCore.BRD_CURRENTS)
  private receiveElectronics(elec_data: CoreElecData) {
    if (!this.state.layout_confirmed) {
      return;
    }

    this.setElectronics(this.coreElecDataToElecData(elec_data));
  }

  @disconnect()
  private onDisconnect() {
    this.setMode(CoreMode.Configuring, true);
  }

  private sendPlates(plates: SerializedPlateWithId[]): void {
    if (this.state.mode === CoreMode.Virtual) {
      this.send(ChannelsUi.BRD_UNITS, {
        units: this.platesToUnits(plates),
        counter: 0,
      });
    }
  }

  private sendFields(fields: Record<number, PlateState['field']>) {
    if (this.state.mode === CoreMode.Virtual) {
      this.send(ChannelsUi.BRD_STATES, fields);
    }
  }

  private platesToUnits(plates: SerializedPlateWithId[]): CoreUnitData {
    return plates.map((plate) => {
      const { position, field } = plate.state;
      return {
        id: plate.id,
        type: plate.type,
        props: plate.props,
        position: position.cells,
        ...(field !== undefined && { dynamic: field }),
      };
    });
  }

  private unitsToPlates(units: CoreUnitData): SerializedPlateWithId[] {
    return units.map((unit) => ({
      id: unit.id,
      type: unit.type,
      props: unit.props,
      state: {
        position: {
          cells: unit.position,
        },
        field: unit.dynamic,
      },
    }));
  }

  private coreElecDataToElecData(elecData: CoreElecData): ElecData {
    return {
      counter: elecData.counter,
      board: {
        voltages: Object.entries(elecData.board.voltages).reduce(
          (acc, [lineId, voltage]) => ({
            ...acc,
            [lineId]: Number(voltage),
          }),
          {},
        ),
        currents: elecData.board.currents.map((current) => ({
          ...current,
          weight: Number(current.weight),
        })),
      },
      units: Object.entries(elecData.units).reduce(
        (acc, [unitId, unit]) => ({
          ...acc,
          [unitId]: {
            currents: unit.currents.map((current) => Number(current)),
            field: unit.dynamic,
          },
        }),
        {},
      ),
    };
  }

  private requestDeviceTimeout(status?: DeviceStatus) {
    if (!status || status === 'searching' || status === 'connected') {
      this.setDeviceTimeout();
    } else {
      this.resetDeviceTimeout();
    }
  }

  private setDeviceTimeout() {
    this.devTimerId = window.setTimeout(
      () => this.checkDeviceTimeout(),
      DEV_TIMEOUT,
    );
  }

  private checkDeviceTimeout() {
    if (!this.state.is_connected) {
      this.emit(new BoardTimeoutEvent());
    }

    this.resetDeviceTimeout();
  }

  private resetDeviceTimeout() {
    clearTimeout(this.devTimerId);
    this.devTimerId = undefined;
  }

  /**
   * Accept incoming plates from backend
   *
   * This method may be useful when debugging.
   * Call it manually if you need to reproduce the situation when the model
   * receives plates from the backend.
   *
   * @param plates
   */
  public setPlates(plates: SerializedPlateWithId[]): void {
    this.setState({ plates });

    this.saveSnapshot();

    this.emit(new PlateEvent({ plates }));
  }

  public setFields(fields: Record<number, any>) {
    this.emit(new FieldsEvent({ fields }));
  }

  public setElectronics(elec_data: ElecData) {
    this.setState({ elec_data });

    this.saveSnapshot();

    this.emit(new ElectronicEvent({ elec_data }));
  }

  public getAnalogStatesInitial() {
    const layout = BoardModel.Layouts[this.getCurrentLayout()];

    const states: [number, number | PinState][] = [];

    for (const cell of extractLabeledCells(layout, CellRole.Analog)) {
      if (cell.pin_num != null && cell.pin_state_initial != null) {
        states.push([cell.pin_num, cell.pin_state_initial]);
      }
    }

    return states;
  }
}

export enum ValidationVerdictStatus {
  Undefined = 'undefined',
  Success = 'success',
  Fail = 'fail',
  Error = 'error',
}

export interface ValidationVerdict {
  status: ValidationVerdictStatus;
  message: string;
  code: string;
  details: {
    region?: any;
    blocks?: any;
  };
}

export interface MissionData {
  code: any;
}

export interface ExerciseProgress {
  id: number;
  is_passed: boolean;
  details: any;
}

export interface MissionProgress {
  id: number;
  // index of current exercise inside the mission
  idx_exercise_current: number;
  // index of lastly passed exercise inside the mission
  idx_exercise_passed: number;
  // whether the mission is passed
  is_passed: boolean;
  details: any;
  data: MissionData;
  exercises: ExerciseProgress[];
}

export interface LessonProgress {
  details: any;
  // index of current mission inside the lesson
  idx_mission_current?: number;
  // index of lastly passed mission inside the lesson
  idx_mission_passed?: number;
  // if loaded, defines whether the lesson is passed, else undefined
  is_passed?: boolean;
  // if loaded, contains an array of missions, else undefined
  missions?: MissionProgress[];
}

export interface CourseProgress {
  id: number;
  details: any;
  lessons: Record<number, LessonProgress>;
}

export interface Progress {
  // progress by courses
  courses?: CourseProgress[];
  // currently opened
  opened: {
    course_id?: number;
    lesson_id?: number;
  };
  // controls
  locks: {
    exercise: boolean;
    mission: boolean;
    lesson: boolean;
    course: boolean;
  };
}

import { Canvg } from 'canvg';
import { saveAs } from 'file-saver';
import SVG from 'svg.js';

import {
  BackgroundLayer,
  ComplexCurrentLayer,
  ControlsLayer,
  CurrentLayer,
  FlyoutLayer,
  LabelLayer,
  MenuLayer,
  PlateLayer,
  PopupLayer,
  RegionLayer,
  VoltageLayer,
  WireLayer,
} from './components/layers';
import {
  PlateChangeActionType,
  type PlatePrototype,
  type SerializedFields,
} from './components/layers/PlateLayer/types';
import { BoardContextMenu } from './components/menus';
import { Grid } from './core/Grid';

import { Theme } from '~/js/utils/breadboard/components/layers/ControlsLayer/types';
import { type WireSerialized } from '~/js/utils/breadboard/components/layers/WireLayer/types';
import { JumperPlate } from '~/js/utils/breadboard/components/plates/JumperPlate';
import {
  type BreadboardMode,
  type AddPlateParams,
  type BreadboardOptions,
} from './Breadboard.types';
import { LAYOUTS } from './components/layouts';
import { type ElecData, type ElecUnit, type Thread } from './core/Current';
import { type Layout, type VoltageTable } from './core/Layout';
import {
  type ChangeCallback,
  type PlateChangeCallback,
  type SerializedPlate,
} from './core/Plate';
import { initGradients } from './styles/gradients';

import { DEFAULT_OPTIONS } from '~/js/utils/breadboard/Breadboard.constants';
import { isEditingAllowed } from '~/js/utils/breadboard/Breadboard.helpers';
import { type LayerOptions } from '~/js/utils/breadboard/core/Layer';
import './styles/main.less';

/**
 * Основной класс платы.
 * Предоставляет API управления визуализацией платы внешним модулям приложений
 *
 * @category Breadboard
 */
export default class Breadboard {
  private _options: BreadboardOptions = DEFAULT_OPTIONS;
  private _brush: SVG.Doc;
  private __grid: Grid;
  private _layers: {
    background?: BackgroundLayer;
    label?: LabelLayer;
    current?: CurrentLayer;
    cocurrent?: ComplexCurrentLayer;
    plate?: PlateLayer;
    region?: RegionLayer;
    controls?: ControlsLayer;
    selector?: FlyoutLayer;
    menu?: MenuLayer;
    popup?: PopupLayer;
    voltage?: VoltageLayer;
    wire?: WireLayer;
  };

  private readonly _callbacks: {
    change: ChangeCallback;
    dragstart: CallableFunction;
    shortcircuitstart: CallableFunction;
    shortcircuitend: CallableFunction;
    layoutchangerequest: CallableFunction;
    moderequest: (mode: BreadboardMode) => void;
  };

  private _layouts: Record<string, Layout>;
  private _div_wrap: HTMLDivElement;
  private _layout: Layout;
  private _dom_node_parent: any;
  private _injected: boolean;

  constructor(options: Partial<BreadboardOptions>) {
    if (!SVG.supported) {
      alert('SVG is not supported. Please use any modern browser.');
    }

    this._layers = {};

    this._callbacks = {
      change: () => {},
      dragstart: () => {},
      shortcircuitstart: () => {},
      shortcircuitend: () => {},
      layoutchangerequest: () => {},
      moderequest: () => {},
    };

    this._layouts = {};
    this.registerLayouts(LAYOUTS);

    this._dom_node_parent = undefined;

    this._setOptions(options);

    this._injected = false;
  }

  /* TODO: Define 'properties' type */
  static drawPlate(parent: HTMLElement, type: string, properties: unknown) {
    const grid = new Grid({
      dim: { x: 10, y: 10 },
      size: { x: 1000, y: 700 },
      gap: { x: 10, y: 10 },
      pos: { x: 0, y: 0 },
      wrap: { x: 1000, y: 700 },
      name: 'default',
    });
    const div_wrap = SVG(parent);

    const plate_type: any = PlateLayer.typeToPlateClass(type);

    const plate = new plate_type(
      div_wrap,
      grid,
      false,
      false,
      null,
      properties,
    );

    plate.draw(grid.getCell(0, 0), 'west');
    plate.move_to_point(0, 0);

    const width = plate._container.width() / 2;
    const height = plate._container.width() / 2;

    plate._container.center(width / 2, height / 2);

    div_wrap.viewbox(0, 0, width, height);
  }

  getElecLayout() {
    if (!this._layout) {
      throw Error('No layout specified yet');
    }

    return this.__grid.getElecLayout();
  }

  getContainer() {
    if (!this._brush) {
      return null;
    }

    return this._brush.node;
  }

  switchModePhoto(on = true) {
    this._layers.region?.toggle(!on);
    // this._layers.controls.toggle(!on);
    // this._layers.current.toggle(!on);
    this._layers.controls?.toggleMenuButtonDisplay(!on);
  }

  getPlates() {
    return this._layers.plate?.getSerializedPlates() || [];
  }

  getFields() {
    return this._layers.plate?.getFields() || {};
  }

  setFields(fields: SerializedFields) {
    this._layers.plate?.setFields(fields);
  }

  /**
   * Инициализировать графическую составляющую платы
   *
   * @param {HTMLElement} dom_node    DOM-узел, в который будет встроена плата
   */
  inject(dom_node: HTMLElement) {
    if (dom_node === undefined) {
      throw new TypeError('Breadboard::inject(): DOM node is undefined');
    }

    const div_wrap = document.createElement('div');
    div_wrap.id = 'bb-root';
    dom_node.appendChild(div_wrap);

    this._div_wrap = div_wrap;

    div_wrap.style.width = '100%';
    div_wrap.style.height = '100%';
    div_wrap.style.position = 'relative';
    div_wrap.style.overflow = 'hidden';

    this._injected = true;

    this._dom_node_parent = dom_node;

    /// установить разметку
    const layout_default = Object.keys(this._layouts)[0];
    this._layout = this._layouts[this._options.layout_name || layout_default];

    /// базовая кисть
    this._brush = SVG(div_wrap);
    this._brush.node.setAttribute(
      'viewBox',
      '0 0 ' + this._layout.wrap.x + ' ' + this._layout.wrap.y,
    );
    this._brush.node.style.width = '100%';
    this._brush.node.style.height = '100%';

    this._brush.style({ 'user-select': 'none' });

    this.__grid = new Grid(this._layout);

    /// создать градиенты
    this._defineGradients();
    /// инициализировать слои
    this._composeLayers();

    this.setMode(this._options.mode);
  }

  setRandomPlates(
    protos: PlatePrototype[],
    size_mid?: number,
    size_deviation?: number,
    attempts_max?: number,
  ) {
    if (!this._layers.plate) {
      throw new Error('Breadboard must be injected first');
    }
    this._layers.plate.setRandom(
      protos,
      size_mid,
      size_deviation,
      attempts_max,
    );
  }

  /**
   * Sets breadboard mode
   *
   * @param mode
   */
  setMode(mode: BreadboardMode) {
    this._options.mode = mode;

    for (const layer of Object.values(this._layers)) {
      layer.setMode(this._options.mode);
    }
  }

  registerLayouts(layouts: Record<string, Layout>) {
    if (!layouts) {
      return;
    }

    for (const [name, layout] of Object.entries(layouts)) {
      this._layouts[name] = layout;
    }

    this._setLayoutsForControls();
  }

  private _setLayoutsForControls() {
    this._layers.controls?.setLayouts(
      Object.entries(this._layouts).map(([layout_name, layout]) => ({
        name: layout_name,
        title: layout.name,
      })),
    );
  }

  setLayout(layout_name: string) {
    if (layout_name === this.getLayoutName()) {
      return;
    }

    this.reinject({ layout_name });
    this._callbacks.change({
      id: null,
      action: PlateChangeActionType.Clear,
    });
  }

  /**
   * Удалить графическую составляющую платы
   */
  dispose() {
    if (!this._injected) {
      return;
    }

    this._injected = false;

    this._div_wrap.remove();
    this._brush.node.remove();
    this._layers = {};
  }

  reinject(options: Partial<BreadboardOptions>) {
    this.dispose();
    this._setOptions(options);
    this.inject(this._dom_node_parent);
  }

  redraw(options: LayerOptions) {
    const units = this._layers.cocurrent?.getUnits();

    this._detachWireEvents();
    this._detachPlateEvents();

    for (const layer of Object.values(this._layers)) {
      layer.recompose(options);
    }

    this._attachPlateEvents();
    this._attachWireEvents();

    if (units) {
      this.setComplexCurrents(units);
    }
  }

  /**
   * Добавить плашку
   *
   * @param {null|int}    id          идентификатор плашки
   * @param {string}      type        тип плашки
   * @param {*}           props       свойства плашки
   * @param {*}           state       свойства плашки
   *
   * @returns {null|int} идентификатор плашки
   */
  addPlate({ id = null, type, props, state }: AddPlateParams) {
    return this._layers.plate?.addPlateSerialized({
      id,
      type,
      props,
      state,
    });
  }

  /**
   * Подсветить ошибочные плашки
   *
   * @param {Array} plate_ids массив идентификаторов плашек, которые требуется подсветить
   *
   */
  highlightPlates(plate_ids: number[]) {
    this._layers.plate?.highlightPlates(plate_ids);
  }

  /**
   * Удалить все плашки с платы
   */
  clearPlates() {
    this._layers.plate?.removeAllPlates();
  }

  getLayoutName() {
    return this._options.layout_name;
  }

  /**
   * Установить плашки на плату
   *
   * @param {Array<Object>} plates список плашек, которые должны отображаться на плате
   */
  setPlates(plates: SerializedPlate[]) {
    const success = this._layers.plate?.setPlates(plates);

    if (success) {
      this._syncJumpers();
    }

    return success;
  }

  setElecData(elec_data: ElecData) {
    const { board, units, counter } = elec_data;

    if (board) {
      const { currents, voltages } = board;

      this.setCurrents(currents, voltages);
    }

    if (units) {
      this.setComplexCurrents(units);
    }
  }

  /**
   * Отобразить токи на плате
   *
   * @param {Array<Object>} threads контуры токов
   * @param voltages
   */
  setCurrents(threads: Thread[], voltages: VoltageTable) {
    // this._layers.current.setCurrents(threads, this._spare);
    this._layers.current?.setCurrents(
      threads,
      false,
      this._options.showSourceCurrents,
    );

    // TODO: uncomment and fix errors
    // voltages && this.__grid.setLineVoltages(voltages);
  }

  setComplexCurrents(units: Record<number, ElecUnit>) {
    this._layers.plate?.setPlatesFields(units);

    const network = this._layers.wire?.getNetwork();
    const jumpers = this._layers.plate?.getJumperPlates();
    this._layers.cocurrent?.setCurrents(units, network || {}, jumpers || []);
  }

  /**
   * Очистить токи
   */
  clearCurrents() {
    this._layers.current?.removeAllCurrents();
  }

  /**
   * Подсветить область
   *
   * @param {Object}  from    исходная координата выделения
   * @param {Object}  to      конечная координата выделения
   * @param {boolean} clear   очистить предыдущее выделение
   */
  highlightRegion(
    from: { x: number; y: number },
    to: { x: number; y: number },
    clear: boolean,
  ) {
    this._layers.region?.highlightRegion(from, to, clear);
  }

  /**
   * Очистить подсвеченные области
   */
  clearRegions() {
    this._layers.region?.clearRegions();
  }

  onChange(cb: PlateChangeCallback) {
    this._callbacks.change = cb || (() => {});
  }

  onDragStart(cb: Function) {
    this._callbacks.dragstart = cb || (() => {});
  }

  onShortCircuitStart(cb: Function) {
    this._callbacks.shortcircuitstart = cb || (() => {});
  }

  onShortCircuitEnd(cb: Function) {
    this._callbacks.shortcircuitend = cb || (() => {});
  }

  onLayoutChangeRequest(cb: Function) {
    this._callbacks.layoutchangerequest = cb || (() => {});
  }

  onModeRequest(cb: (mode: BreadboardMode) => void) {
    this._callbacks.moderequest = cb || (() => {});
  }

  switchSchematic(on: boolean, detailed = false) {
    // TODO: Merge detailed and schematic modes
    if (this._options.schematic === on && this._options.detailed === detailed) {
      return;
    }

    this._options.schematic = on;
    this._options.detailed = detailed;

    this.redraw({ schematic: this._options.schematic });
  }

  switchVerbose(on: boolean) {
    if (this._options.verbose === on) {
      return;
    }

    this._options.verbose = on;

    this.redraw({ verbose: this._options.verbose });
  }

  /**
   * Скомпоновать слои платы
   *
   * @private
   */
  _composeLayers() {
    /// создание DOM-контейнеров
    const background = this._brush.nested(); // фон
    const label_panes = this._brush.nested(); // подписи
    const voltage = this._brush.nested(); // области доменов
    const plate = this._brush.nested(); // плашки
    const current = this._brush.nested(); // токи
    const cocurrent = this._brush.nested(); // токи
    const region = this._brush.nested(); // области выделения
    const controls = this._brush.nested(); // органы управления
    const wire = this._brush.nested(); // провода
    const selector = document.createElement('div'); // боковое меню (селектор плашек)
    const menu = document.createElement('div'); // всплывающее меню
    const popup = document.createElement('div'); // всплывающие подсказки

    this._div_wrap.appendChild(selector);
    this._div_wrap.appendChild(menu);
    this._div_wrap.appendChild(popup);

    const layerOpts = {
      schematic: this._options.schematic,
      detailed: this._options.detailed,
      verbose: this._options.verbose,
      admin: this._options.admin,
    };

    /// инициализация слоёв
    this._layers.background = new BackgroundLayer(
      background,
      this.__grid,
      layerOpts,
    );
    this._layers.label = new LabelLayer(label_panes, this.__grid, layerOpts);
    this._layers.current = new CurrentLayer(current, this.__grid, layerOpts);
    this._layers.cocurrent = new ComplexCurrentLayer(
      cocurrent,
      this.__grid,
      layerOpts,
    );
    this._layers.plate = new PlateLayer(plate, this.__grid, layerOpts);
    this._layers.voltage = new VoltageLayer(voltage, this.__grid, layerOpts);
    this._layers.controls = new ControlsLayer(controls, this.__grid, layerOpts);
    this._layers.region = new RegionLayer(region, this.__grid, layerOpts);
    this._layers.selector = new FlyoutLayer(selector, this.__grid, layerOpts);
    this._layers.menu = new MenuLayer(menu, this.__grid, layerOpts);
    this._layers.popup = new PopupLayer(popup, this.__grid, layerOpts);
    this._layers.wire = new WireLayer(wire, this.__grid, layerOpts);

    this._layers.background.setBgVisible(this._options.bgVisible);
    this._layers.label.setLayoutConfig(this._layout);
    this._layers.label.setLabelStyle(this._layout.label_style);
    this._layers.plate.setPlateStyle(this._layout.plate_style);

    /// internal composition of each layer
    for (const layer of Object.values(this._layers)) {
      // TODO: Merge context menu calls to popup calls

      // connect context menu request handlers to each layer
      layer.onContextMenuCall(
        this._layers.menu.openMenu.bind(this._layers.menu),
      );
      layer.onContextMenuClose(
        this._layers.menu.hideMenu.bind(this._layers.menu),
      );

      // connect popup request handlers to each layer
      layer.onPopupDraw(this._layers.popup.drawPopup.bind(this._layers.popup));
      layer.onPopupShow(this._layers.popup.showPopup.bind(this._layers.popup));
      layer.onPopupHide(this._layers.popup.hidePopup.bind(this._layers.popup));
      layer.onPopupClear(
        this._layers.popup.clearPopup.bind(this._layers.popup),
      );

      layer.compose();
    }

    this._layers.controls.setLayoutName(this._options.layout_name);
    this._layers.controls.toggleThemeButtonDisplay(
      !this._options.useCustomTheme,
    );

    /// включение / отключение режима только чтения
    this._attachControlsEvents();
    this._attachNotificationEvents();
    this._attachWireEvents();
    this._attachPlateEvents();

    this._setLayoutsForControls();
  }

  /**
   * Задать опции платы
   *
   * @param {Object} options словарь опций
   * @private
   */
  _setOptions(options: Partial<BreadboardOptions>) {
    if (options.layouts) {
      this.registerLayouts(options.layouts);
    }

    if (options.layout_name) {
      if (!this._layouts[options.layout_name]) {
        throw new RangeError(`Layout ${options.layout_name} does not exist`);
      }
    }

    this._options = {
      ...this._options,
      ...options,
    };
  }

  /**
   * Подключить обработчики событий органов управления платой
   *
   * @private
   */
  _attachControlsEvents() {
    this.__grid.onVoltageUpdate((vt) => {
      this._layers.voltage?.setLineVoltages(vt);
      this._layers.current?.updateCurrentVoltages();
    });
    /// очистка платы
    this._layers.selector?.onClear(() => {
      this.clearPlates();

      this._callbacks.change({
        id: null,
        action: PlateChangeActionType.Clear,
      });
    });

    this._layers.controls?.onMenuClick(() => {
      this._layers.selector?.open();
    });

    if (!this._options.useCustomTheme) {
      if (this._layers.controls) {
        this._switchTheme(this._layers.controls?.getTheme());
      }

      this._layers.controls?.onThemeChange((mode) => {
        this._switchTheme(mode);
      });
    }

    this._layers.controls?.onModeRequest((mode) => {
      this._callbacks.moderequest(mode);
    });

    this._layers.selector?.onPlateTake(
      (
        plate_data: SerializedPlate,
        plate_x: number,
        plate_y: number,
        cursor_x: number,
        cursor_y: number,
      ) => {
        this._layers.plate?.takePlate(
          plate_data,
          plate_x,
          plate_y,
          cursor_x,
          cursor_y,
        );
      },
    );

    /// переключение полноэкранного режима
    this._layers.selector?.onFullscreen((on: boolean) => {
      Breadboard.fullScreen(on, this._brush.node);
    });

    /// нажатие на пункт глобального контекстного меню (платы)
    this._layers.menu?.onContextMenuItemClick(
      (item_id: number, alias: string, value: any) => {
        switch (alias) {
          case BoardContextMenu.CMI_SNAPSH_SVG:
            this._saveToImage();
            break;
          case BoardContextMenu.CMI_SNAPSH_PNG:
            this._saveToImage(true);
            break;
          case BoardContextMenu.CMI_IMPORT:
            this._importPlates(value);
            break;
          case BoardContextMenu.CMI_EXPORT:
            this._exportPlates();
            break;
          case BoardContextMenu.CMI_MOD_PHOTO:
            this.switchSchematic(false);
            break;
          case BoardContextMenu.CMI_MOD_SCHEMA:
            this.switchSchematic(true, false);
            break;
          case BoardContextMenu.CMI_MOD_DETAIL:
            this.switchSchematic(true, true);
            break;
          case BoardContextMenu.CMI_MOD_VERBOS_INP:
            this.switchVerbose(value);
            break;
          case BoardContextMenu.CMI_SELECTOR:
            this._layers.selector?.togglePin();
            break;
        }

        if (item_id != null) {
          this._layers.plate?.handlePlateContextMenuItemClick(
            item_id,
            alias,
            value,
          );
        }

        for (const layout_alias of Object.keys(this._layouts)) {
          if (alias === `layout:${layout_alias}`) {
            this._callbacks.layoutchangerequest(layout_alias);
          }
        }
      },
    );

    /// начало перетаскивания плашки
    this._layers.plate?.onDragStart(() => {
      this._callbacks.dragstart();
    });
  }

  _switchTheme(theme: Theme) {
    switch (theme) {
      case Theme.Dark: {
        this._div_wrap.setAttribute('data-theme', 'dark');
        break;
      }
      case Theme.Light: {
        this._div_wrap.setAttribute('data-theme', 'light');
        break;
      }
    }
  }

  _attachNotificationEvents() {
    this._layers.current?.onShortCircuitStart(() => {
      this._callbacks.shortcircuitstart();
    });

    this._layers.current?.onShortCircuitEnd(() => {
      this._callbacks.shortcircuitend();
    });

    this._layers.cocurrent?.onShortCircuitStart(() => {
      this._callbacks.shortcircuitstart();
    });

    this._layers.cocurrent?.onShortCircuitEnd(() => {
      this._callbacks.shortcircuitend();
    });
  }

  _attachWireEvents() {
    this._layers.wire?.onChange(() => {
      const wires = this._layers.wire?.getWires();
      const plates: SerializedPlate[] =
        wires?.map((wire) => ({
          id: wire.id,
          type: JumperPlate.Alias,
          state: { position: { cells: wire.cells.map((cell) => cell.idx) } },
          props: {},
        })) || [];

      if (isEditingAllowed(this._options.mode)) {
        // wires changed, sync back with plates (in virtual mode)
        this._layers.plate?.setJumperPlates(plates);
      }
    });
  }

  _attachPlateEvents() {
    this._layers.plate?.onChange((data) => {
      /// если не режим только чтения, подключить обработчик изменения состояния платы
      if (isEditingAllowed(this._options.mode)) {
        this._callbacks.change(data);
      } else {
        // plates changed, sync with wires (in real mode)
        this._syncJumpers();
      }
    });
  }

  _detachWireEvents() {
    this._layers.wire?.onChange();
  }

  _detachPlateEvents() {
    this._layers.plate?.onChange();
  }

  _syncJumpers() {
    const plates = this._layers.plate?.getJumperPlates();

    const wires: WireSerialized[] =
      plates
        ?.map((plate) => ({
          id: plate.id,
          cells: plate.state.position.cells.map((cell) =>
            this.__grid.getCell(cell.x, cell.y),
          ),
        }))
        .filter((wire): wire is WireSerialized => !!wire.id) || [];

    this._layers.wire?.setWires(wires);
  }

  /**
   * Импортировать плашки из файла
   *
   * @param {File} file файл, содержащий JSON-объект с информацией о состоянии платы
   * @private
   */
  _importPlates(file: File) {
    const reader = new FileReader();

    reader.readAsText(file, 'UTF-8');

    reader.onload = (evt: ProgressEvent) => {
      /* TODO */
      const plates = JSON.parse((evt.target as any).result);

      this.clearPlates();

      this.setPlates(plates);

      if (plates.length > 0) {
        this._callbacks.change({
          id: null,
          action: PlateChangeActionType.Import,
        });
      }
    };
  }

  /**
   * Экспортировать текущее состояние платы в файл
   *
   * @private
   */
  _exportPlates() {
    const plates_str = JSON.stringify(this.getPlates());

    const file = new Blob([plates_str], { type: 'text/plain;charset=utf-8' });

    // @ts-expect-error
    if (window.navigator.msSaveOrOpenBlob) {
      // IE10+
      // @ts-expect-error
      window.navigator.msSaveOrOpenBlob(file, 'bbconfig.json');
    } else {
      // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);

      a.href = url;
      a.download = 'bbconfig.json';

      document.body.appendChild(a);

      a.click();

      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  async _saveToImage(rasterize = false) {
    const svg_node = this.getContainer();

    if (!svg_node) {
      return;
    }

    const canvas = document.createElement('canvas');
    canvas.style.minWidth = String(this._layout.wrap.x);
    canvas.style.minHeight = String(this._layout.wrap.y);

    canvas.setAttribute('width', String(this._layout.wrap.x));
    canvas.setAttribute('height', String(this._layout.wrap.y));

    document.body.appendChild(canvas);

    this.switchModePhoto(true);
    const svgString = new XMLSerializer().serializeToString(svg_node);
    this.switchModePhoto(false);

    const svg = new Blob([svgString], {
      type: 'image/svg+xml;charset=utf-8',
    });

    if (rasterize) {
      const ctx = canvas.getContext('2d');

      if (ctx) {
        const v = await Canvg.from(ctx, svgString, {
          ignoreDimensions: false,
          scaleHeight: this._layout.wrap.y,
        });

        v.start();

        const img = canvas.toDataURL('image/png');

        saveAs(img, 'breadboard.png');
      }
    } else {
      saveAs(svg, 'breadboard.svg');
    }

    canvas.remove();
  }

  _defineGradients() {
    initGradients(this._brush.group());
  }

  /**
   * Переключить полноэкранный режим отображения DOM-элемента
   *
   * @param {boolean}     on      включить полноэкранный режим?
   * @param {HTMLElement} element DOM-элемент
   */
  static fullScreen(on: boolean, element: any) {
    if (on) {
      if (element.requestFullScreen) {
        element.requestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitCancelFullScreen) {
        (document as any).webkitCancelFullScreen();
      }
    }
  }
}

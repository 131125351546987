export interface Variable {
  key: string;
  name: string;
  type: string;
  initialValue?: string | number;
  description: string;
}

export interface CodeModuleSettings {
  block_types: Record<string, number>;
  check_type: CodeCheckType;
  editable: boolean;
  max_blocks: number;
  variables: Variable[];
}

export interface BoardModuleSettings {
  check_type: BoardCheckType;
  editable: boolean;
  mode: BoardMode;
}

export interface ButtonModuleSettings {
  model: number[] | null;
}

export interface Popover {
  id: number;
  title: string;
  content: string;
}

export interface Exercise {
  id: number;
  name: string;
  type: ExerciseType;
  is_sandbox: boolean;
  message_success: string;
  task_description: string;
  // task_description_alt: string;
  layout_mode: string;
  launch_mode: LaunchMode;
  popovers: Popover[];
  module_settings: {
    board?: BoardModuleSettings;
    code?: CodeModuleSettings;
    button?: ButtonModuleSettings;
  };
}

export interface Mission {
  id: number;
  name: string;
  description: string;
  exercises: Exercise[];
}

export enum LaunchMode {
  DoNothing = 0,
  CheckOnly = 1,
  ExecuteOnly = 2,
  CheckAndExecute = 3,
}

export enum CodeCheckType {
  Commands = 0,
  States = 1,
}

export enum BoardCheckType {
  Hard = 0,
  Soft = 1,
}

export enum ExerciseType {
  CircuitAssembly = 0,
  ProgramAssembly = 1,
  ButtonPressSeq = 2,
  Combined = 3, // todo: the mode is obsolete, needs to be deprecated
  ElectronicAssembly = 4, // todo: the mode is obsolete, needs to be deprecated
  Arduino = 6,
}

export enum BoardMode {
  Default = 'default',
  Programming = 'programming',
  Electronics = 'electronics',
  Arduino = 'arduino',
}

export interface ExerciseSolution {
  code?: object;
  board?: object;
  board_info?: object;
  board_layout_name?: string;
}

export interface Lesson {
  id: number;
  name: string;
  description: string;
  language: string;
  missions: Mission[];
}

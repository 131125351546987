import { type Layout } from './core/Layout';
import {
  type PlateProps,
  type PlateState,
  type SerializedPlateState,
} from './core/Plate';

export enum BreadboardMode {
  Default = 'DEFAULT',
  Display = 'DISPLAY',
  Virtual = 'VIRTUAL',
  Real = 'REAL',
}

/**
 *
 * @category Breadboard
 */
export interface BreadboardOptions {
  layouts: Record<string, Layout>;
  layout_name: string;
  verbose: boolean;
  detailed: boolean;
  schematic: boolean;
  mode: BreadboardMode;
  bgVisible: boolean;
  showControlsDefault: boolean;
  showSourceCurrents: boolean;
  useCustomTheme: boolean;
  admin: boolean;
}

export interface AddPlateParams {
  id?: number | null;
  type: string;
  props: PlateProps;
  state: SerializedPlateState<PlateState>;
}

import { BreadboardMode } from '~/js/utils/breadboard/Breadboard.types';
import { ViewEvent } from '~/js/core/base/Event';
import { ImperativeView } from '~/js/core/base/view/ImperativeView';
import {
  type AllProps,
  deferUntilMounted,
  type IViewProps,
} from '~/js/core/base/view/View';
import {
  PlateChangeActionType,
  type SerializedFields,
} from '~/js/utils/breadboard/components/layers/PlateLayer/types';
import { type ElecData } from '~/js/utils/breadboard/core/Current';
import {
  type EmbeddedPlate,
  type Layout,
} from '~/js/utils/breadboard/core/Layout';
import {
  type ChangeCallbackArg,
  type PlateState,
  type SerializedPlate,
} from '~/js/utils/breadboard/core/Plate';
import Breadboard from '../../utils/breadboard/Breadboard';

namespace BoardView {
  export class PlateDragStartEvent extends ViewEvent<PlateDragStartEvent> {}

  export class ShortCircuitStartEvent extends ViewEvent<ShortCircuitStartEvent> {}

  export class ShortCircuitEndEvent extends ViewEvent<ShortCircuitEndEvent> {}

  export class LayoutChangeRequestEvent extends ViewEvent<LayoutChangeRequestEvent> {
    layout_name: string;
  }

  export class PlatesChangeEvent extends ViewEvent<PlatesChangeEvent> {
    plates: any[];
  }

  export class FieldsChangeEvent extends ViewEvent<FieldsChangeEvent> {
    fields: Record<number, PlateState['field']>;
  }

  export class ModeRequestEvent extends ViewEvent<ModeRequestEvent> {
    mode: BreadboardMode;
  }

  export interface BoardViewProps extends IViewProps {
    bg_visible?: boolean;
    schematic?: boolean;
    verbose?: boolean;
    admin: boolean;
    detailed?: boolean;
    mode?: BreadboardMode;
    layouts: Record<string, Layout>;
    layout_name: string;
    custom_theme: boolean;
  }

  export class BoardView extends ImperativeView<BoardViewProps> {
    static defaultProps: BoardViewProps = {
      bg_visible: true,
      schematic: true,
      detailed: true,
      admin: false,
      mode: BreadboardMode.Default,
      verbose: false,
      custom_theme: true,
      layouts: {},
      layout_name: 'default',
    };

    private readonly bb: Breadboard;

    constructor(props: AllProps<BoardViewProps>) {
      super(props);

      this.bb = new Breadboard({
        layouts: this.props.layouts,
        layout_name: this.props.layout_name,
        bgVisible: this.props.bg_visible,
        mode: this.props.mode,
        schematic: this.props.schematic,
        detailed: this.props.detailed,
        verbose: this.props.verbose,
        useCustomTheme: this.props.custom_theme,
        admin: this.props.admin,
      });

      this.bb.registerLayouts(this.props.layouts);

      this.setup();
    }

    inject(container: HTMLDivElement): void {
      this.bb.inject(container);
    }

    eject(container: HTMLDivElement): void {
      this.bb.dispose();
    }

    @deferUntilMounted
    setMode(mode: BreadboardMode) {
      this.bb.setMode(mode);
    }

    @deferUntilMounted
    setVerbose(verbose: boolean = true) {
      this.bb.switchVerbose(verbose);
    }

    @deferUntilMounted
    setRandom(
      protos: { type: string; props: any; quantity: number }[],
      size_mid?: number,
      size_deviation?: number,
      attempts_max?: number,
    ) {
      this.bb.setRandomPlates(protos, size_mid, size_deviation, attempts_max);
    }

    /**
     * @deprecated
     */
    getPlates() {
      return this.bb.getPlates();
    }

    getArduinoVariables() {
      const layout = this.bb.getElecLayout();
      const var_names = layout.emb_plates.reduce(
        (acc, eplate) =>
          eplate.type !== 'ard_pin'
            ? acc
            : [...acc, (eplate as EmbeddedPlate<any>).props.var_name as string],
        [],
      );

      return var_names;
    }

    @deferUntilMounted
    setLayout(layout_name: string) {
      this.bb.setLayout(layout_name);
    }

    @deferUntilMounted
    setPlates(plates: SerializedPlate[]) {
      if (plates == null) {
        throw new TypeError('Plates are not defined');
      }

      this.bb.clearRegions();

      return this.bb.setPlates(plates);
    }

    setFields(fields: SerializedFields) {
      this.bb.setFields(fields);
    }

    @deferUntilMounted
    highlightErrorPlates(plate_ids: number[]) {
      if (!plate_ids) {
        return true;
      }

      this.bb.highlightPlates(plate_ids);
    }

    @deferUntilMounted
    setElecData(elec_data: ElecData) {
      this.bb.setElecData(elec_data);
    }

    @deferUntilMounted
    highlightRegion(region: object, clear: boolean) {
      if (!region) {
        return false;
      }

      // @ts-expect-error
      this.bb.highlightRegion(region.from, region.to, clear, null);
    }

    @deferUntilMounted
    clearRegions() {
      this.bb.clearRegions();
    }

    render(): React.ReactNode {
      return super.render();
    }

    private setup() {
      this.bb.onDragStart(async () => {
        await this.emit(new PlateDragStartEvent({}));
      });
      this.bb.onChange(async (data: ChangeCallbackArg) => {
        if ('action' in data && data.action === PlateChangeActionType.State) {
          await this.emit(
            new FieldsChangeEvent({ fields: this.bb.getFields() }),
          );
        } else {
          await this.emit(
            new PlatesChangeEvent({ plates: this.bb.getPlates() }),
          );
        }
      });
      this.bb.onLayoutChangeRequest(async (layout_name: string) => {
        await this.emit(new LayoutChangeRequestEvent({ layout_name }));
      });
      this.bb.onShortCircuitStart(async () => {
        await this.emit(new ShortCircuitStartEvent({}));
      });
      this.bb.onShortCircuitEnd(async () => {
        await this.emit(new ShortCircuitEndEvent({}));
      });
      this.bb.onModeRequest(async (mode: BreadboardMode) => {
        await this.emit(new ModeRequestEvent({ mode }));
      });
    }
  }
}

export default BoardView;

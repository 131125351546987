import { type Layout } from '~/js/utils/breadboard/core/Layout';

export const defaultLayout: Layout = {
  name: 'default',
  wrap: { x: 1200, y: 1350 },
  size: { x: 1000, y: 1100 },
  gap: { x: 0, y: 0 },
  dim: { x: 2, y: 2 },
  pos: { x: 0, y: 0 },

  ddecls: [],
};

import type SVG from 'svg.js';
import { type Cell } from '../../core/Grid';

import { Plate } from '../../core/Plate';
import { ButtonPlate } from './ButtonPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Switch plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class TSwitchPlate extends ButtonPlate {
  static get Alias() {
    return PLATE_ALIASES.T_SWITCH;
  }

  static get Layouts() {
    return ['v5x', 'v8x'];
  }

  private jumper_on: SVG.Path;
  private jumper_off: SVG.Path;

  protected __defaultAttrs__(): any {
    return {
      ...super.__defaultAttrs__(),

      size: { x: 3, y: 2 },
      surface: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 1, y: 1 },
      ],
      origin: { x: 0, y: 0 },
    };
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    if (this.options.verbose) {
      this._redrawInput(this.state.field);
    }

    this._toggleJumper(true);
  }

  /**
   * @inheritdoc
   */
  protected _toggleJumper(force = false) {
    if (!this._is_drawn && !force) {
      return;
    }

    if (this.state.field) {
      this.jumper_off.hide();
      this.jumper_on.show();
    } else {
      this.jumper_off.show();
      this.jumper_on.hide();
    }
  }

  /**
   * Draws a switch over the plate surface
   *
   * @param {number} qs size of squares
   */
  protected _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(2, 0);
    const cell3 = this.__grid.getCell(1, 1);

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);
    const rect3 = this._group.rect(qs, qs);

    rect1
      .cx(cell1.center_rel.x)
      .cy(cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .cx(cell2.center_rel.x)
      .cy(cell2.center_rel.y)
      .addClass('bb-plate-fill');
    rect3
      .cx(cell3.center_rel.x)
      .cy(cell3.center_rel.y)
      .addClass('bb-plate-fill');

    const contact_point = {
      x: cell3.center_rel.x,
      y: cell1.rel.y + cell1.size.y,
    };

    const line_right = this._group.path([
      ['M', cell1.center_rel.x, cell1.center_rel.y],
      ['H', cell3.rel.x],
    ]);

    const line_left = this._group.path([
      ['M', cell2.center_rel.x, cell2.center_rel.y],
      ['H', cell3.rel.x + cell3.size.x],
    ]);

    const line_middle = this._group.path([
      ['M', cell3.center_rel.x, cell3.center_rel.y],
      ['V', contact_point.y],
    ]);

    this.jumper_off = this._group.path([
      ['M', cell3.center_rel.x, cell3.center_rel.y],
      ['L', contact_point.x, contact_point.y],
      ['L', cell1.center_rel.x + cell1.size.x, cell1.center_rel.y],
      ['L', cell1.center_rel.x, cell1.center_rel.y],
    ]);

    this.jumper_on = this._group.path([
      ['M', cell3.center_rel.x, cell3.center_rel.y],
      ['L', contact_point.x, contact_point.y],
      ['L', cell2.center_rel.x - cell2.size.x, cell2.center_rel.y],
      ['L', cell2.center_rel.x, cell2.center_rel.y],
    ]);

    line_right.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');
    line_left.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');
    line_middle.addClass('bb-plate-stroke').stroke({ width: 3 }).fill('none');
    this.jumper_off
      .addClass('bb-plate-stroke')
      .stroke({ width: 3 })
      .fill('none');
    this.jumper_on
      .addClass('bb-plate-stroke')
      .stroke({ width: 3 })
      .fill('none');

    this._toggleJumper();
  }
}

import { CATEGORIES, FIELDTYPES } from '../constants';

import i18next from 'i18next';

const JSONBlocks = {
  logic_neg: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: '%{BKY_LOGIC_NEGATE_TITLE}',
        args0: [
          {
            type: 'input_value',
            name: 'BOOL',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: '%{BKY_LOGIC_NEGATE_TOOLTIP}',
      });
      // appendShadowBlock(this, "BOOL", "logic_boolean");
    },
  },
  logic_eq: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: '%1 == %2',
        args0: [
          {
            type: 'input_value',
            name: 'A',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
          {
            type: 'input_value',
            name: 'B',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: i18next.t('blockly:blocks.logic_eq.tooltip'),
      });
      // appendShadowBlock(this, "A", "logic_boolean");
      // appendShadowBlock(this, "B", "logic_boolean");
    },
  },
  logic_lt: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: '%1 < %2',
        args0: [
          {
            type: 'input_value',
            name: 'A',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
          {
            type: 'input_value',
            name: 'B',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: i18next.t('blockly:blocks.logic_lt.tooltip'),
      });
      // appendShadowBlock(this, "A", "logic_boolean");
      // appendShadowBlock(this, "B", "logic_boolean");
    },
  },
  logic_gt: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: '%1 > %2',
        args0: [
          {
            type: 'input_value',
            name: 'A',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
          {
            type: 'input_value',
            name: 'B',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: i18next.t('blockly:blocks.logic_gt.tooltip'),
      });
      // appendShadowBlock(this, "A", "logic_boolean");
      // appendShadowBlock(this, "B", "logic_boolean");
    },
  },
  logic_ge: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: '%1 >= %2',
        args0: [
          {
            type: 'input_value',
            name: 'A',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
          {
            type: 'input_value',
            name: 'B',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: i18next.t('blockly:blocks.logic_ge.tooltip'),
      });
      // appendShadowBlock(this, "A", "logic_boolean");
      // appendShadowBlock(this, "B", "logic_boolean");
    },
  },
  logic_le: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: '%1 <= %2',
        args0: [
          {
            type: 'input_value',
            name: 'A',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
          {
            type: 'input_value',
            name: 'B',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: i18next.t('blockly:blocks.logic_le.tooltip'),
      });
      // appendShadowBlock(this, "A", "logic_boolean");
      // appendShadowBlock(this, "B", "logic_boolean");
    },
  },
  logic_and: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: i18next.t('blockly:blocks.logic_and.message'),
        args0: [
          {
            type: 'input_value',
            name: 'A',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
          {
            type: 'input_value',
            name: 'B',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: i18next.t('blockly:blocks.logic_and.tooltip'),
      });
      // appendShadowBlock(this, "A", "logic_boolean");
      // appendShadowBlock(this, "B", "logic_boolean");
    },
  },
  logic_or: {
    init: function () {
      this.jsonInit({
        type: 'block_type',
        message0: i18next.t('blockly:blocks.logic_or.message'),
        args0: [
          {
            type: 'input_value',
            name: 'A',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
          {
            type: 'input_value',
            name: 'B',
            check: [FIELDTYPES.BOOL, FIELDTYPES.NUMBER],
          },
        ],
        inputsInline: true,
        output: FIELDTYPES.BOOL,
        colour: CATEGORIES.LOGIC.colour,
        tooltip: i18next.t('blockly:blocks.logic_or.tooltip'),
      });
      // appendShadowBlock(this, "A", "logic_boolean");
      // appendShadowBlock(this, "B", "logic_boolean");
    },
  },
};

export default JSONBlocks;

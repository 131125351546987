export enum ThemeMode {
  System,
  Dark,
  Light,
}

export enum Theme {
  Dark,
  Light,
}

import { type Cell } from '../../core/Grid';
import { Plate, type PlateProps, type PlateState } from '../../core/Plate';
import { LinearPlate } from '../../core/Plate/LinearPlate';
import { PLATE_ALIASES } from './constants';

type PrototypePlateProps = PlateProps & {
  sampleProp: number;
};

type PrototypePlateState = PlateState & {
  field: number;
};

/**
 * This is the boilerplate code to define new plate type.
 *
 * To register the plate in the library, some additional actions should be done:
 * - Add the plate to the `PlateLayer::typeToPlateClass` function,
 *   which is used in import/export routines
 * - To add the plate to the UI menu, add the item to `ITEMS` constant exported
 *   by `plates/_selector_items.ts`, which is handled by `MenuLayer`
 */
export class PrototypePlate extends LinearPlate<
  PrototypePlateProps,
  PrototypePlateState
> {
  /**
   * Define your custom alias here
   */
  static get Alias() {
    return PLATE_ALIASES.PROTOTYPE;
  }

  protected get __inheritProps__() {
    return {
      sampleProp: 0,
    };
  }

  protected get __inheritState__() {
    return {
      field: 0,
    };
  }

  /**
   * Define your custom length here
   */
  protected get __length__() {
    return 3;
  }

  /**
   * Define your custom picture here
   * Use `this.props` to customize
   */
  protected __draw__(position: Cell, orientation: string) {
    const size = Plate.LabelFontSizePreferred;

    // Make the plate opaque if needed
    this._bezel.fill({ color: '#fffffd', opacity: 1 });

    this._group
      .text('prototype')
      .font({
        size,
        family: Plate.CaptionFontFamily,
        weight: Plate.CaptionFontWeight,
      })
      .cx(this._container.width() / 2)
      .cy(this._container.height() / 2)
      .stroke({ width: 0.5 });
  }
}

import { type ElecLineTable, type EmbeddedPlate } from '../Layout';
import { type AuxPoint } from '~/js/utils/breadboard/core/Grid/Cell';

/**
 * Type of 'out-of-bound' behavour for cell search functions
 *
 * When a function receives indices that go over the boundaries of a {@link Grid},
 * it can throw an exception or consider a fallback for invalid index.
 * To define typical behavior which may be required to follow, this type should be used.
 *
 * @category Breadboard
 */
export enum BorderType {
  None = 'none', //           throw an exception / do nothing              | [1 2 3 E E E]
  Replicate = 'replicate', // 'continue' the last index available          | [1 2 3 3 3 3]
  //                          for each dimenision
  Reflect = 'reflect', //     reverse index sequence from the boundary     | [1 2 3 3 2 1]
  Wrap = 'wrap', //           loop index sequence from the boundary        | [1 2 3 1 2 3]
}

/**
 * Types of auxiliary points, which has non-regular (martix) positions,
 * that can be used on the board
 *
 * Each point can be specified in the {@link Grid} only within a corresponding {@link AuxPointType}.
 *
 * @category Breadboard
 */
export enum AuxPointType {
  // Voltage and ground pins
  Vcc = 'vcc',
  Gnd = 'gnd',

  // USB 1 pins
  U1Vcc = 'u1_vcc',
  U1Gnd = 'u1_gnd',
  U1Analog1 = 'u1_a1',
  U1Analog2 = 'u1_a2',

  // USB 3 pins
  U3Vcc = 'u3_vcc',
  U3Gnd = 'u3_gnd',
  U3Analog1 = 'u3_a1',
  U3Analog2 = 'u3_a2',
}

/**
 * Categories
 *
 * @category Breadboard
 */
export enum AuxPointCategory {
  // Source pins for 5th revision of the board
  SourceV5 = 'source-v5',
  // Source pins for 8th revision of the board
  SourceV8 = 'source-v8',
  // USB1 pins
  Usb1 = 'usb1',
  // USB3 pins
  Usb3 = 'usb3',
}

/**
 * A set of fixed properties of the {@link Grid}
 *
 * @category Breadboard
 */
export interface GridParams {
  dim: { x: number; y: number };
  size: { x: number; y: number };
  gap: { x: number; y: number };
  pos: { x: number; y: number };
  wrap: { x: number; y: number };
  name: string;
  isRestrictPlates: boolean;
  isRestrictWires: boolean;
}

export type AuxPointOrRow<K> = K extends string ? AuxPoint : AuxPoint[];
export type AuxPointMap = Map<string | number, AuxPoint | AuxPoint[]>;

export interface ElecLayout {
  emb_plates: EmbeddedPlate[];
  cell_struct: ElecLineTable;
}

import {
  BatteryPlate,
  BridgePlate,
  ButtonPlate,
  BuzzerPlate,
  CapacitorPlate,
  DummyPlate,
  InductorPlate,
  LEDPlate,
  LEDPlateColor,
  PhotoresistorPlate,
  RelayPlate,
  ResistorPlate,
  RGBPlate,
  TRheostatPlate,
  TSwitchPlate,
  TransistorNpnPlate,
  TransistorPnpPlate,
  RheostatPlate,
  ThermoresistorPlate,
  PhototransistorPlate,
  ReedSwitchPlate,
} from '../../plates/index';
import { type SelectorItem } from './types';
import { IndicatorPlate } from '~/js/utils/breadboard/components/plates/IndicatorPlate';
import { DiodePlate } from '~/js/utils/breadboard/components/plates/DiodePlate';

/**
 * Items for {@link SelectorLayer}'s flyout menu
 *
 * @category Breadboard
 */
export const SELECTOR_ITEMS: SelectorItem[] = [
  {
    title: 'Перемычка',
    type: BridgePlate,
    tags: 'перемычка мост bridge gthtvsxrf vjcn икшвпу',
    options: [
      { title: '2 клетки', props: { length: 2 } },
      { title: '3 клетки', props: { length: 3 } },
      { title: '4 клетки', props: { length: 4 } },
      { title: '5 клеток', props: { length: 5 } },
      { title: '6 клеток', props: { length: 6 } },
    ],
    custom: {
      default: {
        title: 'Свой размер',
        props: { length: 2 },
      },
      property_key: 'length',
    },
  },
  {
    title: 'Светодиод',
    tags: 'светодиод лампа свет led diode light cdtnjlbjl kfvgf cdtn дув вшщву дшпре',
    type: LEDPlate,
    options: [
      {
        title: 'Красный',
        props: { color: LEDPlateColor.Red },
      },
      {
        title: 'Зелёный',
        props: { color: LEDPlateColor.Green },
      },
      {
        title: 'Синий',
        props: { color: LEDPlateColor.Blue },
      },
    ],
  },
  {
    title: 'Диод',
    tags: 'диод diode lbjl вшщву',
    type: DiodePlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Резистор',
    tags: 'резистор сопротивление resistor htpbcnjh cjghjnbdktybt куышыещк',
    type: ResistorPlate,
    options: [
      {
        title: '200 Ом',
        props: { resistance: 200 },
      },
      {
        title: '1 кОм',
        props: { resistance: 1000 },
      },
      {
        title: '10 кОм',
        props: { resistance: 10000 },
      },
      {
        title: '30 кОм',
        props: { resistance: 30000 },
      },
    ],
    custom: {
      default: {
        title: 'Свой номинал (кОм)',
        props: { resistance: 100 },
      },
      property_key: 'resistance',
    },
  },
  {
    title: 'Конденсатор',
    tags: 'конденсатор ёмкость емкость capacitor rjyltycfnjh `vrjcnm tvrjcnm сфзфсшещк',
    type: CapacitorPlate,
    options: [
      {
        title: '100 мкФ',
        props: { capacitance: 1e-4 },
      },
      {
        title: '1000 мкФ',
        props: { capacitance: 1e-3 },
      },
    ],
    custom: {
      default: {
        title: 'Своя ёмкость (пкФ)',
        props: { capacitance: 200 },
      },
      property_key: 'capacitance',
    },
  },
  {
    title: 'Транзистор NPN',
    tags: 'транзистор нпн transistor npn nhfypbcnjh ygy екфтышыещк тзт',
    type: TransistorNpnPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Транзистор PNP',
    tags: 'транзистор пнп transistor pnp nhfypbcnjh gyg екфтышыещк зтз',
    type: TransistorPnpPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Фототранзистор',
    tags: 'фототранзистор phototransistor ajnjnhfypbcnjh зрщещекфтышыещк',
    type: PhototransistorPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Фоторезистор',
    tags: 'фоторезистор photoresistor ajnjhtpbcnjh зрщещкуышыещк',
    type: PhotoresistorPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Терморезистор',
    tags: 'терморезистор thermoresistor nthvjhtpbcnjh ерукьщкуышыещк',
    type: ThermoresistorPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Реостат',
    tags: 'реостат резистор переменный rheostat resistor variable htjcnfn htpbcnjh gthtvtyysq крущыефе куышыещк',
    type: TRheostatPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Реостат',
    tags: 'реостат резистор переменный rheostat resistor variable htjcnfn htpbcnjh gthtvtyysq крущыефе куышыещк',
    type: RheostatPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Кнопка',
    tags: 'кнопка button ryjgrf игввещт',
    type: ButtonPlate,
    options: [{ title: 'Обычная' }],
  },
  {
    title: 'Ключ',
    tags: 'ключ switch rk.x ыцшеср',
    type: TSwitchPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Геркон',
    tags: 'геркон reed switch uthrjy куув ыцшеср',
    type: ReedSwitchPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Индуктор',
    tags: 'индуктор индуктивность катушка inductor inductance coil bylernjh bylernbdyjcnm rfneirf штвгсещк сщшд',
    type: InductorPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Реле',
    tags: 'реле relay htkt кудфн',
    type: RelayPlate,
    options: [{ title: 'Обычное' }],
  },
  {
    title: 'RGB',
    tags: 'ргб диод rgb diode hu, lbjl кпи вшщву',
    type: RGBPlate,
    options: [{ title: 'Обычная' }],
  },
  {
    title: 'Индикатор',
    tags: 'индикатор indicator bylbrfnjh штвшсфещк',
    type: IndicatorPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Зуммер',
    tags: 'зуммер пищалка buzzer beeper pevvth gbofkrf игяяук иуузук',
    type: BuzzerPlate,
    options: [{ title: 'Обычный' }],
  },
  {
    title: 'Батарейка',
    tags: 'батарейка battery ,fnfhtqrf ифееукн',
    type: BatteryPlate,
    options: [{ title: 'Обычная' }],
  },
  {
    title: 'Dummy',
    tags: 'dummy вгььн',
    type: DummyPlate,
    options: [{ title: 'Обычная' }],
  },
];

import { VAR_DEFAULT } from './constants';
import i18next from 'i18next';

const GLOBALS = { VARS: [] };

export function setGlobals(globals) {
  for (const [key, value] of Object.entries(globals)) {
    GLOBALS[key] = value;
  }
}

export function getGlobals() {
  return GLOBALS;
}

export function getVars() {
  const vars = getGlobals().VARS;

  // remove duplicates, format:
  // {key: string, name: string, type: string, initialValue: string, validations?: {min?: number, max?: number}}
  const unique = vars
    .reduce(
      (prev, curr) =>
        prev.findIndex((v) => v.key === curr.key) === -1
          ? [...prev, curr]
          : prev,
      [],
    )
    .map((v) => {
      return { ...v, name: i18next.t(v.name, v.name) };
    });

  return unique.length ? unique : [VAR_DEFAULT];
}

import { type Cell } from '../../core/Grid';
import { Plate } from '../../core/Plate';
import { PLATE_ALIASES } from './constants';

/**
 * Dummy plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class DummyPlate extends Plate {
  static get Alias() {
    return PLATE_ALIASES.DUMMY;
  }

  protected __defaultAttrs__(): any {
    return {
      ...super.__defaultAttrs__(),

      size: { x: 1, y: 1 },
    };
  }

  protected get __inheritProps__() {
    return {};
  }

  protected get __inheritState__() {
    return {};
  }

  /**
   * Draws a plate without a bezel
   */
  protected __draw__(position: Cell, orientation: string) {
    this._bezel.fill('none').stroke({ color: 'none', opacity: 0 });

    this._drawPicture();
  }

  public deselect() {
    super.deselect();

    this._bezel.stroke({ color: 'none', opacity: 0 });
  }

  /**
   * Draws an animated dot
   *
   * @param qs size of the dot
   */
  private _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(this.attrs.size.x - 1, 0);

    const center_x = cell1.center_rel.x + (cell2.pos.x - cell1.pos.x) / 2;
    const center_y = cell1.center_rel.y;

    const radius = qs * 1.5;

    const dot = this._group
      .circle(radius)
      .center(center_x, center_y)
      .fill('#ff3a47');

    (dot.animate(1200) as any).radius(0).loop();
  }
}

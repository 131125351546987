import type SVG from 'svg.js';
import { type Grid } from '../../../core/Grid';

import { Layer, type LayerOptions } from '../../../core/Layer';
import { type XYPoint } from '../../../types';

/**
 * Highlights rectangular cell regions to point out user failures
 *
 * @category Breadboard
 * @subcategory Layers
 */
export class RegionLayer extends Layer {
  /** CSS class of the layer */
  static get Class() {
    return 'bb-layer-region';
  }

  /** layer's main SVG container */
  protected _container: SVG.Container;

  /** SVG group for highighters */
  private _regiongroup: any;

  /**
   * @inheritdoc
   */
  constructor(container: SVG.Container, grid: Grid, options: LayerOptions) {
    super(container, grid, options);

    this._container.addClass(RegionLayer.Class);

    this._regiongroup = undefined;
  }

  /**
   * @inheritdoc
   */
  public __compose__() {
    this._drawRegions();
  }

  /**
   * Highlight single cell region
   *
   * @param src  position of the first highlighter corner
   * @param dst    position of the second highlighter corner
   * @param clear remove prevously created highighters
   * @param color color of the highlighter
   */
  public highlightRegion(
    src: XYPoint,
    dst: XYPoint,
    clear: boolean = false,
    color: string = '#d40010',
  ) {
    if (clear) {
      this._regiongroup.clear();
    }

    if (!src || !dst) {
      return false;
    }

    if (src.x == null || src.y == null) {
      return false;
    }
    if (dst.x == null || dst.y == null) {
      return false;
    }

    if (src.x >= this.__grid.dim.x || dst.x >= this.__grid.dim.x) {
      throw new RangeError("X coordinate does not fit the grid's dimension");
    }

    if (src.y >= this.__grid.dim.y || dst.y >= this.__grid.dim.y) {
      throw new RangeError("Y coordinate does not fit the grid's dimension");
    }

    const cell_src = this.__grid.getCell(src.x, src.y);
    const cell_dst = this.__grid.getCell(dst.x, dst.y);

    const width =
      Math.abs(cell_src.pos.x - cell_dst.pos.x) +
      cell_src.size.x +
      this.__grid.gap.x * 2;
    const height =
      Math.abs(cell_src.pos.y - cell_dst.pos.y) +
      cell_src.size.y +
      this.__grid.gap.y * 2;

    this._regiongroup
      .rect(width, height)
      .move(
        cell_src.pos.x - this.__grid.gap.x,
        cell_src.pos.y - this.__grid.gap.y,
      )
      .radius(10)
      .fill({ color, opacity: 0.2 })
      .stroke({ color, width: 2 })
      .scale(1.2)
      .animate(250, '<>', 0)
      .scale(1)
      .animate(1000)
      .fill({ opacity: 0.6 })
      .loop(100000, true);
  }

  /**
   * Remove all the highlighters created
   *
   * This action will be done automatically if a new region is added via {@link highlightRegion} by default.
   */
  public clearRegions() {
    this._regiongroup.clear();
  }

  /**
   * Initializes internal SVG group to draw highlighter elements inside
   */
  private _drawRegions() {
    this._regiongroup = this._container.group().id('regiongroup');
    // this._regiongroup.move(100, 170);
  }

  /**
   * Highlight all cells occupied by plate in the {@link Grid}
   *
   * This method might be useful when debugging plate behavior.
   */
  private _highlightOccupiedCells() {
    this._regiongroup.clear();

    for (const col of this.__grid.cells) {
      for (const cell of col) {
        if (cell.occupied) {
          this.highlightRegion(cell.idx, cell.idx);
        }
      }
    }
  }
}

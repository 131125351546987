import type SVG from 'svg.js';
import { PlateLayer } from '../../components/layers';
import { type XYPoint } from '../../types';
import { type Grid } from '../Grid';
import { Orientation } from './constants';
import { type PlateProps } from './types';

const MAP_ROTATION_TO_ORIENTATION: Record<number, Orientation> = {
  0: Orientation.West,
  90: Orientation.South,
  180: Orientation.East,
  270: Orientation.North,
};

/**
 * Converts given cells to position and rotation
 * relative to its original surface and rotation origin
 * (if possible)
 *
 * @param cells translated and rotated surface
 * @param surface original surface
 * @param origin point of relative rotation
 *
 * @returns position and rotation (if possible)
 */
export const convertCellsToPosition = (
  cells: XYPoint[],
  surface: XYPoint[],
  origin: XYPoint,
) => {
  let rotated = surface;

  for (let degpow = 0; degpow < 360; degpow += 90) {
    const diff = cellsDiff(rotated, cells);

    if (diff) {
      return { cell: diff, orientation: MAP_ROTATION_TO_ORIENTATION[degpow] };
    }

    rotated = rotateCells(rotated, origin);
  }

  console.error('Cannot convert cells to position', cells, surface, origin);
  throw new Error('Cannot convert cells to position');
};

const rotateCells = (cells: XYPoint[], origin: XYPoint) => {
  return cells.map((cell) => {
    const relLoc = { x: cell.x - origin.x, y: cell.y - origin.y };

    return { x: -relLoc.y + origin.x, y: relLoc.x + origin.y };
  });
};

const translateCells = (cells: XYPoint[], position: XYPoint) => {
  return cells.map(({ x, y }) => ({ x: x + position.x, y: y + position.y }));
};

/**
 * Searches common point of translation between two unordered sets of cells
 *
 * If the point exists, both sets are linearly translated in 2D space.
 * If not, the sets non-compatible or rotated.
 *
 * @param cellsFrom
 * @param cellsTo
 * @returns XY-translation of cellsFrom to cellsTo, if exists
 */
const cellsDiff = (
  cellsFrom: XYPoint[],
  cellsTo: XYPoint[],
  sorted = false,
) => {
  if (cellsFrom.length !== cellsTo.length) {
    console.error('cellsDiff', cellsFrom, cellsTo);
    throw new Error(
      'Cannot convert cells to position: incompatible cells lengths',
    );
  }

  const cellsFromSorted = !sorted
    ? cellsFrom
    : cellsFrom.sort((a, b) => a.x - b.x + a.y - b.y);
  const cellsToSorted = !sorted
    ? cellsTo
    : cellsTo.sort((a, b) => a.x - b.x + a.y - b.y);

  let diff: XYPoint | undefined;

  for (const i in cellsFromSorted) {
    const localDiff: XYPoint = {
      x: cellsToSorted[i].x - cellsFromSorted[i].x,
      y: cellsToSorted[i].y - cellsFromSorted[i].y,
    };

    if (!diff || (diff.x === localDiff.x && diff.y === localDiff.y)) {
      diff = localDiff;
      continue;
    } else {
      return undefined;
    }
  }

  return diff;
};

export const convertPositionToCellsExternal = (
  svg: SVG.Container,
  grid: Grid,
  type: string,
  props: PlateProps,
  position: XYPoint,
  orientation: Orientation,
) => {
  const plate_class = PlateLayer.typeToPlateClass(type);

  const plate = new plate_class({
    container: svg,
    grid,
    props,
  });

  const surface = plate.attrs.surface;
  const origin = plate.attrs.origin;

  return convertPositionToCells(surface, origin, position, orientation);
};

export const convertPositionToCells = (
  surface: XYPoint[],
  origin: XYPoint,
  position: XYPoint,
  orientation: Orientation,
): XYPoint[] => {
  let rotations;

  switch (orientation) {
    case Orientation.West: {
      rotations = 0;
      break;
    }
    case Orientation.South: {
      rotations = 1;
      break;
    }
    case Orientation.East: {
      rotations = 2;
      break;
    }
    case Orientation.North: {
      rotations = 3;
      break;
    }
    default: {
      rotations = 0;
    }
  }

  let rotated = surface;

  for (let i = 0; i < rotations; i++) {
    rotated = rotateCells(rotated, origin);
  }

  return translateCells(rotated, position);
};

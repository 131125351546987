import type HttpDatasource from '~/js/core/models/datasources/HttpDatasource';
import { RequestMethod } from '~/js/core/models/datasources/HttpDatasource';
import { type ModelState } from '~/js/core/base/model/Model';
import { HttpModel } from './HttpModel';

/**
 * @category Core.Models
 */
export interface JWTAuthData {
  token: string;
}

/**
 * @category Core.Models
 */
export interface JWTRefreshData {
  refresh_token?: string;
}

/**
 * @category Core.Models
 */
export default abstract class JWTAuthModel<
  MS extends ModelState,
> extends HttpModel<MS, HttpDatasource> {
  public async login(username: string, password: string): Promise<JWTAuthData> {
    const data = { username, password };

    const { jwt_token } = await this.request('api/login', {
      data,
      method: RequestMethod.POST,
    });

    return { token: jwt_token };
  }

  public async refresh(): Promise<JWTRefreshData> {
    return { refresh_token: undefined };
  }
}

import type SVG from 'svg.js';
import { type Cell } from '../../core/Grid';

import { Plate } from '../../core/Plate';
import { ButtonPlate } from './ButtonPlate';
import { PLATE_ALIASES } from './constants';

/**
 * Switch plate
 *
 * @category Breadboard
 * @subcategory Plates
 */
export class SwitchPlate extends ButtonPlate {
  static get Alias() {
    return PLATE_ALIASES.SWITCH;
  }

  static get Layouts() {
    return ['v9x', 'v10.3'];
  }

  private jumper_on: SVG.Path;
  private jumper_off: SVG.Path;

  protected get __length__() {
    return 3;
  }

  /**
   * @inheritdoc
   */
  protected __draw__(position: Cell, orientation: string) {
    this._drawPicture();

    if (this.options.verbose) {
      this._redrawInput(this.state.field);
    }

    this._toggleJumper(true);
  }

  /**
   * @inheritdoc
   */
  protected _toggleJumper(force = false) {
    if (!this._is_drawn && !force) {
      return;
    }

    if (!this.state.field) {
      this.jumper_off.stroke({ dasharray: '3 3', dashoffset: 0 });
      this.jumper_on.stroke({ dasharray: '' });
    } else {
      this.jumper_off.stroke({ dasharray: '' });
      this.jumper_on.stroke({ dasharray: '3 3', dashoffset: 0 });
    }
  }

  /**
   * Draws a switch over the plate surface
   *
   * @param {number} qs size of squares
   */
  protected _drawPicture(qs = Plate.QuadSizePreferred) {
    const cell1 = this.__grid.getCell(0, 0);
    const cell2 = this.__grid.getCell(1, 0);
    const cell3 = this.__grid.getCell(2, 0);

    const rect1 = this._group.rect(qs, qs);
    const rect2 = this._group.rect(qs, qs);
    const rect3 = this._group.rect(qs, qs);

    rect1
      .cx(cell1.center_rel.x)
      .cy(cell1.center_rel.y)
      .addClass('bb-plate-fill');
    rect2
      .cx(cell2.center_rel.x)
      .cy(cell2.center_rel.y)
      .addClass('bb-plate-fill');
    rect3
      .cx(cell3.center_rel.x)
      .cy(cell3.center_rel.y)
      .addClass('bb-plate-fill');

    const line_height = this._container.height() / 4;

    const line_right = this._group.path([
      ['M', cell1.center_rel.x, cell2.center_rel.y],
      ['L', cell1.center_rel.x, line_height],
      ['H', cell2.center_rel.x - 20],
    ]);

    const line_left = this._group.path([
      ['M', cell3.center_rel.x, cell3.center_rel.y],
      ['L', cell3.center_rel.x, line_height],
      ['H', cell2.center_rel.x + 20],
    ]);

    this.jumper_off = this._group.path([
      ['M', cell2.center_rel.x + 20, line_height],
      ['L', cell2.center_rel.x, cell2.center_rel.y],
    ]);

    this.jumper_on = this._group.path([
      ['M', cell2.center_rel.x - 20, line_height],
      ['L', cell2.center_rel.x, cell2.center_rel.y],
    ]);

    line_right.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');
    line_left.addClass('bb-plate-stroke').stroke({ width: 2 }).fill('none');

    this.jumper_off
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');
    this.jumper_on
      .addClass('bb-plate-stroke')
      .stroke({ width: 2 })
      .fill('none');

    this._toggleJumper();
  }
}

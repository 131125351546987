import { PopoverContentUpdateEvent } from '~/js/models/lesson/LessonModel/events';
import Presenter, { on, restore } from '../../core/base/Presenter';
import type RichTextView from '../../views/common/RichTextView';

export default class PopoverContentLessonPresenter extends Presenter<RichTextView.RichTextView> {
  @restore()
  @on(PopoverContentUpdateEvent)
  private showPopover(evt: PopoverContentUpdateEvent) {
    this.setViewProps({ content: evt.content });
  }
}

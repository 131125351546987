import Blockly from 'blockly';

const BlocklyJSONGenerators = {
  math_mul: (block) => {
    const a =
      Blockly.JSON.valueToCode(block, 'A', Blockly.JSON.ORDER_MULTIPLICATION) ||
      '0';
    const b =
      Blockly.JSON.valueToCode(block, 'B', Blockly.JSON.ORDER_MULTIPLICATION) ||
      '0';

    return [a + ' * ' + b, Blockly.JSON.ORDER_MULTIPLICATION];
  },

  math_add: (block) => {
    const a =
      Blockly.JSON.valueToCode(block, 'A', Blockly.JSON.ORDER_ADDITION) || '0';
    const b =
      Blockly.JSON.valueToCode(block, 'B', Blockly.JSON.ORDER_ADDITION) || '0';

    return [a + ' + ' + b, Blockly.JSON.ORDER_SUBTRACTION];
  },

  math_sub: (block) => {
    const a =
      Blockly.JSON.valueToCode(block, 'A', Blockly.JSON.ORDER_SUBTRACTION) ||
      '0';
    const b =
      Blockly.JSON.valueToCode(block, 'B', Blockly.JSON.ORDER_SUBTRACTION) ||
      '0';

    return [a + ' - ' + b, Blockly.JSON.ORDER_SUBTRACTION];
  },

  math_div: (block) => {
    const a =
      Blockly.JSON.valueToCode(block, 'A', Blockly.JSON.ORDER_DIVISION) || '0';
    const b =
      Blockly.JSON.valueToCode(block, 'B', Blockly.JSON.ORDER_DIVISION) || '0';

    return [a + ' / ' + b, Blockly.JSON.ORDER_DIVISION];
  },

  math_mod: (block) => {
    const a =
      Blockly.JSON.valueToCode(block, 'A', Blockly.JSON.ORDER_DIVISION) || '0';
    const b =
      Blockly.JSON.valueToCode(block, 'B', Blockly.JSON.ORDER_DIVISION) || '0';

    return [a + ' % ' + b, Blockly.JSON.ORDER_DIVISION];
  },

  math_number: (block) => {
    const num = block.getFieldValue('NUM');

    return [num, Blockly.JSON.ORDER_ATOMIC];
  },
};

BlocklyJSONGenerators.math_number_index = BlocklyJSONGenerators.math_number;
BlocklyJSONGenerators.math_number_seconds = BlocklyJSONGenerators.math_number;
BlocklyJSONGenerators.math_number_brightness =
  BlocklyJSONGenerators.math_number;
BlocklyJSONGenerators.math_number_repeats = BlocklyJSONGenerators.math_number;
BlocklyJSONGenerators.math_number_pwm = BlocklyJSONGenerators.math_number;

export default BlocklyJSONGenerators;

import type SVG from 'svg.js';
import { BackgroundLayer, LabelLayer, VoltageLayer, WireLayer } from '..';
import { type Grid } from '../../../core/Grid';
import { Layer, type LayerOptions } from '../../../core/Layer';
import { BoardContextMenu } from '../../menus';

import { isEditingAllowed } from '~/js/utils/breadboard/Breadboard.helpers';
import {
  BOARD_MODE_TO_TEXT,
  THEME_MODE_TO_TEXT,
} from '~/js/utils/breadboard/components/layers/ControlsLayer/constants';
import {
  Theme,
  ThemeMode,
} from '~/js/utils/breadboard/components/layers/ControlsLayer/types';
import { BreadboardMode } from '../../../Breadboard.types';
import './styles.less';

let themeMode = ThemeMode.System;

/**
 * Displays menu button and handles events to
 * open the {@link BoardContextMenu}
 *
 * @category Breadboard
 * @subcategory Layers
 */
export class ControlsLayer extends Layer {
  /** CSS class of the layer */
  static get Class() {
    return 'bb-layer-controls';
  }

  /** HTML menu button id */
  static get MenuButtonId() {
    return 'bb-btn-menu';
  }

  static get ThemeButtonId() {
    return 'bb-btn-theme';
  }

  /** layer's main SVG container */
  protected _container: SVG.Container;

  /** Board context menu instance that is currently visible */
  private _menu: BoardContextMenu;

  /** Layout list for context menu */
  private _layouts: { name: string; title: string }[];

  /** SVG group for button details */
  private _menubuttongroup: SVG.Nested;
  private _themebuttongroup: SVG.Nested;
  private _modebuttongroup: SVG.Nested;

  private _theme_mode_text: SVG.Text;

  private _layout_text?: SVG.Text;
  private _mode_text?: SVG.Text;

  private _mode_btns: Record<BreadboardMode, SVG.Nested>;
  private _mode_btn_rects: Record<BreadboardMode, SVG.Rect>;

  /** Event handlers */
  private readonly _callbacks: {
    menuclick: () => void;
    themechange: (theme: Theme) => void;
    moderequest: (mode: BreadboardMode) => void;
  };

  /**
   * @inheritdoc
   */
  constructor(container: SVG.Container, grid: Grid, options: LayerOptions) {
    super(container, grid, options);

    this._container.addClass(ControlsLayer.Class);

    this._callbacks = {
      menuclick: () => {},
      themechange: () => {},
      moderequest: () => {},
    };

    this._handleContextMenu = this._handleContextMenu.bind(this);

    this._menu = new BoardContextMenu([], isEditingAllowed(this.__mode));
  }

  /**
   * Draws contents for the layer
   */
  public __compose__() {
    this._mode_btns = {} as Record<BreadboardMode, SVG.Nested>;
    this._mode_btn_rects = {} as Record<BreadboardMode, SVG.Rect>;

    this._menubuttongroup = this._container
      .nested()
      .id(ControlsLayer.MenuButtonId);

    this._themebuttongroup = this._container
      .nested()
      .id(ControlsLayer.ThemeButtonId);

    this._modebuttongroup = this._container.nested();

    // TODO: Breadboard node access should be obvious,
    // maybe it's better to pass it over base Layer class?
    this._container.node.parentNode?.addEventListener(
      'contextmenu',
      this._handleContextMenu,
      false,
    );
    // document.addEventListener('keyup', this.handleKey, false);
    this._drawMenuButton();
    this._drawThemeButton();
    this._drawModeButtons();

    this._attachEventListeners();

    this._drawLayoutText();

    // this._hide();
  }

  public setLayoutName(layout_name?: string) {
    if (this.__verbose) {
      this._layout_text
        ?.text(`layout: ${layout_name || 'n/a'}`)
        .addClass('bb-controls-caption');
    }
  }

  public setMode(mode: BreadboardMode) {
    super.setMode(mode);
  }

  public setLayouts(layout_names: { name: string; title: string }[]) {
    this._layouts = [...layout_names];

    this._menu = new BoardContextMenu(
      this._layouts,
      isEditingAllowed(this.__mode),
    );
  }

  /**
   * Toggles the appearance of the layer
   *
   * Controls are needed only when managing the board's contents,
   * but it needs to be disabled for end users.
   *
   * @param is_visible whether to make the layer visible
   */
  public setVisibility(is_visible: boolean) {
    is_visible ? this._show() : this._hide();
  }

  public toggleThemeButtonDisplay(on = true) {
    if (on) {
      this._themebuttongroup.show();
    } else {
      this._themebuttongroup.hide();
    }
  }

  /**
   * Toggles the appearance of the menu button
   *
   * @param on
   */
  public toggleMenuButtonDisplay(on = true) {
    if (on) {
      this._menubuttongroup.show();
    } else {
      this._menubuttongroup.hide();
    }
  }

  /**
   * Attaches a handler of the menu click event
   *
   * @param cb callback function as a handler to attach
   */
  public onMenuClick(cb: () => void) {
    if (!cb) {
      this._callbacks.menuclick = () => {};
      return;
    }

    this._callbacks.menuclick = cb;
  }

  public onThemeChange(cb: (theme: Theme) => void) {
    this._callbacks.themechange = cb || (() => {});
  }

  public onModeRequest(cb: (mode: BreadboardMode) => void) {
    this._callbacks.moderequest = cb || (() => {});
  }

  public getTheme(): Theme {
    if (themeMode === ThemeMode.System) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return Theme.Dark;
      }

      return Theme.Light;
    }

    if (themeMode === ThemeMode.Dark) {
      return Theme.Dark;
    }

    return Theme.Light;
  }

  protected _applyMode() {
    if (this.__mode === BreadboardMode.Default) {
      this._menubuttongroup.hide();
    } else {
      this._menubuttongroup.show();
    }

    this._menu = new BoardContextMenu(
      this._layouts || [],
      isEditingAllowed(this.__mode),
    );

    this._mode_text
      ?.text(`mode: ${this.__mode}`)
      .addClass('bb-controls-caption');

    for (const [mode, rect] of Object.entries(this._mode_btn_rects)) {
      mode === this.__mode
        ? rect.addClass('bb-controls-rect-active')
        : rect.removeClass('bb-controls-rect-active');
    }

    if (this.__verbose) {
      this._modebuttongroup.show();
    } else {
      this._modebuttongroup.hide();
    }
  }

  recompose(options: LayerOptions) {
    super.recompose(options);

    if (this.__verbose) {
      this._modebuttongroup.show();
    } else {
      this._modebuttongroup.hide();
    }
  }

  /**
   * Draws the menu button to the layer
   */
  private _drawMenuButton() {
    this._menubuttongroup
      .click(() => {
        this._callbacks.menuclick();
      })
      .style({ cursor: 'pointer' });

    this._menubuttongroup.rect(30, 25).move(20, 20).fill({ color: '#afafaf' });

    this._menubuttongroup.rect(30, 5).move(20, 20);
    this._menubuttongroup.rect(30, 5).move(20, 30);
    this._menubuttongroup.rect(30, 5).move(20, 40);
  }

  private _drawThemeButton() {
    this._themebuttongroup
      .click(() => {
        themeMode += 1;
        themeMode = themeMode % 3;
        this._theme_mode_text.text(THEME_MODE_TO_TEXT[themeMode]);
        this._callbacks.themechange(this.getTheme());
      })
      .style({ cursor: 'pointer' });

    this._themebuttongroup
      .rect(40, 40)
      .move(20, 50)
      .addClass('bb-controls-rect');
    this._themebuttongroup
      .text('THM')
      .addClass('bb-controls-caption')
      .move(25, 50);
    this._theme_mode_text = this._themebuttongroup
      .text('SYS')
      .addClass('bb-controls-caption')
      .move(25, 70);
  }

  private _drawModeButtons() {
    this._mode_btns = {} as Record<BreadboardMode, SVG.Nested>;
    this._mode_btn_rects = {} as Record<BreadboardMode, SVG.Rect>;

    let i = 0;

    for (const mode of Object.values(BreadboardMode)) {
      const btn = this._modebuttongroup
        .nested()
        .size(40, 40)
        .move(20, 100 + 40 * i);

      this._mode_btns[mode] = btn;

      this._mode_btn_rects[mode] = btn
        .rect(40, 40)
        .addClass('bb-controls-rect');

      btn
        .text((add) => {
          add.tspan('MODE');
          add.tspan(BOARD_MODE_TO_TEXT[mode]).newLine();
        })
        .x('50%')
        .y('30%')
        .addClass('bb-controls-caption')
        .addClass('bb-controls-caption-centered');

      btn
        .click(() => {
          this._callbacks.moderequest(mode);
        })
        .style({ cursor: 'pointer' });

      i += 1;
    }
  }

  private _drawLayoutText() {
    if (!this.__verbose) {
      return;
    }

    this._layout_text = this._container.text('').move(60, 0);
    this._mode_text = this._container.text('').move(60, 14);
  }

  private _show() {
    this._menubuttongroup.show();
    this._themebuttongroup.show();
  }

  private _hide() {
    this._menubuttongroup.hide();
    this._themebuttongroup.hide();
  }

  /**
   * Handles mouse clicks on the board background to call the context menu
   *
   * The handler prevents the opening of the system menu
   *
   * @param {MouseEvent} evt
   */
  private _handleContextMenu(evt: MouseEvent) {
    let el = evt.target as Element | null | undefined;

    /// Определить, является ли элемент, по которому выполнено нажатие, частью слоя
    while (
      (el = el?.parentElement) &&
      !(
        el.classList.contains(BackgroundLayer.Class) ||
        el.classList.contains(VoltageLayer.Class) ||
        el.classList.contains(LabelLayer.Class) ||
        el.classList.contains(WireLayer.Class)
      )
    ) {}

    if (el) {
      evt.preventDefault();

      if (this.__verbose || this.__admin) {
        this._callContextMenu(this._menu, { x: evt.pageX, y: evt.pageY });
      }
    }
  }

  private _attachEventListeners() {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');

    mql.onchange = () => {
      this._callbacks.themechange(this.getTheme());
    };
  }
}

import { Wire } from '~/js/utils/breadboard/core/Wire/Wire';
import {
  isRangeConnected,
  isSamePoint,
} from '~/js/utils/breadboard/helpers/points';
import { type XYPoint } from '~/js/utils/breadboard/types';
import { Cell } from '~/js/utils/breadboard/core/Grid';
import {
  type Connection,
  type ConnItem,
  type Junction,
} from '~/js/utils/breadboard/components/layers/WireLayer/types';
import cloneDeep from 'lodash/cloneDeep';
import { hasSameAxis } from '~/js/utils/breadboard/core/Grid/helpers';

export const wiresToIsolatedGroups = (wires: Wire[]) => {
  const groups: Wire[][] = [];

  for (const wire of wires) {
    const related_group = groups.find((group) =>
      group.find((group_wire) => {
        return isAdjacentConnection(wire.conn, group_wire.conn);
      }),
    );

    if (!related_group) {
      groups.push([wire]);
    } else {
      related_group.push(wire);
    }
  }

  return groups;
};

export const wiresToLinkedGroups = (wires: Wire[]) => {
  const groups: Wire[][] = [];

  let unhandled: Wire[] = [...wires];

  for (const wire of wires) {
    if (!unhandled.includes(wire)) {
      continue;
    }

    const path = findLinkedPath(wire, unhandled);
    unhandled = unhandled.filter((w) => !path.includes(w));
    groups.push(path);
  }

  return groups;
};

export function findLinkedPath(
  head: Connection,
  group: Connection[],
): Connection[];
export function findLinkedPath(head: Wire, group: Wire[]): Wire[];
export function findLinkedPath(
  head: Wire | Connection,
  group: (Wire | Connection)[],
) {
  const path = [head];

  for (const linked of path) {
    for (const unlinked of group) {
      if (path.includes(unlinked)) {
        continue;
      }

      const linked_conn = linked instanceof Wire ? linked.conn : linked;
      const unlinked_conn = unlinked instanceof Wire ? unlinked.conn : unlinked;

      if (isAdjacentConnection(linked_conn, unlinked_conn)) {
        path.push(unlinked);
      }
    }
  }

  return path;
}

export const connToString = (conn?: Connection) => {
  if (!conn) {
    return conn;
  }

  return `${connItemToString(conn.src)} -> ${connItemToString(conn.dst)}`;
};

export const connItemToString = (conn_item?: ConnItem) => {
  if (!conn_item) {
    return 'none';
  }

  return conn_item instanceof Cell
    ? `[${conn_item.idx.x},${conn_item.idx.y}]`
    : `#${conn_item.id}`;
};

export const getJunctionPosition = (cells: Cell[]) => {
  const pow = cells.length;

  const [isSameX, isSameY] = hasSameAxis(cells);

  // NOTE: This moves the junction point out from the axis
  //       to keep the intersection to be visually obscured
  const [biasX, biasY] = [Number(isSameX) * 10, Number(isSameY) * -10];

  return cells.reduce(
    (acc, cell) => ({
      x: biasX + acc.x + cell.center.x / pow,
      y: biasY + acc.y + cell.center.y / pow,
    }),
    { x: 0, y: 0 },
  );
};

export const getUniqueWireCells = (wires: Wire[]) => {
  return wires
    .reduce<(Cell | null)[]>((acc, wire) => {
      const conn_src = wire.conn.src;
      const conn_dst = wire.conn.dst;

      const uniq_src =
        conn_src instanceof Cell && !acc.includes(conn_src) ? conn_src : null;
      const uniq_dst =
        conn_dst instanceof Cell && !acc.includes(conn_dst) ? conn_dst : null;

      return [...acc, uniq_src, uniq_dst];
    }, [])
    .filter((p): p is Cell => !!p);
};

export const getUniqueConnItems = (conns: Connection[]) => {
  return conns
    .reduce<(ConnItem | null)[]>((acc, conn) => {
      const conn_src = conn.src;
      const conn_dst = conn.dst;

      const uniq_src = conn_src && !acc.includes(conn_src) ? conn_src : null;
      const uniq_dst = conn_dst && !acc.includes(conn_dst) ? conn_dst : null;

      return [...acc, uniq_src, uniq_dst];
    }, [])
    .filter((p): p is ConnItem => !!p);
};

export const connectionItemToPosition = (conn_item: ConnItem) => {
  if ('cells' in conn_item && conn_item.cells) {
    return getJunctionPosition(conn_item.cells);
  } else if (conn_item instanceof Cell) {
    return { ...conn_item.center };
  }

  throw new Error('Connection item is neither Cell or Junction');
};

export const isAdjacentConnection = (target: Connection, other: Connection) => {
  const items = [target.src, target.dst, other.src, other.dst].filter(
    (i): i is ConnItem => !!i,
  );

  // compare all unique pairs in array
  // except pairs containing the exact item on both positions
  return items.some((item, item_num) =>
    items
      // exclude exact item to compare only with other items
      .filter((_, num) => num !== item_num)
      .some((etc) => {
        if (item instanceof Cell && etc instanceof Cell) {
          return isSamePoint(item.idx, etc.idx);
        } else if (!(item instanceof Cell) && !(etc instanceof Cell)) {
          return item.id != null && etc.id != null && item.id === etc.id;
        }

        return false;
      }),
  );
};

export const isSameConnectionCell = (tgt: Connection, cell: Cell) => {
  return isSameConnItem(tgt.src, cell) || isSameConnItem(tgt.dst, cell);
};

export const isSameConnection = (tgt: Connection, etc: Connection) => {
  return (
    (isSameConnItem(tgt.src, etc.src) && isSameConnItem(tgt.dst, etc.dst)) ||
    (isSameConnItem(tgt.src, etc.dst) && isSameConnItem(tgt.dst, etc.src))
  );
};

export const isJunctionConnection = (conn: Connection) => {
  return (
    (conn.src && !(conn.src instanceof Cell)) ||
    (conn.dst && !(conn.dst instanceof Cell))
  );
};

export const findLoops = (conns_: Connection[]) => {
  const conns = cloneDeep(conns_);
  const nodes = getUniqueConnItems(conns);

  const graph = nodes.map((node) => {
    const edges = conns.filter(
      (conn) =>
        isSameConnItem(conn.src, node) || isSameConnItem(conn.dst, node),
    );
    return { node, edges };
  });

  // console.group('graph', graph);
  //
  // for (const item of graph) {
  //   console.log({
  //     node: connItemToString(item.node),
  //     edges: item.edges.map((conn) => connToString(conn)),
  //   });
  // }
  //
  // console.groupEnd();
  //
  return recursiveDFS(graph);
};

type NodeDFS = (Junction | Cell) & {
  visited?: boolean;
};

type EdgeDFS = Connection & {
  visited?: boolean;
};

interface GraphDFSItem {
  node: NodeDFS;
  edges: EdgeDFS[];
}

export const recursiveDFS = (graph: GraphDFSItem[], parent?: NodeDFS) => {
  const item = parent ? graph.find((i) => i.node === parent) : graph[0];

  if (!item) {
    return false;
  }

  // Mark as visited
  item.node.visited = true;

  const { node, edges } = item;

  // Loop over all connections
  for (const edge of edges) {
    // Skip visited edges
    if (edge.visited) {
      continue;
    }

    let adjacent: NodeDFS | undefined = node;

    // Connection leads to DST
    if (isSameConnItem(node, edge.src)) {
      adjacent = edge.dst;
    }

    // Connection leads to SRC
    if (isSameConnItem(node, edge.dst)) {
      adjacent = edge.src;
    }

    // Connection leads to the parent node
    if (isSameConnItem(node, adjacent)) {
      continue;
    }

    // Connection leads to nothing
    if (!adjacent) {
      continue;
    }

    // Mark edge as visited
    edge.visited = true;

    // Connection marked as visited
    if (adjacent.visited) {
      return true;
    }

    // Look from the next node
    if (recursiveDFS(graph, adjacent)) {
      return true;
    }
  }

  return false;
};

export const isSameConnItem = (target?: ConnItem, other?: ConnItem) => {
  if (!target || !other) {
    return false;
  }

  if (target instanceof Cell && other instanceof Cell) {
    return isSamePoint(target.idx, other.idx);
  }

  if (!(target instanceof Cell) && !(other instanceof Cell)) {
    return target.id === other.id;
  }

  return false;
};

import { io, type Socket } from 'socket.io-client';

import AsynchronousDatasource, {
  AsyncDatasourceStatus,
} from '../../base/model/datasources/AsynchronousDatasource';

/**
 * An implementation of asynchronous data source based on Socket.IO API
 *
 * @category Core.Models
 * @subcategory Datasources
 */
export default class SocketDatasource extends AsynchronousDatasource {
  private static readonly ConnectTimeout = 1500; // ms

  private readonly addr: string;
  private readonly port: number;
  private socket: Socket;
  private readonly _handlers: Record<string, Function>;

  constructor(addr = '127.0.0.1', port = 8080) {
    super();

    this.addr = addr;
    this.port = port;

    this._handlers = {};
  }

  get displayName(): string {
    return 'SocketIPC';
  }

  /**
   * Creates Socket.IO client instance
   */
  async init(): Promise<boolean> {
    if (this.socket) {
      return true;
    }

    this.socket = io(`http://${this.addr}:${this.port}`);

    this.socket.on('disconnect', (data: any) => {
      this._status = AsyncDatasourceStatus.Disconnected;
      console.debug('[SocketIPC] disconnected.');
      this.emit_disconnect(data);
    });

    return true;
  }

  /**
   * Initializes Socket.IO connections search loop
   */
  async connect(): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      if (this._status !== AsyncDatasourceStatus.Disconnected) {
        resolve(true);
      }

      console.debug('[SocketIPC] connecting...');

      // if (!this.socket.hasListeners('connect')) {
      this.socket.on(this.customChannels.connect, (greeting: any) => {
        const api_version: string = greeting.version?.api;

        this._status = AsyncDatasourceStatus.Connected;
        console.debug(
          `[${this.displayName}] on %c${this.customChannels.connect}`,
          'background-color: #fff5ba;',
          greeting,
        );
        console.log(
          `[${this.displayName}] connection established. API: ${
            api_version || 'unknown'
          }.`,
        );
        this.emit_connect(greeting);
        resolve(true);
      });

      setTimeout(() => {
        if (this._status === AsyncDatasourceStatus.Connected) {
          return;
        }

        // say time-out because connection might be established later
        this._status = AsyncDatasourceStatus.Timeouted;
        console.debug('[SocketIPC] connection timeout.');
        this.emit_timeout();
      }, SocketDatasource.ConnectTimeout);
      // }
    });
  }

  /**
   * @inheritdoc
   */
  async disconnect() {
    console.debug(
      `[${this.displayName}] on %cdisconnect`,
      'background-color: #fff5ba;',
    );

    if (!this.socket) {
      console.warn(
        `[${this.displayName}] socket client is not initiated, will not disconnect`,
      );
      return;
    }

    this.socket.disconnect();
  }

  /**
   * @inheritdoc
   */
  on(channel: string, handler: Function) {
    super.on(channel, handler);
    // basic 'on' is enough for internal channels (__<name-rounded-with-double-underscores>__)
    if (channel.startsWith('__') && channel.endsWith('__')) {
      return;
    }

    if (!this.socket) {
      throw new Error('Datasource is not connected to socket');
    }

    this.socket.on(channel, (data: object) => {
      this.showDebugHandlerMessage(channel, data);
      handler(data);
    });
  }

  /**
   * @inheritdoc
   */
  once(channel: string, handler: Function) {
    super.once(channel, handler);
    // basic 'once' is enough for internal channels (__<name-rounded-with-double-underscores>__)
    if (channel.startsWith('__') && channel.endsWith('__')) {
      return;
    }

    if (!this.socket) {
      throw new Error('Datasource is not connected to socket');
    }

    this.socket.once(channel, (data: object) => {
      console.debug(`[${this.displayName}] once`, channel, data);
      handler(data);
    });
  }

  /**
   * @inheritdoc
   */
  send(channel: string, data?: object, is_deferred: boolean = false) {
    if (!this.socket) {
      throw new Error('Datasource is not connected to socket');
    }

    console.debug(
      `%c[SocketIPC] send%s %c${channel}`,
      is_deferred ? 'color: violet' : '',
      is_deferred ? ' (deferred)' : '',
      'background-color: #edffba;',
      data,
    );

    this.socket.emit(channel, data);
  }
}

export enum Orientation {
  East = 'east',
  North = 'north',
  West = 'west',
  South = 'south',
  Dummy = 'dummy',
}

export const QUAD_SIZE_DEFAULT = 24;
export const LED_SIZE_DEFAULT = 16;
export const LABEL_FONT_SIZE_DEFAULT = 16;

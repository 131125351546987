import { type XYPoint, type XYRange, type XYRangeOrPoint } from '../../types';
import { type AuxPointCategory } from '../Grid';
import { type PlateProps, type SerializedPlate } from '../Plate';

/**
 * Direction of the data flow of the pin
 *
 * @category Breadboard
 */
export enum PinState {
  Input = 'input',
  Output = 'output',
}

export enum DomainSlice {
  Vertical = 'vert',
  Horizontal = 'horz',
  Arbitrary = 'arb',
}

export interface EmbeddedPlate<Props extends PlateProps = PlateProps> {
  type: string;
  id: string;
  position: XYPoint[];
  props: Props;
  pin_state_initial?: PinState;
}

export type DomainTable = Record<number, Domain>;

export type VoltageTable = Record<string, number>;

export type ElecLineTable = Record<string, XYPoint[]>;

export type LineTable = Record<string, Line>;

export interface Line {
  points: XYPoint[];

  role?: CellRole;
  pin_state_initial?: PinState;
  embedded_plate?: EmbeddedPlate;

  field: XYRange;
}

/**
 * Group of interconnected cells
 *
 * It's usually represented as the non-diagonal contact line.
 * It can have different visual styles, which can be adusted through the parameters
 * of this method.
 *
 * @category Breadboard
 */
export interface Domain {
  field: XYRange;
  virtual?: XYRange;
  minus?: (i: number, point: XYPoint) => XYPoint;
  /* TODO: Narrow types 'style' and 'role' */
  props?: DomainProps;
}

/**
 * User-side declaration of {@link Domain}
 */
export interface DomainDecl {
  field: XYRangeOrPoint;
  virtual?: XYRange;
  minus?: (i: number, point: XYPoint) => XYPoint;
  slice?: DomainSlice;
  props?: DomainProps;
}

export interface DomainProps {
  style?: string;
  role?: CellRole;
  value_orientation?: string;
  bias_inv?: boolean;
  no_labels?: boolean;
  pins_src?: number;
  pins_dst?: number;
  line_after?: number;
  line_before?: number;
  pin_state_initial?: PinState;
  label_pos?: 'top' | 'bottom' | 'left' | 'right';
}

/**
 * Board cell topology
 *
 * @category Breadboard
 */
export interface Layout {
  name: string;
  /** size of the total board workspace ({@link Grid} wrap) */
  wrap: { x: number; y: number };
  /** size of the matrix */
  size: { x: number; y: number };

  /** gaps between cells */
  gap: XYPoint;
  /** number of rows & cols of the matrix */
  dim: XYPoint;
  /** geometric position of the matrix */
  pos: XYPoint;

  plates?: Array<SerializedPlate<any, any>>;

  restrict_wires?: boolean;
  restrict_plates?: boolean;

  ddecls?: DomainDecl[];
  aux_point_cats?: AuxPointCategory[];
  controls?: { horz: boolean };

  curr_straight_top_y?: number;
  curr_straight_bottom_y?: number;

  plate_style?: {
    label_font_size?: number;
    quad_size?: number;
    led_size?: number;
  };
  label_style?: { font_size: number; text_bias: number; led_size?: number };
}

/**
 *
 * @category Breadboard
 */
export enum CellRole {
  Plus = 'plus',
  Minus = 'minus',
  Analog = 'analog',
  None = 'none',
}

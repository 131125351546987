import { type Layout } from '../../core/Layout';
import { v5x } from './v5x';
import { v8x } from './v8x';
import { v9x } from './v9x';
import { v10_3 } from './v10_3';
import { defaultLayout } from './default';

export const LAYOUTS: Record<string, Layout> = {
  default: defaultLayout,
  v5x,
  v8x,
  v9x,
  'v10.3': v10_3,
};
